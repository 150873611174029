import React, { useState, useEffect, useCallback } from 'react';
import { Row, Button, SectionHeader, SectionLabel, CenterDiv, Label, Td, Th, overlayStyle, TableContainer, PopupButtons, PopupContainer, TextField,  } from '../utils/Styles';
import swal from 'sweetalert';
import {SelectionControl, CircularProgress } from 'react-md';
import axios from 'axios';
import * as Constants from '../utils/Constants';
import Popup from 'reactjs-popup';
import axiosRetry from 'axios-retry';
import styled from 'styled-components';
import { ReactComponent as deleteSVG } from '../images/deleteIcon.svg';
import { convertToTimeZone } from 'date-fns-timezone';
import ls from 'local-storage';
import { format, toDate } from 'date-fns';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import { ReactComponent as CopySVG } from '../images/copy.svg';
import { useHistory, useLocation } from 'react-router-dom';

const TextFieldSmall = styled(TextField)`
  width: 370px;
`;

const CopyButtonHost = styled(CopySVG)`
  height: 18px;
  width: 20px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
  @media screen and (max-width: 500px) {
    height: 18px;
    width: 18px;
    margin: -4px 2px 6px 22px !important;
  }
`;

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const DeleteIcon = styled(deleteSVG)`
  object-fit: contain;
  cursor: pointer;
  width: 18px;
  @media screen and (max-width: 720px) {
    width: 16px;
  }
`;

const ResponsivePopupContainer = styled.div`
  width:  100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  z-index: 5;
  & > div {
    @media screen and (max-width: 750px) {
      transform: scale(0.9);
      background: transparent !important;
    }
    @media screen and (max-width: 480px) {
      transform: scale(0.8);
    }
    @media screen and (max-width: 450px) {
      height: 600px !important;
    }
    @media screen and (max-width: 400px) {
      transform: scale(0.75);
    }
  }
`;

const ContentDiv = styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
  @media screen and (max-width: 750px) {
    font-size: 0.95rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

const TextFieldLabel = styled(Label)`
  width: 110px;
  margin-top: 20px;
`;

const MandatoryLabel = styled(TextFieldLabel)`
  &:after {
    font-weight: bold;
    content:" *";
    color: #EE4B2B;
  }
`;

const ResponsiveMandatoryLabelGeneral = styled(MandatoryLabel)`
  @media screen and (max-width: 575px) {
    width: 140px;
  }
  @media screen and (max-width: 450px) {
    width: 120px;
  }
`;

const ResponsiveTextFieldSmall = styled(TextFieldSmall)`
  @media screen and (max-width: 575px) {
    width: 180px;
  }
  @media screen and (max-width: 450px) {
    width: 140px;
  }
`;

const ResponsiveTabContent = styled.div`
  padding: 5px 16px 10px;
  overflow: hidden auto;
  scrollbar-width: thin;
  scrollbar-color: #A8B5BD7D transparent;
  ::-webkit-scrollbar {
    height: 4px;
    width: 6px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #6262627d;
    border-radius: 10px;
  }
`;

const SectionHeaderMetabase = styled.div`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;
`;

const ResponsiveTabs = styled(Tabs)`
  @media screen and (max-width: 500px) {
    width: 320px;
  }
`;

const ContainerHost = styled.div`
  display: flex;
  justify-content: center;
`;

const InnerHostContainer = styled.div`
  display: flex;
  margin-right: 3px;
  padding-left: 2px;
`;

const License = () => {
    const [licenseData, setLicenseData] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [retry, setRetry] = useState(false)
    const [CurTimezone, setCurTimeZone]=useState();
    const [standaredTZ, setStandardTZ]=useState();
    const [onRefresh, setOnRefresh] = useState(false);
    const params = useLocation().search;
    const [historyParams] = useState(params);
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState('provider');
    const [selectedProvider, setSelectedProvider] = useState('scorer');
    const deviceID = ls.get('device_id') === undefined ? '' : ls.get('device_id') + '.local';

    const setParams = useCallback(() => {
      const params = new URLSearchParams(historyParams);
      params.set('selectedTab', selectedTab);
      return '?' + params.toString();
    }, [historyParams, selectedTab]);
  
    const fetchHistoryParams = useCallback(() => {
      const string = new URLSearchParams(historyParams);
      const newSelectedTab = string.get('selectedTab');
  
      if (newSelectedTab) {
        setSelectedTab(newSelectedTab);
      }
    }, [historyParams]);
  
    useEffect(() => {
      fetchHistoryParams();
    }, [fetchHistoryParams]);
  
    useEffect(() => {
      history.push(setParams());
    }, [history, setParams, selectedTab]);
  
    const onTabChange = (newTab) => {
      setSelectedTab(newTab);
    };
  
 const contentStyle = {
     margin: 'auto',
     background: 'rgb(255, 255, 255)',
     width: 'fit-content',
     height: 'auto',
     padding: '5px',
     minWidth: '25%',
     border: '2px solid #d7d7d7'
 };

 const closePopup = (event) => {
     setModalOpen(false);
 };

 const addNewInstance = (event) => {
     setModalOpen(true);
 };

 const handleRadioChange = (event) => {
    setSelectedProvider(event.target.value);
  };

  const clickToCopy = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };

  const handleCopyContent = () =>{
    const addressToCopy = `${deviceID}:${Constants.EDGE_UI_PORT}/api/v1/sdk/license`;
    clickToCopy(addressToCopy);
  }

  const enableLisenseMgmt = () => {
    let jsonData = Object.assign({});

    jsonData.instance_id = 'license';
    jsonData.type = 'license';
    jsonData.provider = selectedProvider;
    jsonData.enabled = true;

    let finalJson = {};
    finalJson.enabled = true;
    finalJson.enabled_license_mgr = true;
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = 'license-mgr';
    finalJson.services = {};
    finalJson.services.license1 = Object.assign({}, jsonData);
    axios.put(Constants.EDGE_API_ENDPOINT + '/sdk/license_mgr', finalJson)
      .then(res => {
        swal('License Manager enabled successfully.', { icon: 'success' });
        setTimeout(function () { window.location.reload(); }, 2000);
      })
      .catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
  }

  const getLicenses = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client, {
      retries: 15,
      retryDelay: (retryCount, error) => {
        if (retryCount < 15 && retryCount > 5) {
          setRetry(true)
        } else if (retryCount === 15) {
          setRetry(false)
          swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
        }
        return 3000;
      },
      retryCondition: (error) => {
        return true;
      },
    });
    client.get('/sdk/license_mgr/all')
      .then(res => {
        let data = res.data.data;
        let sortedData = [];
        if (data && "lic-mgr" in data.stacks) {
          Object.keys(data.stacks["lic-mgr"].services).map((licenseKey) => {
            let licenseData = data.stacks["lic-mgr"].services[licenseKey];
            licenseData.name = licenseKey;
            sortedData.push(licenseData);
            return licenseKey;
          });
        }
        const sortedData1 = Object.keys(sortedData).map((item) => {
          let test = sortedData[item];
          const licenseObj = {};
          for (const key in test) {
            if (test[key].hasOwnProperty('key')) {
              licenseObj[key] = test[key];
            }
          }
          return licenseObj;
        })
        const filterLicense = sortedData1.reduce((result, obj) => {
          for (const key in obj) {
            obj[key].id = key;
            result.push(obj[key]);
          }
          return result;
        }, []);
        filterLicense.sort((a, b) => { return b.activation_date - a.activation_date })
        data['licenseServices'] = filterLicense;
        client.get('/sdk/license_mgr/status/all')
          .then(res => {
            let licenseStatusData = res.data.data;
            if (licenseStatusData && 'stacks' in licenseStatusData) {
              let licenseStatusList = licenseStatusData.stacks;
              Object.keys(licenseStatusList).map((licenseName) => {
                if (licenseName in data.stacks) {
                  data.stacks[licenseName].status = licenseStatusList[licenseName];
                }
                return licenseName;
              });
            }
            if (licenseStatusData && ('services' in licenseStatusData.stacks["lic-mgr"])) {
              let licenseStatusList = licenseStatusData.stacks["lic-mgr"].services;
              const keys = Object.keys(licenseStatusList)
              if (keys.length > 0) {
                const firstObjValue = licenseStatusList;
                Object.keys(firstObjValue).map((item) => {
                  let statusData = firstObjValue[item];
                  Object.keys(statusData).map((item) => {
                    const updatedData = data['licenseServices'].map(service => {
                      if (service.id === item) {
                        return { ...service, lic_status: statusData[item].lic_status };
                      }
                      return service;
                    });
                    data['licenseServices'] = updatedData;
                  })
                });
                setLicenseData(data);
              }
            } else {
              setLicenseData(data);
            }
            setLoading(false);
            setOnRefresh(false);
          })
          .catch(err => {
            setLicenseData(data);
            setOnRefresh(false);
            setLoading(false);
          });
      }).catch(error => {
        setLicenseData([]);
        setLoading(false);
      });
  }, []);

 function getDisplayLabel(data, key) {
    return (data[key] !== undefined ? data[key] : key);
  }

 useEffect(() => {
     getLicenses();
 }, []);

 const refreshLicenseMgr = () => {
     setLoading(true);
     setOnRefresh(true);
     document.getElementById('btnRefrehInstance').textContent = 'Refreshing...';
     getLicenses();
 }
 
 const refreshLicenses = () => {
     setLoading(true);
     setOnRefresh(true);
     document.getElementById('btnRefrehLicenses').textContent = 'Refreshing...';
     getLicenses();
 }

  useEffect(() => {
    if (document.getElementById('btnRefrehInstance')) {
      document.getElementById('btnRefrehInstance').textContent = 'Refresh';
    }
    if (document.getElementById('btnRefrehLicenses')) {
      document.getElementById('btnRefrehLicenses').textContent = 'Refresh';
    }
  }, [licenseData]);

    const handleToggleChange = (value, event) => {
        var actionLabel = value ? "enable" : "disable";
        var jsonData = {
            'namespace': Constants.NAMSPACES.SDK,
            'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE,
            'type': 'license-mgr',
        }
        var msg = "\n\n Are you sure you want to " + actionLabel + " the License Manager?";
        swal({
            text: msg, buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                if(licenseData && (licenseData.licenseServices.length >= 1)){
                    swal('Please remove all license codes to disable the License Manager.', { icon: 'error' });
                    return;
                } else {
                    axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/license_mgr', jsonData)
                    .then(res => {
                        swal('License Manager ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' })
                        setTimeout(function () { window.location.reload() }, 2000)
                    })
                    .catch(error => {
                        swal('Error in ' + (value ? 'stop' : 'start') + ' project', { icon: 'error' })
                    })
                }
            }
        });
    };

    const deleteLicenseFeature = (value, event) => {

        var jsonData = {
            'namespace': Constants.NAMSPACES.SDK,
            'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE,
            'type': 'license-mgr',
        }

        var msg = "\n\n Are you sure you want to delete License Manager?";

        swal({
            text: msg, buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                if (licenseData && (licenseData.licenseServices.length >= 1)) {
                    swal('Please remove all license codes to delete the License Manager.', { icon: 'error' });
                    return;
                } else {
                    axios.delete(Constants.EDGE_API_ENDPOINT + '/sdk/license_mgr', jsonData)
                        .then(res => {
                            swal('License Manager delete request submitted successfully.', { icon: 'success' })
                            setTimeout(function () { window.location.reload() }, 2500)
                        })
                        .catch(error => {
                            swal('Error in ' + (value ? 'stop' : 'start') + ' project', { icon: 'error' })
                        })
                }
            }
        });
    };

 const deleteLicense = (key) => { 
     let jsonData = {
         action: "delete",
         license_code: key,
     };
     swal({
         text: 'Are you sure you want to remove this license?',
         buttons: ['No', 'Yes'],
         dangerMode: true,
         icon: 'warning'
     }).then((isConfirm) => {
             if (isConfirm) {
                 axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/license', jsonData)
                     .then(res => {
                         swal('License removed successfully.', { icon: 'success' });
                         setTimeout(function () { window.location.reload(); }, 2000);
                     })
                     .catch(error => {
                         if (error.response) {
                             var errorObj = error.response.data;
                             swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
                         } else {
                             swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
                         }
                     });
             }
         })
 };

 const getClockStatus = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    client.get('/system/clock/status/all')
    .then(res => {
      let data=res.data.data.timezone;
      let splitTimeZone = data.split(' (');
      setCurTimeZone(splitTimeZone[0])
      setStandardTZ(splitTimeZone[1])
      let splitZone = splitTimeZone[1].split(',');
      setStandardTZ(splitZone[0])
    }).catch(error => {
      console.error('clock data');
    });
  },[]);

  useEffect(()=>{
    getClockStatus()
  },[])

  const getDateFormate = (Date,CurTimezone) => {     
    try {
      const zonedTargetDate = convertToTimeZone(Date, { timeZone: CurTimezone });
      return zonedTargetDate;
    } catch (err) {
      console.error(err);
      // return new Date(Date);
    }
  };

  return (
     <>
       {(licenseData === undefined) &&
          <div className="loading-msg">
            <label>{retry ? 'Retrying...' : 'Loading...'}</label>
             <CircularProgress />
          </div>
        }
        { licenseData && 
         <ContentDiv>
             <Wrapper style={{ 'padding': '20px' }}>
                 <SectionHeader>
                     <SectionLabel>License Manager</SectionLabel>
                 </SectionHeader>
                 {licenseData && licenseData?.stacks?.["lic-mgr"]?.enabled_license_mgr === true ?
                 <>
                 <div>
                <ResponsiveTabs defaultTab={selectedTab} onChange={onTabChange} style={{ 'padding': '6px' }}>
                    <TabList>
                        <Tab tabFor="provider" className={'rwt__tab'}>Provider</Tab>
                        {licenseData.stacks["lic-mgr"].enabled &&
                            <Tab tabFor="licenses" className={'rwt__tab'}>Licenses</Tab>
                        }
                    </TabList>
                    <TabPanel tabId="provider">
                     <Row>
                        <ActionButtonContainer>
                            <Button danger id='btnDeleteInsatnce' name='btnDeleteInsatnce' onClick={deleteLicenseFeature} >Delete</Button>
                            <Button primary id="btnRefrehInstance" name="btnRefrehInstance" disabled={loading} onClick={refreshLicenseMgr}>Refresh</Button>
                        </ActionButtonContainer>
                     </Row>

                     <TableLayout>
                         <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                             <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                                 <Th>Provider</Th>
                                 <Th>Status</Th>
                                 <Th>Created</Th>
                                 <Th>API Endpoint</Th>
                                 <Th>Enabled</Th>
                             </tr>
                             {
                                 Object.keys(licenseData && licenseData.stacks).map((item) => {
                                     let instanceData = licenseData.stacks[item];
                                     let createdEpochTime = instanceData.created_time;
                                     let enabled = instanceData.enabled;
                                    let provider = instanceData.services.license1.provider;

                                     let instanceStatus = 'recovering';
                                     if ('status' in instanceData) {
                                       instanceStatus = instanceData.status.running_status.status;
                                     }
                                     let newCreatedDate = new Date(createdEpochTime*1000);
                                     if(newCreatedDate === undefined){
                                        return ;
                                      }
                                     let formattedDate = getDateFormate(newCreatedDate,CurTimezone);
                                     let createdDate = format(new Date(formattedDate), 'yyyy/MM/dd');
                                     let createdTime = format(new Date(formattedDate), 'HH:mm:ss');

                                     return (
                                         <tr>
                                             <Td> {provider === 'scorer' ? 'SCORER' : 'Cryptlex'} </Td>
                                             <Td style={{ 'text-align': 'center', 'white-space': 'nowrap' }}> {instanceStatus === '-' ? '-' :
                                                 <label className={instanceStatus}>
                                                     {getDisplayLabel(Constants.STATUS_TYPES, instanceStatus)}
                                                 </label>}
                                             </Td>
                                             <Td> {createdDate} {createdTime} {standaredTZ} </Td>
                                             <Td>
                                                 <ContainerHost>
                                                     <InnerHostContainer title={ `${deviceID}:${Constants.EDGE_UI_PORT}/api/v1/sdk/license` }><div> { `${deviceID}:${Constants.EDGE_UI_PORT}/api/v1/sdk/license` } </div><CopyButtonHost className={'small-button'} onClick={handleCopyContent} style={{ 'marginLeft': 'auto' }}>Copy</CopyButtonHost></InnerHostContainer>
                                                 </ContainerHost>
                                             </Td>
                                             <Td>
                                                 <SelectionControl style={{ 'display': 'block', 'paddingTop': '10px' }} id="enabled" type="switch" name="enabled" labelBefore="true" value={licenseData && enabled} checked={licenseData && enabled || false} onChange={handleToggleChange} />
                                             </Td>
                                         </tr>
                                     );
                                 })}
                         </table>
                     </TableLayout>
            </TabPanel>
            {licenseData.stacks["lic-mgr"].enabled && 
                <TabPanel tabId="licenses">
                    <Row>
                     <ActionButtonContainer>
                        {licenseData && (licenseData.licenseServices.length >= 1) &&
                           <Button primary id="btnRefrehLicenses" name="btnRefrehLicenses" disabled={loading} onClick={refreshLicenses}>Refresh</Button>
                        }
                         <Button primary id="btnAddInstance" name="btnAddInstance" onClick={() => addNewInstance()}>Add License</Button>
                         {modalOpen &&
                             <ResponsivePopupContainer>
                                 <Popup modal closeOnDocumentClick={false} open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                                     <LicenseMgmtPopup setModalOpen={setModalOpen} closePopup={closePopup} licenseData={licenseData} />
                                 </Popup>
                             </ResponsivePopupContainer>}
                     </ActionButtonContainer>
                    </Row>
                    {licenseData && (licenseData.licenseServices.length >= 1) &&
                      <TableLayout>
                         <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                             <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                                 <Th>Activation Date</Th>
                                 <Th>Product Name</Th>
                                 <Th>Features</Th>
                                 <Th>Status</Th>
                                 <Th>Type</Th>
                                 <Th>Renewal Date</Th>
                                 <Th>Action</Th>
                             </tr>
                             {
                                 Object.keys(licenseData.licenseServices).map((item) => {
                                     let instanceData = licenseData.licenseServices[item];
                                     let createdDateTime = instanceData.activation_date;
                                     let expiryDateTime = instanceData.expiration_date;
                                     let newActivationDate = new Date(createdDateTime*1000);
                                     let ActivationDateTime = getDateFormate(newActivationDate,CurTimezone);
                                     let ActivationDate = format(new Date(ActivationDateTime), 'yyyy/MM/dd');
                                     let ActivationTime = format(new Date(ActivationDateTime), 'HH:mm:ss');
                                     
                                     var newExpiryDate = new Date(expiryDateTime*1000);
                                     let ExpirationDateTime = getDateFormate(newExpiryDate,CurTimezone);
                                     let ExpiryDate = format(new Date(ExpirationDateTime), 'yyyy/MM/dd');
                                     let ExpiryTime = format(new Date(ExpirationDateTime), 'HH:mm:ss');

                                     if(ActivationDateTime === undefined){
                                        return ;
                                      }
                                     if(ExpirationDateTime === undefined){
                                        return ;
                                      }

                                     let productName = instanceData.product_name;
                                     let features = instanceData.features;
                                     let type = instanceData.type 
                                     let status = instanceData.lic_status;
                                     return (
                                         <tr>
                                             <Td>{ActivationDate} {ActivationTime} {standaredTZ}</Td>
                                             <Td title={productName} style={{'overflow': 'hidden','text-overflow': 'ellipsis', 'maxWidth':'180px'}}>{productName}</Td>
                                             <Td title={features} style={{'overflow': 'hidden','text-overflow': 'ellipsis', 'maxWidth':'180px'}}>{features}</Td>
                                             <Td>
                                                <>
                                                  {(status === undefined || status === '' || status === null) ?
                                                  <div>-</div>
                                                  :
                                                  <>
                                                  {status === 'active' && <div style={{ 'color': 'green' }}><b>Active</b></div>}
                                                  {status === 'expired' && <div style={{ 'color': 'red' }}><b>Expired</b></div>}
                                                  {status === 'suspended' && <div style={{ 'color': 'orange' }}><b>Suspended</b></div>}
                                                  </>
                                                  }
                                                </>
                                             </Td>
                                             <Td>
                                             { type === 'subscription' && <div>Subscription</div> }
                                             { type === 'trial' && <div>Trial</div> }
                                             </Td>
                                             <Td>{ExpiryDate} {ExpiryTime} {standaredTZ}</Td>
                                             <Td>
                                                 <DeleteIcon style={{ 'cursor': 'pointer' }} onClick={() => deleteLicense(instanceData.key)} />
                                             </Td>
                                         </tr>
                                     );
                                 })}
                         </table>
                </TableLayout>
              }
              {licenseData && (licenseData.licenseServices.length === 0) &&
                 <CenterDiv>
                     <Label>No license added.</Label>
                 </CenterDiv>}
            </TabPanel>}
            </ResponsiveTabs>
            </div>
              </> : <>
            {licenseData && Object.keys(licenseData.stacks).length === 0 &&
                <>
                    <Row><Label style={{ 'margin-left': '25px', 'margin-top': '10px' }}>License Manager is not enabled.</Label></Row>
                    <Row >
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0 5px', 'margin-left':'22px', 'marginTop':'5px' }}>
                    <Label style={{ 'margin': '4px 10px 0 2px' }}>Provider</Label>
                        <div>
                            <input type="radio" id="provider_scorer" name="provider" value="scorer" checked={selectedProvider === 'scorer'} onChange={handleRadioChange}/>
                            <Label style={{ 'margin': '5px 0 0 5px' }}>SCORER</Label>
                        </div>
                        <div>
                            <input type="radio" id="provider_cryptlex" name="provider" value="cryptlex" checked={selectedProvider === 'cryptlex'} disabled={true} onChange={handleRadioChange}/>
                            <Label style={{ 'margin': '5px 0 0 5px', 'color':'grey' }}>Cryptlex</Label>
                        </div>
                    </div>
                    </Row>
                    <Row><Button style={{ 'margin-left': '25px', 'margin-top': '19px' }} primary id="btnAddInstance" name="btnAddInstance" onClick={enableLisenseMgmt}>Enable</Button></Row>
                </>}
              </>
            }
             </Wrapper>
         </ContentDiv>
         }
     </>
    );
};

  const LicenseMgmtPopup = (props) => {
    const { closePopup, setModalOpen, licenseData } = props;
    const [licenseSetting, setLicenseSetting] = useState({ license_code: '' });

 const handleOnChange = (e) => {
     setLicenseSetting({ ...licenseSetting, [e.target.name]: e.target.value });
 };

 const saveLicense = () => {
     const licenseKey = licenseSetting.license_code; 
     const jsonData = {
         action: "validate",
         license_code: licenseKey,
         product_id: ''
     } 
     const findKey = licenseData.licenseServices ? Object.keys(licenseData.licenseServices).map((item) => {
         let test = licenseData.licenseServices[item].key;
         return test;
     }) : []; 
     if (!licenseKey) {
         swal('Please enter license code.', { icon: 'error' });
         return;
     } 
     if(licenseData && (licenseData.licenseServices.length >= 10)){
         swal('Maximum 10 numbers of license codes can be added.', { icon: 'error' });
         return;
     } else {
         axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/license', jsonData)
             .then(res => {
                 let Data = res.data.data;
                 const jsonData = {
                     action: "add",
                     license_code: licenseKey,
                 }
                 swal({
                     title: 'License Details',
                     text: `Product Name: ${Data.product_name}\nFeature: ${Data.features}`,
                     icon: 'info',
                     buttons: ['Cancel', 'Confirm'],
                 }).then((confirm) => {
                     if (confirm) {
                         axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/license', jsonData)
                             .then(res => {
                                 setModalOpen(false);
                                 swal('License added successfully.', {icon: 'success'});
                                 setTimeout(function () { window.location.reload(); }, 2000);
                                })
                            }
                        });
                    })
                .catch(error => {
                    if (error.response) {
                        var errorObj = error.response.data;
                        swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
                    }
                    else {
                        swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
                    }
                });
            }
        };

   return (
     <>
         <PopupContainer>
             <SectionHeaderMetabase style={{ 'display': 'flex' }}>
                 <SectionLabel>Add License</SectionLabel>
             </SectionHeaderMetabase>
             <ResponsiveTabContent>
                 <Row>
                     <ResponsiveMandatoryLabelGeneral>License Code</ResponsiveMandatoryLabelGeneral>
                     <ResponsiveTextFieldSmall id="license_code" autoComplete="off" name="license_code" value={licenseSetting.license_code} onChange={handleOnChange} />
                 </Row>
             </ResponsiveTabContent>
             <Row>
                 <PopupButtons>
                     <Button onClick={saveLicense} primary>Proceed</Button>
                     <Button id="btnCancelInstance" danger onClick={() => { closePopup(); }}>Cancel</Button>
                 </PopupButtons>
             </Row>
         </PopupContainer>
     </>
    );
};

export default License;