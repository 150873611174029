import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components';
import { Tabs, Tab, TabList } from 'react-web-tabs';
import axios from 'axios';
import { ContentDiv, Wrapper, Label, Label2, LeftDiv, Spacer, TextField, MandatoryLabelStream, TextArea, SelectField, Row, Column, RightDiv, SectionHeader, Button, ButtonWrapper, ImageIcon, ImageIconThin } from '../utils/Styles';
import SelectOptions from '../components/SelectOptions';
import RecordingTab from '../components/RecordingTab';
import AnalysisTab from '../components/AnalysisTab';
import BroadcastingTab from '../components/BroadcastingTab';
import ProjectTab from '../components/ProjectTab';
import StreamForwardingTab from '../components/StreamForwardingTab';
import AdvancedActionTab from './AdvancedActionTab';
import CaptureScheduleTab from '../components/CaptureScheduleTab';
import CameraImage from '../components/CameraImage';
import * as Constants from '../utils/Constants';
import "../css/react-tabs.css";
import { SelectionControl, CircularProgress, Media, TabPanel } from 'react-md';
import swal from "sweetalert";
import  '../images/no_image.png';
import Popup from "reactjs-popup";
import WebRTCPlayer from '../components/WebRTCPlayer';
import ls from 'local-storage';
import TabLeaflet from './TabLeaflet';
import { differenceInMinutes, format } from 'date-fns';
import { useHistory, useLocation } from 'react-router';

const ResponsiveSelectionControl = styled(SelectionControl)`
    height: 40px !important;
    @media screen and (max-width: 750px) {
        height: 28px !important;
    }
    @media screen and (max-width: 550px) {
        height: 26px !important;
    }
    @media screen and (max-width: 450px) {
        height: 18px !important;
        margin:0px 0px 10px 0px;
    }
`;

const SectionLabel = styled.label`
  display:inline-block;
  font-size: 1.3em;
  color: #0097f1;
  @media screen and (max-width: 400px) {
    margin-left : -14px;
  }
`;

const TextArea1=styled(TextArea)`
max-height : 128px;
@media screen and (max-width: 1400px) {
    max-height : 129px;
}
@media screen and (max-width: 550px) {
    font-size:8.5px;
}
@media screen and (max-width: 400px) {
    font-size:7.5px;
}
`;

const ButtonSmall = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#0097f1' : '#FF7F50'};
  color: #ffffff;
  font-size: 1em;
  padding: 0.3em 0.6em;
  border: 2px solid ${props => props.primary ? '#0097f1' : '#FF7F50'};
  border-radius: 3px;
  opacity: 0.7;
  @media screen and (max-width: 500px) {
     height:23px;
     padding:0px 5px;
  }
  @media screen and (max-width: 400px) {
     height:25px;
     padding:0px 5px;
     margin-left : 13px
  }
  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }

`;

const ResponsiveStreamMessage = styled.span`
    font-weight: bold;
    font-size: 0.8em;
    padding: 5px 0px 0px 50px;
    @media screen and (max-width: 500px) {
        padding-left: 20px;
        font-size: 0.7em;
    }
    @media screen and (max-width: 400px) {
        padding-left: 10px;
        font-size: 0.68em;
    }
    @media screen and (max-width: 375px) {
        padding-left: 5px;
    }
`;

const ResponsiveRightDiv = styled(RightDiv)`
    @media screen and (max-width: 475px) {
        display: flex;
        flex-direction: column;
        gap: 8px 0;
        & > div:first-child {
            padding-right: 0;
            & > div > label > span {
                width: 67px;
            }
        }
        & > div:last-child > label > span {
            width: 67px;
        }
    }
`;

const ResponsiveRow = styled(Row)`
    display: flex;
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        & > ${Column} {
            width: 100%;
            max-width: 600px;
        }
    }
    @media screen and (max-width: 410px) {
        align-items: center;
    }
`;

const ResponsiveVideo = styled.video`
    @media only screen and (max-device-width: 450px) {
        max-width : 100%;
    }
`;

const ResponsiveMedia = styled(Row)`
    display: flex;
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        & > ${Column} {
            width: 100%;
            max-width: 600px;
        }
    }
    @media screen and (max-width: 410px) {
        align-items: center;
    }
`;

const ResponsiveTabList = styled.div`  
    & .rwt__tab {
        @media screen and (max-width: 1000px) {
            padding: 12px;
        }
        @media screen and (max-width: 910px) {
            font-size: 1rem;
        }
        @media screen and (max-width: 875px) {
            font-size: 0.9rem;
        }
        @media screen and (max-width: 800px) {
            padding: 9px;
            font-size: 0.85rem;
        }
        @media screen and (max-width: 750px) {
            padding: 7px 5px;
        }
        @media screen and (max-width: 700px) {
            font-size: 0.75rem;
        }
        @media screen and (max-width: 475px) {
            font-size: 0.65rem;
            padding: 7px 4px;
        }
        @media screen and (max-width: 420px) {
            font-size: 0.6rem;
        }
        @media screen and (max-width: 390px) {
            font-size: 0.52rem;
        }
    }
`;

const ResponsiveWrapper = styled(Wrapper)`
    @media screen and (max-width: 750px) {
        font-size: 0.95rem;
    }
    @media screen and (max-width: 700px) {
        font-size: 0.9rem;
    }
    @media screen and (max-width: 550px) {
        font-size: 0.75rem;
    }
    @media screen and (max-width: 450px) {
        font-size: 0.68rem;
    }
    @media screen and (max-width: 400px) {
        font-size: 0.65rem;
    }
`;

const contentStyle = {
    "width":" 85%",
    "text-align": "center",
    "height": "90%"
};

const SectionHeader1=styled.div`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;

  @media screen and (max-width: 400px) {
    padding: 5px 5px 8px 15px;
  }
`;

const NoImg=styled.img`
  @media screen and (max-width: 750px) {
   width: 36.5em;
  }
  @media screen and (max-width: 400px) {
   width: 32.5em;
  }
`;


const ViewStream = (props) => {
    const [readOnly, setReadOnly] = useState(true);
    const [isRefreshInterval, setRefreshInterval] = useState(true);
    const [videoDevices, setVideoDevices] = useState();
    const [videoDevicesAll, setVideoDevicesAll] = useState();
    const [inputs, setInputs] = useState();
    const [recorderState, setRecorderState] = useState({});
    //const [analysisState, setAnalysisState] = useState({});
    const [broadcastingState, setBroadcastingState] = useState({});
    const [webrtcState, setWebrtcState] = useState({});
    const [rtspState, setRtspState] = useState({});
    const [hlsState, setHlsState] = useState({});
    const [projectState, setProjectState] = useState({});
    const [streamForwardingState, setStreamForwardingState] = useState({});
    const [source, setSource] = useState({});
    const [videoDeviceInfo, setVideoDeviceInfo] = useState();
    const [pageStreamStatus, setPageStreamStatus] = useState();
    const {push} = useHistory();
    const location = useLocation().search;
    var stackName = location !== undefined ? location.slice(1).split('&')[0].split('=').pop() : '';
    //var availableDevices = ([recorderState.video_device].concat(videoDevices)).sort()
    const [streamStatusData, setStreamStatusData] = useState();
    const [originalState, setOriginalState] = useState({});
    const [mapService, setMapService] = useState('');
    const [mapToken, setMapToken] = useState('');
    const [snapshotSize, setSnapshotSize] = useState();
    const [safieEnableConnecter,setSafieEnableConnecter]=useState();
    const [selectedTab, setSelectedTab] = useState('recordingTab')
    var mDnsRtspUrl;
    var deviceIPRtspUrl;
    var mDnsWebrtcUrl;
    var deviceIPWebrtcUrl;
    var refreshImage = true;
    var streamStatus = ""
    const switchBorderStyle = { borderRadius: 2 }
    const switchBackgroundStyle =   {
                                base: 'rgb(33,150,243)',
                                hover: 'rgb(33,150,243)'
                            }
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [updateCamera, setUpdateCamera] = useState('');
    const [locationData, setLoactionData] = useState();
    const [safieLocation, setSafieLocation] = useState();
    const [configure, setConfigure] = useState(false);

    function round5(value) {
        return (Math.round(value * 1e5) / 1e5).toFixed(5);
    }

    const getsnapshotSize = useCallback((size) => {
        if(size!== undefined) {
            setSnapshotSize(size.width);
        }
    },[]);

    const getRTSPToggleState = (state) => {
    
    }

    const windowScrollEvent = useCallback(() => {
        return new Promise((resolve, reject) => {
            const tin = setInterval(() => {
                if (document.getElementById('contents') !== null) {
                    document.getElementById('contents').scrollTop = 740;
                    if (document.getElementById('contents').scrollTop > 200) {
                        clearInterval(tin);
                        resolve(true);
                    }
                }
            }, 200);
        });
    }, []);

    const getParam = useCallback((isUpdate = false, updateSelectedTabString = '') => {
        const params = window.location.search;
        let paramList = [];
        if (params !== undefined && params.includes('?')) {
            paramList = params.slice(1).split('&');
        }
        if(paramList.length > 0) {
            let qparam = '';
            const index = paramList.findIndex((item) => item.includes('selectedTab='))
            if (index === -1) {
                qparam = params + '&selectedTab=' + selectedTab;
            } else {
                if (isUpdate) {
                    paramList[index] = 'selectedTab=' + updateSelectedTabString;
                    qparam = '?' + paramList.join('&');
                } else {
                    setSelectedTab(paramList[index].split('=').pop());
                }
            }
            return qparam;
        }
        return '';
    }, [selectedTab]);

    const startScrollForGeolocationTab = useCallback(async () => {
        await windowScrollEvent();
    }, [windowScrollEvent]);

    useEffect(() => {
        if (getParam() !== '') {
            push({search: getParam()});
        }
        const params = window.location.search;
        if (params.includes('selectedTab=geoLocation') && params.includes('redirection=true')) {
            startScrollForGeolocationTab();
        }
    }, [push, startScrollForGeolocationTab]);

    const onTabChange = useCallback((tabId) => {
        setSelectedTab(tabId);
        push({search: getParam(true, tabId)});
    }, [push])

    const getAccessToken = useCallback( () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_location/map/all')
        .then(res =>{
            if(res.data.data.access_token === ' ' || res.data.data.access_token === ''){
                setMapToken('')
                setMapService(res.data.data.service_provider)
            } else {
                setMapToken(res.data.data.access_token)
                setMapService(res.data.data.service_provider)
            }
        }).catch(err => {
            console.log(err);
          });
    },[]);

    useEffect(() => {
        getAccessToken();
    },[getAccessToken]);

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
        } else {
        setIsMobileBrowser(isMobileBrowser);
        }
    },[]);

    useEffect(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all")
        .then(res => {
            let services = res.data["data"]["services"];
            res.data["data"]["services"]["recorder1"]["video_file_max_size"] = res.data["data"]["services"]["recorder1"]["video_file_max_size"]/1048576 + "";
            setInputs(res.data["data"]);
            services["recorder1"]["hls_enabled"] = services["recorder1"]["hls_enabled"];
                if(services["recorder1"]["maximum_packet_delay_type"]==="auto"){
                    services["recorder1"]["maximum_packet_delay"] = "2";
                }
                setOriginalState(res.data["data"]);
                Object.keys(services).map((service_name) => {
                    if(services[service_name]["action"] !== "DeleteService"){
                    let jsonData = {
                        "service_name": service_name
                    }
                    if(services[service_name]["type"] === "recorder"){
                        let recorder = services[service_name];
                        setSource(recorder["camera_type"]);
                        if(recorder["camera_type"] === "USB"){
                            recorder["resolution"] = recorder["video_width"] + "x" + recorder["video_height"];
                        }else if(services[service_name]["recorder_type"] === 'mlit_recorder' && recorder["camera_type"] === "RTP") {
                            recorder["camera_type"] = "MLIT";
                        }
                        recorder["service_name"] = service_name;
                        setRecorderState(recorder);
                        if(recorder["camera_type"] === "USB"){
                            getVideoDeviceData(recorder["video_device"]);
                        }
                    }
                    else if (services[service_name]["type"] === "rtmp_broadcaster"){
                        let broadcaster = services[service_name]
                        broadcaster["protocol"] = "rtmp";
                        jsonData["data"] = broadcaster;
                        setBroadcastingState(jsonData);
                    } else if (services[service_name]["type"] === "rtp_broadcaster"){
                        let broadcaster = services[service_name]
                        broadcaster["protocol"] = "rtp";
                        jsonData["data"] = broadcaster;
                        setBroadcastingState(jsonData);
                    } else if (services[service_name]["type"] === "fog_broadcaster"){
                        let broadcaster = services[service_name]
                        broadcaster["protocol"] = "fog";
                        if (broadcaster["stream_id"] == stackName){
                            broadcaster["stream_id"] = "auto"
                        }
                        jsonData["data"] = broadcaster;
                        setBroadcastingState(jsonData);
                    } else if (services[service_name]["type"] === "kinesis_streamer"){
                        let broadcaster = services[service_name]
                        broadcaster["protocol"] = "kinesis";
                        jsonData["data"] = broadcaster;
                        setBroadcastingState(jsonData);
                    } else if (services[service_name]["type"] === "stream_forwarder"){
                        let streamForwarder = services[service_name]
                        streamForwarder["service_name"] = service_name;
                        streamForwarder["sf_frame_rate_logging"] = streamForwarder["frame_rate_logging"];
                        streamForwarder["sf_endpoint_type"] = streamForwarder["endpoint_type"];
                        streamForwarder["sf_network_access"]= streamForwarder['network_access'];
                        delete streamForwarder["frame_rate_logging"];
                        delete streamForwarder["endpoint_type"];
                        setStreamForwardingState(streamForwarder);
                    }
                    }
                });
                setRtspAndWebrtcDetails(res.data["data"], isMobileBrowser)
            });
    }, [isMobileBrowser, stackName]);

    function setRtspAndWebrtcDetails(jsonData_, isMobileBrowser) {
        const mDnsName = ls.get("device_id") === undefined ? "" : ls.get("device_id") + ".local";
        const deviceIP = ls.get("device_ip") === undefined ? window.location.hostname : ls.get("device_ip");

        if (jsonData_["rtsp_port"] !== "" ){
            var check = jsonData_["rtsp_enabled"]
            var videoDevice = jsonData_["services"]["recorder1"]["video_device"]
            var rtspService = {}
            mDnsRtspUrl = "rtsp://" + mDnsName + ":" + jsonData_["rtsp_port"] + "/" + stackName
            deviceIPRtspUrl = "rtsp://" + deviceIP + ":" + jsonData_["rtsp_port"] + "/" + stackName
            rtspService["peer_address_mdns"] = mDnsRtspUrl
            rtspService["peer_address_ip"] = deviceIPRtspUrl
            rtspService["rtsp_url_type"] = "mDNS"
            setRtspState(rtspService);
        }

        var webrtcService = jsonData_["services"]["webrtc-broadcaster"];
        let mDnsWebrtcUrl = "ws://" + mDnsName + ":" + Constants.EDGE_UI_PORT + "/websocket-" + webrtcService.sender_port;
        let deviceIPWebrtcUrl = "ws://" + deviceIP + ":" + Constants.EDGE_UI_PORT + "/websocket-" + webrtcService.sender_port;
        webrtcService["peer_address_mdns"] = mDnsWebrtcUrl;
        webrtcService["peer_address_ip"] = deviceIPWebrtcUrl;
        webrtcService["webrtc_url_type"] = "mDNS";

        var hlsService = {}
        console.log(webrtcService,'jsondataPort');
        let mDnsHlsUrl = "http://" + mDnsName + ":" + "1835" + "/live/" + stackName + "/playlist.m3u8";
        let deviceIPHlsUrl = "http://" + deviceIP + ":" + "1835" + "/live/" + stackName + "/playlist.m3u8";
        hlsService["peer_address_mdns1"] = mDnsHlsUrl;
        hlsService["peer_address_ip1"] = deviceIPHlsUrl;
        hlsService["hls_url_type"] = "mDNS";
        setHlsState(hlsService);

        let services = jsonData_["services"];
        if(services["recorder1"]["camera_type"] === "USB"){
            if(services["recorder1"]["video_width"] < 1280){
                webrtcService["video_height"] = "240px";
            } else {
                if(isMobileBrowser) {
                    webrtcService["video_height"] = "210px";
                } else {
                    webrtcService["video_height"] = "270px";
                }
            }
        } else {
            webrtcService["video_height"] = "270px";
        }

        setWebrtcState(webrtcService);
    }

    const handleRtspLabelOnChange = (value) => {
        setRtspState({...rtspState, ["rtsp_url_type"]: value});
    }

    const handleWebrtcLabelOnChange = (value) => {
        setWebrtcState({...webrtcState, ["webrtc_url_type"]: value});
    }
    const handleHlsLabelOnChange=(value)=>{
        setHlsState({...hlsState ,["hls_url_type"]: value})
    }

    function getStreamStatus() {
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + '/status/all')
            .then(res => {
                setStreamStatusData(res.data.data);
            });
    }

    useEffect(() => {
        getStreamStatus();
        const interval = setInterval(() => {
            getStreamStatus();
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    function getVideoDeviceData(deviceName){
        axios.get(Constants.EDGE_API_ENDPOINT + "/devices/video/" + deviceName)
            .then(res => {
                if(res.data["data"]){
                    setVideoDeviceInfo(res.data);
                }
            })
    }

    const handleVideoDeviceOnChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
        getVideoDeviceData(event.target.value);
    }

    const handleOnChange = (event) => {
        event.persist();
        if(event.target.name === "description"){
            if(event.target.value.length > 255){
                return;
            }
        }
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

    const handleNumberOnChange = (event) => {
        event.persist();
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        setInputs({...inputs, [event.target.name]: event.target.value});
    }

    const handleToggleChange = (value, event) => {
        if(!readOnly){
            setInputs({...inputs, [event.target.id]: value});
        }
    };

    const clickToCopy = (event) => {
        let webRTCAddressElement = document.getElementsByName("webRTCAddress")[0]
        webRTCAddressElement.select();
        document.execCommand("copy");
        webRTCAddressElement.blur();
    }


    const clickToCopyRtspUrl = (event) => {
        let rtspAddressElement = document.getElementsByName("rtspAddress")[0]
        rtspAddressElement.select();
        document.execCommand("copy");
        rtspAddressElement.blur();
    }

    const CopyHlsUrl=(event)=>{
        let hlsAddress = document.getElementsByName("hlsAddress")[0]
        hlsAddress.select();
        document.execCommand("copy");
        hlsAddress.blur();
    }

    const saveRefreshInterval = () => {
        var jsonData = Object.assign({}, inputs);
        axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
        .then(res => {
            swal('Refresh interval saved successfully.', { icon: 'success' });
            setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const updateCameraView = (value, event) => {
        var jsonData = {
            "namespace": "default",
            // "rtsp_enabled": inputs.rtsp_enabled
        }
        var key = event.target.name
        jsonData[key] = value
        axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
        .then(res => {
            event.persist();
            setInputs(inputs => ({...inputs, [key]: value}));
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const deleteStack = (event) => {
        event.persist();
        swal({text: "Are you sure you want to delete this stream?",
              buttons: ['No', 'Yes'],
              dangerMode: true,
              icon: 'warning'
        })
        .then((isConfirm) => {
            if (isConfirm) {
                axios.delete(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all")
                    .then(res => {
                        swal("Stream delete request submitted successfully.", { icon: "success"});
                        if (localStorage.getItem(`viewStreamMapView${stackName}`) !== null) {
                            localStorage.removeItem(`viewStreamMapView${stackName}`);
                        }
                        if (localStorage.getItem(`mapViewCheckboxesStreamPage${stackName}`) !== null) {
                            localStorage.removeItem(`mapViewCheckboxesStreamPage${stackName}`);
                        }
                        props.history.push("./stream-viewer");
                    })
                    .catch(error => {
                        if(error.response){
                            var errorObj = error.response.data;
                            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                        } else {
                            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                        }
                    });
            }
        });
    }

    const editDescription = (event) => {
        setReadOnly(readonly => (false));
    }

    const cancelDescription = (event) => {
        window.location.reload()
    }

    const saveDescription = (event) => {
       var jsonData = {
            "namespace": "default",
            "description": inputs.description,
            "rtsp_enabled": inputs.rtsp_enabled,
            "webrtc_enabled":inputs.webrtc_enabled,
            "hls_enabled":inputs.hls_enabled
        }

        axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
        .then(res => {
            setReadOnly(readonly => (true));
            //swal("Stream information updated successfully.", { icon: "success"});
            window.location.reload();
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
        /*
        if (inputs.video_device != undefined) {
            jsonData = recorderState
            jsonData["video_device"] = inputs.video_device
            jsonData["enabled"] = inputs.enabled
            axios.put(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/" + jsonData["service_name"] , jsonData)
            .then(res => {
                swal("Stream information updated successfully.", { icon: "success"});
                setReadOnly(true);
            })
            .catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                }else{
                    swal({text: "The network connection is lost" , icon: "error"});
                }
            });
        } */
    }

    const enableStackSafie=(value, event)=>{
        swal(`\n\nSafie Connector is not configured yet. \nPlease configure it from Settings > Safie Connector.`, {
            buttons: {
              cancel: "Cancel",
              configure: {
                text: "Go to Settings",
                value: "catch",
              },
            },
          })
          .then((value) => {
            switch (value) {
              case "catch":
                window.location.href = 'safie-camera-settings'
                break;
           
              default:
            }
          });
    }

    const enableStack = (value, event) => {
        var action = (value ? "start" : "stop")
        var actionLabel = value ? "enable" : "disable";
        var enabled = (actionLabel == "enable")? true : false


        var jsonData = {
            "namespace": "default",
            "action": action,
            "source": "Edge UI",
            "enabled": enabled
        }

        var msg = "\n\n Are you sure you want to "+ actionLabel +" this stream?"

        swal({text: msg,
             buttons: ['No', 'Yes'],
             dangerMode: true,
            }).then(function(isConfirm) {
                if (isConfirm) {
                    axios.post(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
                        .then(res => {
                            setInputs({...inputs, ["enabled"]: value});
                            swal("Stream " + (value ? "enable" : "disable") +" request submitted successfully.", { icon: "success"});
                        })
                        .catch(error => {
                            if(error.response){
                                var errorObj = error.response.data;
                                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                            }else{
                                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                            }
                        });
                }
            });
    }

    const snapshotIntervalCallback = (intervalTime) => {
        setInputs({...inputs, ["snapshot_refresh_interval"]: intervalTime});
    }
    
    const recorderStateCallback = (stateData) => {
        //console.log(recorderState)
    }

    const deviceListCheckCallBack = (isList) => {
        //console.log(isList)
    }

    const isSafieConfigurCallBack = (configured) => {
        setConfigure(configured);
    }

    /*const analysisStateCallback = (stateData) => {
        //console.log(analysisState)
    }*/

    const broadcastingStateCallback = (stateData) => {
        //console.log(broadcastingState)
    }

    const projectStateCallback = (stateData) => {
        //console.log(projectState)
    }

    const streamForwardingStateCallback = (stateData) => {
        //console.log(stateData)
    }

    const captureScheduleStateCallback = (stateData1, stateData2) => {
        //console.log(stateData1)
        //console.log(stateData2)
    }

    const editRefreshInterval = () => {
        setRefreshInterval(false);
    }

    const changeCameraName = (stateData) => {
        if(source === 'SAFIE') {
            setUpdateCamera(stateData);
        }
    }

    const getSelectedUSBDevice = (state) => {
        // setSelectedVideoDevice(state)
    }

    function getDisplayLabel(data, key){
        return (data[key] !== undefined ? data[key] : key)
    }

    function getStreamStatusLabel(stackName){
        try{
            streamStatus = streamStatusData["running_status"]["status"]
            if(streamStatus == "paused" || streamStatus == "disabled"){
                refreshImage = false
            }
        }catch(error){
            console.log(error)
        }
        if(streamStatus !== "running" && streamStatus !== "disabled"){
            return <ResponsiveStreamMessage className={streamStatus}>{getDisplayLabel(Constants.STATUS_TYPES, streamStatus)}</ResponsiveStreamMessage>
        } else {
            return ""
        }
    }

    const handleSelectTab = (e) => {
        if(e !== undefined) {
            setTimeout(() => {
                setShowMap(true);
            }, 100);
        }
    }
    useEffect(() => {
        if (selectedTab === 'geoLocation') {
            setTimeout(() => {
                setShowMap(true);
            }, 100);
        }
    }, [selectedTab]);

    const triggerSave = (location_data) => {
        setLoactionData(location_data);
    }

    const checkUndefinedNestedKey = useCallback((obj, ...args) => {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }, []);

    useEffect(() => {
        if(inputs !== undefined && inputs.location !== undefined && inputs.location.sync_safie_location !== undefined  && streamStatusData !== undefined) {
            if(inputs.location.sync_safie_location) {
                let status_data = {};
                if (checkUndefinedNestedKey(streamStatusData, 'services', 'recorder1', 'health_status', 'status_detail', 'location_detail', 'location') !== undefined){
                    status_data = streamStatusData.services.recorder1.health_status.status_detail.location_detail
                }
                let data = {enabled_location: inputs.location.enabled_location};
                let isExpiredLocation = false;
                try {
                    if (status_data.gps_status === 'active') {
                        const {time} = status_data; // expected time format from safie is yyyy/MM/dd_HH:mm:ss_JST ex- '2023/03/17_06:12:18_JST', ofcouse time zone can change
                        let currentTime = new Date().toLocaleString("en-US", {
                            timeZone: time.slice(20)
                        });
                        currentTime = format(new Date(currentTime), 'MM/dd/yyyy HH:mm:ss');
                        const safieTime = format(new Date(time.slice(0,10)), 'MM/dd/yyyy') + ' ' + time.slice(11,19);
                        const diffInMin = differenceInMinutes(new Date(currentTime), new Date(safieTime));
                        if (diffInMin <= -5 || diffInMin >= 5) {
                            isExpiredLocation = true;
                        }
                    } else {
                        isExpiredLocation = true;
                    }
                } catch (error) {
                    isExpiredLocation = true;
                }
                data.sync_safie_location = true;
                data.latitude = (isExpiredLocation || !status_data.location.latitude) ? 'NA' : round5(parseFloat(status_data.location.latitude)).toString();
                data.longitude = (isExpiredLocation || !status_data.location.longitude) ? 'NA' : round5(parseFloat(status_data.location.longitude)).toString();
                setSafieLocation(data);
            } else {
                let data = {};
                data.sync_safie_location = false;
                setSafieLocation(data);
            }
        }
    },[inputs, streamStatusData, checkUndefinedNestedKey]);

    return (
            <ContentDiv>
            { inputs === undefined ?
                <div className="loading-msg">
                    <label>Loading...</label>
                    <CircularProgress/>
                </div>
            :
                <div>
                <ResponsiveWrapper>
                    <SectionHeader1>
                        <SectionLabel>Stream Information
                            { streamStatusData &&
                                getStreamStatusLabel()
                            }
                        </SectionLabel>

                        <RightDiv>
                            {/* <LeftDiv>
                                <ButtonSmall onClick={deleteStack}>Delete Stream</ButtonSmall>
                            </LeftDiv> */}
                            {
                                source !=="SAFIE" &&
                                <ResponsiveSelectionControl id="enabled" type="switch" label={inputs.enabled? "Enabled" :"Disabled"} name="enabled" labelBefore="true" checked={inputs.enabled || false} onChange={enableStack} disabled={(inputs.action === "DeleteStack" && inputs.action_status.status === "desired") ? "disabled" : ''}/>
                            }
                            {
                                !(source === "SAFIE")===configure &&
                                <ResponsiveSelectionControl id="enabled" type="switch" label={inputs.enabled? "Enabled" :"Disabled"} name="enabled" labelBefore="true" checked={inputs.enabled || false} onChange={enableStackSafie} disabled={(inputs.action === "DeleteStack" && inputs.action_status.status === "desired") ? "disabled" : ''}/>
                            }
                             {
                               configure &&
                                <ResponsiveSelectionControl id="enabled" type="switch" label={inputs.enabled? "Enabled" :"Disabled"} name="enabled" labelBefore="true" checked={inputs.enabled || false} onChange={enableStack} disabled={(inputs.action === "DeleteStack" && inputs.action_status.status === "desired") ? "disabled" : ''}/>
                            }
                        </RightDiv>
                    </SectionHeader1>
                    <ResponsiveRow>
                        <Column>
                            <div>
                            <MandatoryLabelStream style={{"padding": "12px 0 0" }}>Stream Name</MandatoryLabelStream>
                            <TextField name="stack_name" type="text" value={stackName} disabled={true}/>
                            <Label style={{"padding": "15px 0 0"}}>Description</Label>
                            <TextArea1 name="description" rows="1" value={inputs.description} onChange={handleOnChange} disabled={readOnly}/>
                            </div>
                        </Column>
                        {(source === "RTP" || source === "USB" || source === "RTMP" || source === "RTSP" || source === "HTTP" || (source=== 'SAFIE' && recorderState.capture_type === 'live_streaming') ) && 
                        <Column style={{'margin':'-10px 0 0 0'}}>
                        <LeftDiv style={{"padding": "10px 0px 0px 0px"}}>
                                <SelectionControl id="webrtc_enabled" type="switch" label={inputs.webrtc_enabled? "WebRTC Enabled" :"WebRTC Disabled"} name="webrtc_enabled" labelBefore="true" style={{"margin-right":"15px", "height": "40px"}}  checked={inputs.webrtc_enabled || false} onChange={handleToggleChange} disabled={true}/>
                            </LeftDiv>
                            { webrtcState != undefined &&  webrtcState.sender_port && inputs != undefined &&
                            <div>
                            {
                                inputs.webrtc_enabled &&
                                <RightDiv style={{"padding": "18px 0px 0px 0px"}}>
                                {webrtcState.webrtc_url_type === "mDNS" &&
                                    <Button primary name="webrtc_url_type" onClick={() => handleWebrtcLabelOnChange("IP")} className={"small-button"} style={{ "paddingTop": "10px" }}>{webrtcState.webrtc_url_type}</Button>
                                }
                                {webrtcState.webrtc_url_type === "IP" &&
                                    <Button primary name="webrtc_url_type" onClick={() => handleWebrtcLabelOnChange("mDNS")} className={"small-button"} style={{ "paddingTop": "10px" }}>{webrtcState.webrtc_url_type}</Button>
                                }
                                <Button primary onClick={clickToCopy} className={"small-button"}>Copy</Button>
                                </RightDiv>
                            }
                                <TextField name="webRTCAddress" type="text" value={ inputs.webrtc_enabled ? (webrtcState.webrtc_url_type === "mDNS" ? webrtcState.peer_address_mdns : webrtcState.peer_address_ip) : ''} readOnly/>
                            </div>
                            }
                            <LeftDiv style={{'margin':'10px 0 0 0'}}>
                                <SelectionControl id="rtsp_enabled" type="switch" label={inputs.rtsp_enabled? "RTSP Enabled" :"RTSP Disabled"} name="rtsp_enabled" labelBefore="true" style={{"margin-right":"15px", "height": "40px"}} checked={inputs.rtsp_enabled || false} onChange={handleToggleChange}/>
                            </LeftDiv>
                            { rtspState != undefined &&
                            <div>
                                { inputs.rtsp_enabled &&
                                <RightDiv style={{"padding": "18px 0px 0px 0px"}}>
                                {rtspState.rtsp_url_type === "mDNS" &&
                                    <Button primary name="rtsp_url_type" onClick={() => handleRtspLabelOnChange("IP")} className={"small-button"} style={{"paddingTop": "10px"}}>{rtspState.rtsp_url_type}</Button>
                                }
                                {rtspState.rtsp_url_type === "IP" &&
                                    <Button primary name="rtsp_url_type" onClick={() => handleRtspLabelOnChange("mDNS")} className={"small-button"} style={{"paddingTop": "10px"}}> {rtspState.rtsp_url_type}</Button>
                                }
                                {
                                    (rtspState.peer_address_mdns || rtspState.peer_address_ip) === undefined ? '' : 
                                    <Button primary onClick={clickToCopyRtspUrl} className={"small-button"}>Copy</Button>
                                }
                                </RightDiv>
                                }
                                <TextField name="rtspAddress" type="text" value={inputs.rtsp_enabled ? (( rtspState.rtsp_url_type === "mDNS" ? rtspState.peer_address_mdns : rtspState.peer_address_ip) === undefined) ? 'Please save stream information to generate URL' : rtspState.rtsp_url_type === "mDNS" ? rtspState.peer_address_mdns : rtspState.peer_address_ip : ''} readOnly/>
                            </div>
                            }
                            
                                    <LeftDiv style={{ "padding": "12px 0px 0px 0px" }}>
                                        <SelectionControl id="hls_enabled" type="switch" label={inputs.hls_enabled ? "HLS Enabled" : "HLS Disabled"} name="hls_enabled" labelBefore="true" style={{ "margin-right": "15px", "height": "40px" }} checked={inputs.hls_enabled || false} onChange={handleToggleChange} />
                                    </LeftDiv>
                                    {hlsState != undefined &&
                                        <div>

                                            <>
                                                {inputs.hls_enabled === true &&
                                                    <>
                                                        <RightDiv style={{ "padding": "19px 0px 0px 0px" }}>
                                                            {hlsState.hls_url_type === "mDNS" &&
                                                                <Button primary name="hls_url_type" onClick={() => handleHlsLabelOnChange("IP")} className={"small-button"} style={{ "paddingTop": "10px" }}>{hlsState.hls_url_type}</Button>
                                                            }
                                                            {hlsState.hls_url_type === "IP" &&
                                                                <Button primary name="hls_url_type" onClick={() => handleHlsLabelOnChange("mDNS")} className={"small-button"} style={{ "paddingTop": "10px" }}>{hlsState.hls_url_type}</Button>
                                                            }
                                                            <Button primary onClick={CopyHlsUrl} className={"small-button"}>Copy</Button>
                                                        </RightDiv>
                                                    </>
                                                }
                                            </>

                                            <TextField name="hlsAddress" type="text" value={inputs.hls_enabled ? (hlsState.hls_url_type === "mDNS" ? hlsState.peer_address_mdns1 : hlsState.peer_address_ip1) : ''} readOnly />
                                        </div>
                                    }
                                </Column>}

                    </ResponsiveRow>
                    { (source === "RTSP" || source === "HTTP" || source === "RTMP" || source === "RTP" || source === "FTP" || source === "SAFIE") &&
                        <Column style={{'width': '100%'}}>
                            <RightDiv>
                                { readOnly == true &&
                                    <Button id="editDescrButton" primary onClick={editDescription}>Edit</Button>
                                }
                                { readOnly == false &&
                                    <div>
                                        <Button primary onClick={saveDescription}>Save</Button>
                                        <Button danger onClick={cancelDescription}>Cancel</Button>
                                    </div>
                                }
                            </RightDiv>
                        </Column>
                    }

                {/* { source == "USB" &&
                    <Row>
                        <Column>
                            <Label>Video Device</Label>
                            { (recorderState && videoDevices) &&
                                <SelectField name="video_device" onChange={handleOnChange}>
                                    {videoDevicesAll !== undefined && videoDevicesAll.map((item) => {
                                    if(item.name === recorderState.video_device){
                                        let videoDescription = item.description ? ' (' + item.description + ')' : '';
                                        return (<option value={recorderState.video_device}>{recorderState.video_device + videoDescription}</option>)
                                    }
                                    })
                                }
                                { videoDevices.map((item) => {
                                    let videoDescription = item.name ? item.name + ' (' + item.description + ')' : '';
                                    return (<option value={item.name}>{videoDescription}</option>)
                                    })
                                }
                                </SelectField>
                            }
                        </Column>
                    </Row>
                    } */}
                    { source == "USB" &&
                    <Row>
                        <Column></Column>
                        <Column>
                            <RightDiv>
                                { readOnly == true &&
                                    <Button id="editDescrButton" primary onClick={editDescription}>Edit</Button>
                                }
                                { readOnly == false &&
                                    <div>
                                        <Button primary onClick={saveDescription}>Save</Button>
                                        <Button danger onClick={cancelDescription}>Cancel</Button>
                                    </div>
                                }
                            </RightDiv>
                        </Column>
                    </Row>
                    }
                </ResponsiveWrapper>
                <Spacer/>
                { (recorderState.video_device) &&
                    <div>
                        <ResponsiveWrapper>
                            <SectionHeader style={{display: 'flex', justifyContent: 'space-between'}}>
                            <SectionLabel>Camera View</SectionLabel>
                            <ResponsiveRightDiv>
                                <LeftDiv>
                                <ResponsiveSelectionControl id="camera_view_still_image" type="switch" label="Still Image" name="camera_view_still_image" labelBefore="true" checked={inputs.camera_view_still_image || false} onChange={updateCameraView}/>
                                </LeftDiv>
                                {( source === "RTP" || source === "RTMP" || source === "RTSP" || source === "HTTP" || source === "USB" || (source=== 'SAFIE' && recorderState.capture_type === 'live_streaming') ) &&
                                <ResponsiveSelectionControl id="camera_view_movie" type="switch" label="Movie (WebRTC)" name="camera_view_movie" labelBefore="true" checked={inputs.camera_view_movie || false} onChange={updateCameraView}/>
                                }
                            </ResponsiveRightDiv>
                            </SectionHeader>
                            {/* <Row>
                                <Column>
                                    { inputs.camera_view_still_image === true && inputs.enabled === true &&
                                    <div>
                                        <label>Refresh Interval (Secs)</label>
                                        <TextField style={{"marginLeft":"20px", "width":"70px"}} name="snapshot_refresh_interval" type="text" value={inputs.snapshot_refresh_interval} onChange={handleNumberOnChange} onBlur={handleRefreshIntervalOnBlur} maxLength="4"/>
                                    </div>
                                    }
                                </Column>
                                <Column>
                                </Column>
                            </Row> */}
                            <ResponsiveMedia>
                                { (inputs.camera_view_still_image === true && inputs.enabled === true) &&
                                <Column style={{ padding: '10px'}}>
                                    { streamStatus === "paused" ?
                                    <div>
                                        <CameraImage stackName={stackName} getsnapshotSize={getsnapshotSize} refreshInterval={refreshImage ? inputs.snapshot_refresh_interval : 0} streamStatus={streamStatus} recorder={recorderState}/>
                                    </div>
                                    :
                                    <div>
                                       <CameraImage stackName={stackName} detailPage={true} getsnapshotSize={getsnapshotSize} refreshInterval={refreshImage ? inputs.snapshot_refresh_interval : 0} streamStatus={streamStatus} recorder={recorderState}/>
                                    </div>
                                    }
                                </Column>
                                }
                                { (inputs.camera_view_still_image === true && inputs.enabled === false) &&
                                <Column>
                                    <NoImg src={ require('../images/no_image.png')} className="no_img"/>
                                </Column>
                                }
                                { (source !== "FTP" && streamStatus !== "paused") &&
                                <Column>
                                { webrtcState != undefined &&  webrtcState.sender_port && inputs != undefined &&
                                <div>
                                    <div id="videoContainer" style={{"text-align": "center"}}>
                                        <div>
                                            <ResponsiveVideo id="stream" className='videoInsert' autoPlay muted controls height={(( webrtcState.video_width >= 1280)? '190px': webrtcState.video_height)} width={snapshotSize} style={{"border": "1px solid lightgray", "margin": "auto"}}>Your browser doesn't support video</ResponsiveVideo>
                                        </div>
                                        <div>Status: <span id="status"> { (streamStatusData !== undefined && streamStatusData["capture_schedule_status"] !== undefined && streamStatus === "paused") ? "paused since " + (streamStatusData["capture_schedule_status"]["last_capture_stop"]).replace('_', ' ') : "unknown" } </span></div>
                                    </div>
                                    { (streamStatus!== "" && streamStatus !== "paused") &&
                                    <WebRTCPlayer id={stackName} peerAddress={(window.location.protocol === "http:" ? "ws://" : "wss://") + window.location.host + "/websocket-" + webrtcState.sender_port + "/"} enabled={inputs.camera_view_movie && inputs.enabled} for="videoContainer"/>
                                    }
                                </div>
                                }
                                </Column>
                                }
                            </ResponsiveMedia>
                            <ResponsiveRow>
                                <Column>
                                    { inputs.camera_view_still_image === true && inputs.enabled === true &&
                                    <>
                                    </>}
                                    <div>
                                        <label>Refresh Interval (Secs)</label>
                                        <SelectField name="snapshot_refresh_interval" value={inputs.snapshot_refresh_interval} onChange={handleNumberOnChange} style={{'width': '80px', 'marginLeft': '10px'}} disabled={isRefreshInterval}>
                                            <SelectOptions items={Constants.CS_AUTO_REFRESH_INTERVALS}/>
                                        </SelectField>
                                        { isRefreshInterval === true &&
                                            <ButtonSmall id="editRefreshButton" primary onClick={editRefreshInterval} style={{'marginLeft': '10px'}}>Edit</ButtonSmall>
                                        }
                                        { isRefreshInterval === false &&
                                            <>
                                                <ButtonSmall id="saveRefreshButton" primary onClick={saveRefreshInterval} style={{'marginLeft': '10px'}}>Save</ButtonSmall>
                                                <ButtonSmall id="cancelRefreshButton" danger onClick={() =>  window.location.reload()} style={{'marginLeft': '10px'}}>Cancel</ButtonSmall>
                                            </>
                                        }
                                    </div>
                                </Column>
                            </ResponsiveRow>
                        </ResponsiveWrapper>
                        <Spacer/>
                    </div>
                }
                <ResponsiveWrapper>
                    <Tabs defaultTab={selectedTab} onChange={onTabChange}>
                        <TabList>
                            <ResponsiveTabList>
                                <Tab tabFor="recordingTab" className={`rwt__tab`}>Recording</Tab>
                                {/* <Tab tabFor="analysisTab" className={`rwt__tab`}>Analysis</Tab> */}
                                <Tab tabFor="broadcastingTab" className={`rwt__tab`}>Broadcasting</Tab>
                                <Tab tabFor="streamForwardingTab" className={`rwt__tab`}>Stream Forwarding</Tab>
                                <Tab tabFor="captureScheduleTab" className={`rwt__tab`}>Capture Schedule</Tab>
                                <Tab tabFor="geoLocation" className={`rwt__tab`} onClick={handleSelectTab}>Stream Location</Tab>
                            </ResponsiveTabList>
                        </TabList>
                        { (videoDeviceInfo != undefined || recorderState.camera_type === "RTSP" || recorderState.camera_type === "HTTP" || recorderState.camera_type === "RTMP" || recorderState.camera_type === "RTP" || recorderState.camera_type === "FTP" || recorderState.camera_type === 'SAFIE' || recorderState.camera_type === 'MLIT') &&
                        <RecordingTab parentCallback={recorderStateCallback} selectedVideoDevice={getSelectedUSBDevice} setRTSPToggleState={getRTSPToggleState} triggerSave={triggerSave} changeCameraName={changeCameraName} isList={deviceListCheckCallBack} isConfigue={isSafieConfigurCallBack} intervalCallback={snapshotIntervalCallback} streamStatusData={streamStatusData} stackStatus={inputs.enabled} stackName={stackName} data={recorderState} data2={videoDeviceInfo} readOnly={true}/>
                        }
                        {/* <AnalysisTab parentCallback={analysisStateCallback} stackStatus={inputs.enabled} stackName={stackName} data={analysisState} readOnly={true}/> */}
                        <BroadcastingTab style={{'width':'15px'}} parentCallback={broadcastingStateCallback} stackStatus={inputs.enabled} stackName={stackName} data={broadcastingState} readOnly={true} source={source} />
                        <StreamForwardingTab parentCallback={streamForwardingStateCallback} stackStatus={inputs.enabled} stackName={stackName} data={streamForwardingState} readOnly={true}/>
                        <CaptureScheduleTab parentCallback={captureScheduleStateCallback} stackName={stackName} data={inputs.capture_schedule} captureStatus={streamStatusData!== undefined ? streamStatusData["capture_schedule_status"] : undefined} readOnly={true}/>
                        <TabLeaflet locationInputs={(safieLocation && safieLocation.latitude !== undefined) ? safieLocation : inputs.location} locationData={locationData} updateCamera={updateCamera} streamStatus={streamStatus} showMap={showMap} stackName={stackName} isViewPage={true} isSafie={source === 'SAFIE'} recorderState={recorderState} readonly={true} />
                    </Tabs>
                </ResponsiveWrapper>
                <Spacer/>
                <ResponsiveWrapper>
                    <SectionHeader style={{display: 'flex', justifyContent: 'space-between'}}>
                        <SectionLabel>Advanced Actions</SectionLabel>
                    </SectionHeader>
                    <ResponsiveRow>
                        <Column style={{ 'margin': '15px'}}>
                            <ButtonSmall onClick={deleteStack}>Delete Stream</ButtonSmall>
                        </Column>
                    </ResponsiveRow>
                </ResponsiveWrapper>
                </div>
            }
            </ContentDiv>
            )
}

export default ViewStream;