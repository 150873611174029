import React from 'react'
import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { TextFieldLabel, Label, TextField, TextArea } from '../utils/Styles';
import { SelectionControl } from 'react-md';
import { detectBrowser } from '../utils/Utils';
import swal from '@sweetalert/with-react';
import * as Constants from '../utils/Constants';
import axios from 'axios';
import { useRef } from 'react';
import Loader from './Loader';

const TextFieldSmall = styled(TextField)`
  width: 23rem;
  @media screen and (max-width: 400px) {
    width: 204px;
  }
`;
const ContentDiv=styled.div`
  width: 100%;
  overflow-y: auto;
  padding-bottom: 60px;
  @media screen and (max-width: 400px) {
    padding-bottom: 80px;
  }
  & fieldset {
    min-width: 0;
  }
`;

const ResponsiveTextFieldLabel = styled(TextFieldLabel)`
  // font-size:15px;
  margin-top:12px;
  width: 80px;
  // @media screen and (max-width: 500px) {
  //   width: 120px;
  // }
  @media screen and (max-width: 400px) {
    font-size:9px;
  }
`;
const Row = styled.div`
  margin-top : -3.5em;
  margin-bottom : 3em;
  width: 30%;
  clear:both;
  @media screen and (max-width: 650px) {
    margin-top : -4.5em;
  }
  @media screen and (max-width: 500px) {
    margin-top : -5.5em;
  }
  @media screen and (max-width: 400px) {
    margin-top : -4.7em;
  }
`;

const NotesBox = styled.div`
  margin: 5px 0px;
  max-width: 794px;
  textarea{
    min-width: 47.7em;
    max-width: 100%;
    min-height: 55px;
    max-height: 120px;
    margin-bottom: 4px;
    @media (max-width: 1114px) {
      min-width: 21em;
      margin-bottom : -8px;
    }
    @media (max-width: 750px) {
      min-width: 24.2em;
    }
    @media (max-width: 700px) {
      min-width: 25.5em;
      margin-bottom: -8px;
    }
    @media (max-width: 650px) {
      min-width: 322px;
      max-width: 100%;
      min-height: 67px;
      max-height: 165px;
      margin-bottom: -8px;
    }
    @media (max-width: 400px) {
      min-width: 204px;
      max-width: 100%;
      min-height: 67px;
      max-height: 165px;
      margin-bottom: 24px;
    }
  }
`;

const TextFieldLabelUserName = styled(Label)`
  width: 190px;
  margin:40px 0 0 0;
  margin-bottom: 7px;
  @media (max-width: 1113px) {
    margin:0px 0 0 0;
  }
  @media (max-width: 650px) {
    margin:0 0 5px 0;
  }
  &:after {
    font-weight: bold;
    content:" *";
    color: ${props => props.enableToggle === true ? '#EE4B2B' :'white'} 
  }
  
`;

const TextFieldLabelUser = styled(Label)`
  width: 190px;
  margin-top: 0px;
  &:after {
    font-weight: bold;
    content:" *";
    color: ${props => props.enableToggle === true ? '#EE4B2B' :'white'};
  }
  
`;

const FlexibleDiv = styled.div`
  
  width:0vw;
  height:0vw;
  background-color:red;
  margin-left:49em;
  @media (max-width: 1156px) {
    margin-left:22em;
    }
    @media (max-width: 750px) {
    margin-left:25.3em;
    }
    @media (max-width: 701px) {
    margin-left:25.3em;
    }
`;

const Line = styled.div`
  position: absolute;
  height: 2px;
  top: 13px;
  width: 60.2%;
  background-color: #eee;
  max-width: 941px;
  @media (max-width: 1386px) {
    width: 69.2%;
    }
  @media (max-width: 1120px) {
    width: 74%;
    }
  @media (max-width: 400px) {
    top: 9px;
    width: 68%;
    }
`;

const Span = styled.span`
  height: 17px;
  width: 20px;
  object-fit: contain;
  cursor: pointer;
  margin-left: -30px;
  margin-bottom: -4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
 margin:0px 31px 0px 0px;
  margin-top: 10px;
     @media screen and (max-width:700px){
       margin:0px 35px 0px 0px;
     }
`;

const StatusDetailBox = styled.div`
  margin:17px 0px;
     @media screen and (max-width:400px){
      margin: 15px 0px 0px -95px;
  }
`;
const StatusBox = styled.div`
  display: flex;
`;
const StatusBar = styled.div`
  width: 130px;
  height: 5px;
  margin: -6px 0 37px 0;
  border-radius: 2.5px;
  background-color: ${({ color }) => color};
  @media screen and (max-width: 650px) {
    width: 100px;
}
  @media screen and (max-width: 400px) {
    width: 80px;
    margin-left:97px;    
}
`;

const StatusText = styled(Label)`
  font-size: 12px;
  margin-bottom: 0;
  margin:-45px 0 0 57.3em;

  @media screen and (max-width: 1157px) {
    margin:-44px 0 0 57.2em; 
    font-size: 12px;     
}
@media screen and (max-width: 1114px) {
  margin:-44px 0 0 23em; 
  font-size: 12px;     
}
  @media screen and (max-width: 650px) {
    margin:-44px 0 0 31.5em; 
    font-size: 9px;     
}
  @media screen and (max-width: 400px) {
    margin:-44px 0 0 29.2em; 
    font-size: 9px;     
}
  span{
    margin-bottom: 0;
    margin-left:12px;
  }
`;
const StatusText1 = styled(Label)`
  font-size: 12px;
  margin-bottom: 0;
  margin:-3.8em 0 0 55em;
  @media screen and (max-width: 1157px) {
    margin:-44px 0 0 55.2em; 
    font-size: 12px;     
}
  @media screen and (max-width: 1114px) {
    margin:-44px 0 0 21em; 
    font-size: 12px;     
}
  @media screen and (max-width: 1000px) {
    margin:-44px 0 0 21em; 
    font-size: 12px;     
}
  @media screen and (max-width: 600px) {
    margin:-44px 0 0 20.9em; 
    font-size: 12px;     
}
@media screen and (max-width: 400px) {
  font-size: 8px;
  margin:-5.3em 0 0 31.4em;
}
@media screen and (max-width: 300px) {
  font-size: 8px;
  margin:-5.3em -5em 0px 31.4em;
}
  span{
    margin-bottom: 0;
    margin-left:12px;
  }
`;

const ButtonDiv = styled.div`
margin:3vh 81.3vh 0vh 0vh ;
@media screen and (max-width: 1040px) {
    margin:3vh 81.3vh 0vh 0vh ;
}
`;
const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#0097f1' : '#FF7F50'};
  color: #ffffff;
  font-size: 1em;
  margin: -30px 21px 0em 0em;
  padding: 0.4em 1em;
  border: 2px solid ${props => props.primary ? '#0097f1' : '#FF7F50'};
  border-radius: 3px;
  opacity: 0.7;
  @media screen and (max-width: 1156px) {
    margin:-28px -25.5em 2em 4em;
}
  @media screen and (max-width: 1114px) {
    margin:-21px 1.1em 0em 0em;
}
  @media screen and (max-width: 750px) {
        margin : -19px 1em 0em 0em;
}
@media screen and (max-width: 700px) {
  margin : -17px -0.2em 4em 0em;
}
  @media screen and (max-width: 650px) {
    margin:-17px -0.3em 0em 0em;
}
  @media screen and (max-width: 500px) {
    margin:-13px -5em 2em 0em;
    margin-left:10px;
}
  @media screen and (max-width: 450px) {
    margin:-12px -7.2em 2em 10px;
    margin-left:10px;
}
  @media screen and (max-width: 400px) {
    margin:-3.7em 37px 13px 16em;
    margin-left:10px;
}

  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }
`;

const SaveButton = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#0097f1' : '#FF7F50'};
  color: #ffffff;
  font-size: 1em;
  margin: -30px 20px 0em 0em;
  padding: 0.4em 1em;
  border: 2px solid ${props => props.primary ? '#0097f1' : '#FF7F50'};
  border-radius: 3px;
  opacity: 0.7;
  @media screen and (max-width: 1400px) {
    margin : -30.5px 20px 1em 0em;
}
  @media screen and (max-width: 1157px) {
    margin : -17px 20px 0em 0em;
}
  @media screen and (max-width: 700px) {
    margin : -7px 20px 1em 0em;
}
  @media screen and (max-width: 650px) {
    margin:-7px 20px 1em 0em; 
    margin-left:10px;
}
  @media screen and (max-width: 500px) {
    margin:3px 7px 1em 0em; 
    margin-left:10px;
}
  @media screen and (max-width: 400px) {
    margin:-37px 7px 6em 0em; 
    margin-left:10px;
}
  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }
`;

const CancelButton = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#0097f1' : '#FF7F50'};
  color: #ffffff;
  font-size: 1em;
  margin: -30px 21px 0em 0em;
  padding: 0.4em 1em;
  border: 2px solid ${props => props.primary ? '#0097f1' : '#FF7F50'};
  border-radius: 3px;
  opacity: 0.7;
  @media screen and (max-width: 1400px) {
    margin:-30px 21px 1em 0em;
}
  @media screen and (max-width: 1157px) {
    margin:-18px -25.6em 0em 0em
}
  @media screen and (max-width: 1114px) {
    margin:-17px 1.1em 0em 0em;
}
  @media screen and (max-width: 800px) {
        flex-direction: column;
}
@media screen and (max-width: 700px) {
  margin:-7px -3px 1em 0em;
}
@media screen and (max-width: 650px) {
  margin:-7px -2px 1em 0em;
}
  @media screen and (max-width: 500px) {
    margin:2px -5em 1em 0em;
    margin-left:10px;
}
  @media screen and (max-width: 450px) {
    margin:2px -7.5em 1em 0em;
    margin-left:10px;
}
  @media screen and (max-width: 400px) {
    margin:-37px 3.9em 6em 0em;
    margin-left:10px;
}
  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }
`;

const InputPasswordContainer = styled.div`
  display: flex;
  position: relative;
  // top: 21px;
  flex-wrap: wrap;
  max-width: 928px;
  @media screen and (max-width: 400px) {
    top: 0px;
}
  
`;

const PasswordFieldBox = styled.div`
  min-width: 380px;
  margin-right: 32px;
  @media screen and (max-width: 1114px) {
    margin-top: 10px;
}
  @media screen and (max-width: 650px) {
    min-width: 0px;
    margin-top: 10px;
    margin-right: 0px;
}
  @media screen and (max-width: 400px) {
    min-width: 0px;
    margin-right: 0px;
}
  button > div {
  margin-top: 5px;
  }
`;

const ConfirmPasswordDiv =styled.div`
  @media screen and (max-width: 300px) {
   margin-top : -20px;
  }
`;

const USER_DETAILS = { username: '', description: '', password: '', confirmPassword: '', mode: 'basic_auth', enabled: false };
const onValidUsername = (val) => {
  const usernameRegex = /^[a-zA-Z0-9_.@-]+$/;
  return usernameRegex.test(val);
};

const UserLogin = (props) => {

  const [readOnly, setReadOnly] = useState(true);
  const [passwordType, setpasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("confirmPassword");
  const browserType = useState(() => detectBrowser());
  const [editUser, setEdituser] = useState(true)
  const [userDetails, setUserDetails] = useState(USER_DETAILS);
  const [passwordStatus, setPasswordStatus] = useState('#d9d9d9');
  const [goodPassword, setGoodPassword] = useState('');
  const [badPassword,setBadPassword]=useState('')
  const [isViewPage, setIsViewPage]=useState(false)
  const [loading, setLoading] = useState(true);
  const userdetailsRef = useRef(userDetails);
  const [prevData,setPrevData]=useState('')
  const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 650;

    React.useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

  const ShowHidePassword = () => {
    setpasswordType(passwordType === "text" ? "password" : "text")
  }

  const ShowHideConfirmPassword = () => {
    setConfirmPasswordType(confirmPasswordType === "text" ? "confirmPassword" : "text")
  }

  const editUserDetails = () => {
    setReadOnly(false);
    setIsViewPage(true)
  }

  const getUserDetails = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    client.get('/system/services/security')
      .then(res => {
        let data = res.data.data;
        setUserDetails(data);
        setLoading(false);
        if(data.description === undefined){
          data.description = '';
        }
        if(data.enabled === false ){
          data.confirmPassword = '';
          data.password = '';
          setUserDetails(data);
          setPrevData(data);
        } else {
          setUserDetails({ ...data, confirmPassword: data?.password });
          setPrevData({ ...data, confirmPassword: data?.password });
        }
        userdetailsRef.current = data;
      })
  }, []);

  useEffect(() => {
    getUserDetails()
  }, [getUserDetails])

const handleToggleChange = (value, event) => {
    const name = event.target.name;
    if (!readOnly) {
      setUserDetails({ ...userDetails, [name]: value });
    } 
    
  };

  const onCancel=()=>{
    setReadOnly(true)
    setIsViewPage(false)
    getUserDetails();
  }

  const onSave = (value, event) => {
    let jsonData = Object.assign({}, userDetails);
    let userInputData = Object.assign({}, userDetails);
    let regEx = /^[a-zA-Z0-9_.@-]+$/;
    if(userDetails.enabled === true){
      if (userDetails.username == '' || userDetails.username == null) {
        swal({ text: "Please enter username.", icon: 'error' });
        return;
      } else if (userDetails.username.length > 64) {
        swal('Please enter username <= 64 characters.', { icon: 'error' });
        return;
      } else if (userDetails.password == "") {
        swal({ text: "Please enter password.", icon: 'error' });
        return;
      } else if (userDetails.confirmPassword == "") {
        swal({ text: "Please enter confirm password.", icon: 'error' });
        return;
      } else if (userDetails.password.length > 64 || userDetails.confirmPassword.length > 127) {
        swal('Please enter password <=64 characters.', { icon: 'error' });
        return;
      } else if (userDetails.confirmPassword !== userDetails.password) {
        swal({ text: "Password not match.", icon: 'error' });
        return;
      }
    } else if(userDetails.enabled === false){
       if (userDetails.confirmPassword !== userDetails.password) {
        swal({ text: "Password not match.", icon: 'error' });
        return;
      }
    }
    let userJson = Constants.USER_DETAILS_VALUE;
    userJson.username = jsonData.username;
    userJson.password = jsonData.password;
    userJson.enabled = jsonData.enabled;
    userJson.description = jsonData.description;

    userJson.mode = "basic_auth";

    let finalJson = {};
    finalJson.username = jsonData.username;
    finalJson.password = jsonData.password;
    finalJson.enabled = jsonData.enabled;
    finalJson.description = jsonData.description;

    finalJson.mode = "basic_auth";

    if (userDetails.enabled === false) {
      finalJson.username = '';
      finalJson.password = '';
    };

    axios.put(Constants.EDGE_API_ENDPOINT + '/system/services/security', finalJson)
      .then(res => {
        setReadOnly(value);
        swal('Login details saved successfully.', { icon: 'success' });
        setTimeout(function () { window.location.reload(); }, 2000);
      })
      .catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
  }

  const onChangeHandler = useCallback(({ target: { name, value } }) => {
    if ((name === 'password' || name === 'confirmPassword') && editUser) {
    value = value.replace(' ', '');
      setUserDetails(prev => ({ ...prev, [name]: value }));
    } else if (name === 'username' && value.length <= 255) {
      if (value === '') {
        setUserDetails(prev => ({ ...prev, username: value }));
      } else if (onValidUsername(value)) {
        setUserDetails(prev => ({ ...prev, username: value }));
      }
    } else if (name === 'description') {
      if (value.trim().length < 1024) {
        setUserDetails(prev => ({ ...prev, description: value }));
      }
    } else {
      setUserDetails(prev => ({ ...prev, [name]: value }));
    }
  
  }, [editUser]);

  useEffect(() => {
    if (userDetails.confirmPassword === '' && userDetails.password === '') {
      setPasswordStatus('#d9d9d9');
      setGoodPassword('');
    } else {
      if (userDetails.confirmPassword === userDetails.password) {
        setPasswordStatus('#9dd09d');
        setGoodPassword('Matched');
      } else if(userDetails.enabled) {
        setPasswordStatus('#ff6666');
        setBadPassword('Not Matched')
      }
    }
  }, [userDetails.confirmPassword, userDetails.password]);

  function SvgPasswordShow() {
    return (
      <svg width={20} height={17} viewBox={"0 0 24 24"} style={{ cursor: "pointer", marginBottom: "-4px" }}>
        <g
          fill="none"
          fillRule="evenodd"
          stroke="rgb(78, 156, 211)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          vectorEffect={"non-scaling-stroke"}
        >
          <path d="M12 5.251C7.969 5.183 3.8 8 1.179 10.885a1.663 1.663 0 000 2.226C3.743 15.935 7.9 18.817 12 18.748c4.1.069 8.258-2.813 10.824-5.637.57-.633.57-1.593 0-2.226C20.2 8 16.031 5.183 12 5.251z" />
          <path d="M15.75 12a3.75 3.75 0 11-7.5-.002 3.75 3.75 0 017.5.002z" />
        </g>
      </svg>
    );
  }

  function SvgPasswordHide() {
    return (
      <svg width={20} height={17} viewBox={"0 0 24 24"} style={{ cursor: "pointer", marginBottom: "-4px" }}>
        <path
          d="M2.783 20l18.75-18M8.964 18.051A9.985 9.985 0 0012 18.5c4.1.069 8.258-2.813 10.824-5.637.57-.633.57-1.593 0-2.226A20.568 20.568 0 0019.75 7.88m-5.337-2.598A9.549 9.549 0 0012 5c-4.031-.067-8.2 2.752-10.821 5.635a1.663 1.663 0 000 2.226 20.8 20.8 0 002.6 2.4M8.25 11.75A3.749 3.749 0 0112 8m3.75 3.749A3.75 3.75 0 0112 15.5"
          fill="none"
          fillRule="evenodd"
          stroke="rgb(78, 156, 211)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
      </svg>
    );
  }

  return (
    <div style={{ marginLeft: '2rem' }}>
      <ContentDiv>
      <div>
      <Loader isLoading={loading} />
            {!loading &&
            <div>
      <>
      <form autoComplete="off">
      <div style={{display:'flex', marginTop:'-20px'}}>
       <div>
        {
          width < 1113 &&
          <div style={{ 'display': 'flex', 'justifyContent': 'flex-start', 'margin': '3rem 15px 1rem 0rem' }}>
        <SelectionControl style={{ height: 'auto',marginTop:'-5px' }} id='enabled_login' type="switch"  label={userDetails.enabled ? "Enabled" : "Disabled"} labelBefore="true" name="enabled" value={userDetails.enabled} disabled={readOnly} checked={userDetails.enabled || false} onChange={handleToggleChange} />
      </div>
        }
       <TextFieldLabelUserName id='' enableToggle={userDetails.enabled}>Username</TextFieldLabelUserName><br />
        <TextFieldSmall id="username" autoComplete="off" name="username" value={userDetails.username} disabled={readOnly ? readOnly : (!userDetails.enabled ? true : false)} onChange={onChangeHandler} />
       </div>
       {
          width >= 1113 &&
        <div style={{ 'display': 'flex', 'justifyContent': 'flex-start', 'margin': '5.5rem 15px 2rem 5.8em' }}>
        <SelectionControl style={{ height: 'auto',marginTop:'-5px' }} id='enabled_login' type="switch"  label={userDetails.enabled ? "Enabled" : "Disabled"} labelBefore="true" name="enabled" value={userDetails.enabled} disabled={readOnly} checked={userDetails.enabled || false} onChange={handleToggleChange} />
      </div>
      }
      </div>
      
      <InputPasswordContainer>
        <PasswordFieldBox>
          <TextFieldLabelUser id='password' enableToggle={userDetails.enabled}>Password</TextFieldLabelUser><br />
          <TextFieldSmall type={browserType[0] === 'Chrome' ? 'text' : passwordType} id="password" className={userDetails.enabled === true ? (passwordType === 'password' ? 'showPassword' : 'hidePassword') : (passwordType === 'password' ? 'showPassword' : 'showPassword') } name="password" value={userDetails.password} maxLength="100" style={{ paddingRight: "31px" }} onChange={onChangeHandler} autocomplete={false} disabled={readOnly ? readOnly : (!userDetails.enabled ? true : false)} />
          { userDetails.enabled === false ? '' :
            <>
              {userDetails.enabled === false || readOnly ?
                <Span>
                  <SvgPasswordHide />
                </Span> :
                <Span onClick={ShowHidePassword}>
                  {userDetails.enabled === true ? (passwordType === "text" ? <SvgPasswordShow /> : <SvgPasswordHide />) : <SvgPasswordHide />}
                </Span>
              }
            </> }
          
        </PasswordFieldBox>
        <ConfirmPasswordDiv>
        <PasswordFieldBox>
          <TextFieldLabelUser id='confirmPassword' enableToggle={userDetails.enabled}>Confirm Password</TextFieldLabelUser><br />
          <TextFieldSmall type={browserType[0] === 'Chrome' ?  'text' : confirmPasswordType} id="confirmPassword" className={userDetails.enabled === true ? (confirmPasswordType === 'confirmPassword' ? 'showPassword' : 'hidePassword') : (confirmPasswordType === 'confirmPassword' ? 'showPassword' : 'showPassword') } name='confirmPassword' value={userDetails.confirmPassword} maxLength="100" style={{ paddingRight: "31px" }} autocomplete={false} onChange={onChangeHandler} disabled={readOnly ? readOnly : (!userDetails.enabled ? true : false)} />
          { userDetails.enabled === false ? '' :
            <>
              { userDetails.enabled === false || readOnly ?
                <Span>
                  <SvgPasswordHide />
                </Span> :
                <Span onClick={ShowHideConfirmPassword}>
                  {userDetails.enabled === true ? (confirmPasswordType === "text" ? <SvgPasswordShow /> : <SvgPasswordHide />) : <SvgPasswordHide />}
                </Span> }
            </> }
        </PasswordFieldBox>
        </ConfirmPasswordDiv>
      </InputPasswordContainer>
      <StatusDetailBox>
        <StatusBar color={passwordStatus} />
        <StatusBox>
          {userDetails.password === userDetails.confirmPassword &&
            <StatusText>{goodPassword}</StatusText>}
          {userDetails.password !== userDetails.confirmPassword &&
            <StatusText1>{badPassword}</StatusText1>}
        </StatusBox>
      </StatusDetailBox>
      <NotesBox>
            <Row>
              <ResponsiveTextFieldLabel style={{ flexShrink: '0' }}>Notes</ResponsiveTextFieldLabel>
              <TextArea label="description" id='description' name="description" rows="1" placeholder='Enter notes here...' value={userDetails.description} disabled={readOnly} onChange={onChangeHandler} />
            </Row> 
      </NotesBox>
        </form>
      </>
      <FlexibleDiv>
        <div id="insidediv">
          {readOnly ?
            <ButtonDiv >
                <div>   
                <ButtonWrapper>
                <Button primary onClick={editUserDetails}>Edit</Button>
              </ButtonWrapper>
                 </div>
            </ButtonDiv> :
            <ButtonDiv >
                <ButtonWrapper>
                <SaveButton primary onClick={onSave}>Save</SaveButton>
                <CancelButton id='btnCancle' danger onClick={onCancel}>Cancel</CancelButton>
              </ButtonWrapper>
            </ButtonDiv>}
        </div>
      </FlexibleDiv>
      </div>
        }
      </div>
      </ContentDiv>
    </div>
  )
}

export default UserLogin
