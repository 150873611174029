import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import axios from 'axios';
import { ContentDiv, Wrapper, Label, LeftDiv, Spacer, TextField, TextArea, CopyButton, TextFieldLabel, ButtonSmall, TextFieldSmall, MandatoryLabel, SelectField, Row, Column, RightDiv, SectionHeader, Button, SectionLabel, Th, Td } from '../utils/Styles';
import SelectOptions from '../components/SelectOptions';
import * as Constants from '../utils/Constants';
import "../css/react-tabs.css"
import swal from "sweetalert";
import styled from 'styled-components';
import { isEmpty } from '../utils/Utils';
import Loader from './Loader';
import axiosRetry from 'axios-retry';
import {CircularProgress } from 'react-md';
import { convertToTimeZone } from 'date-fns-timezone';
import { format } from 'date-fns';
import {FaPaste} from 'react-icons/fa';

const LoaderDiv = styled.div`
    @media screen and (max-width: 425px) {
        width: 10px;
        height: 10px;
    }
`;

const OtpTabContainer = styled.div`
    width: 580px;
    position: relative;
    padding: 10px 25px;

    @media screen and (max-width: 850px) {
        width: 395px;
    }
    @media screen and (max-width: 700px) {
        width: 332px;
    }
    @media screen and (max-width: 420px) {
        width: 285px;
    }
`;
const AdvancedTabContainer = styled(Column)`
    width: 580px;
    padding: 10px 25px;

    @media screen and (max-width: 850px) {
        width: 395px;
    }
    @media screen and (max-width: 700px) {
        width: 332px;
    }
    @media screen and (max-width: 420px) {
        width: 285px;
    }
`;
const InputFieldContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

const StreamPresentDiv = styled.div`
    padding: 10px 25px;
`;

const TableLayout = styled.div`
    margin-bottom: 15px;
    white-space: nowrap;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    & > svg {
        margin: 10px;
    }
`;

const ResponsiveWrapper = styled(Wrapper)`
    @media screen and (max-width: 700px) {
        font-size: 0.9rem;
    }
    @media screen and (max-width: 425px) {
        font-size: 0.75rem;
    }
    @media screen and (max-width: 400px) {
        font-size: 0.65rem;
    }
`;

const DEFAULT_CONNECTION_DATA = {
    'type': 'safie_settings',
    'enabled':true,
    'configuration_time': '',
    'refresh_token': '',
    'client_id':'',
    'client_secret':'',
    'api_version': 'v2',
    'scope': '',
    'safie_url': 'https://openapi.safie.link',
    'access_token_status':''
}

const SafieCameraSettings = (props) => {

    const [connectionData, setConnectionData] = useState(DEFAULT_CONNECTION_DATA);
    const [loading, setLoading] = useState(true);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [isStreamPresent, setStreamPresent] = useState(false);
    const [isConfigured, setIsConfigured] = useState(false);
    const [deleteState, setDeleteState] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState('');
    const [currentTab, setCurrentTab] = useState('otpTab');
    const [otp, setOtp] = useState('');
    const [retry,setRetry]=useState(false);
    const [curTimezone, setCurTimeZone]=useState();
    const [standaredTZ, setStandardTZ]=useState();
    const [httpReq, setHttpReq]= useState(false);

    const getStreamList = () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/all')
        .then(res => {
            const stacks = res.data["data"]["stacks"];
            Object.keys(stacks).map((stack_name) => {
                if(stacks[stack_name]["action"] === "DeleteStack"){
                    delete stacks[stack_name];
                } else if(stacks[stack_name]["services"]["recorder1"]["camera_type"] !== "SAFIE"){
                    delete stacks[stack_name];
                } 
                else if(stacks[stack_name] !== undefined) {
                    if(stacks[stack_name].enabled === false){
                        delete stacks[stack_name];
                    }
                }
            });
            if(Object.keys(stacks).length === 0){
                setStreamPresent(false);
            } else {
                setStreamPresent(true);
            }
        }).catch(error => {
           console.log(error);
        });
    }
    const checkAllValuesPresent = useCallback((obj) => {
        const desiredObj = ['type', 'enabled', 'configuration_time', 'refresh_token', 'client_id', 'client_secret', 'scope','safie_url'];
        let isAllValuesPresent;        
        isAllValuesPresent = Object.values(obj).every((itm) => {
            if(itm===''){
                return false;
            } else {
                return true;
            }
        }) && desiredObj.every((itm) => {
            if(!Object.keys(obj).includes(itm)){
                return false;
            } else {
                return true;
            }
        });
        return isAllValuesPresent;
    },[]);

    const getConfigDetails = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client,{
            retries: 15,
            retryDelay: (retryCount, error) => {
                if(retryCount < 15 && retryCount > 5) {
                    setRetry(true)
                 } else if(retryCount === 15) {
                   setRetry(false)
                   swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
                 }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
          });
        setLoading(true);
        client.get('/system/safie/status/all')
        .then(res => {
            const data = res.data.data;
            if(Object.keys(data).length !== 0) {
                let inputs = Object.assign({}, data.services["safie-settings1"]);
                if(checkAllValuesPresent(inputs)){
                    setConnectionData(inputs);
                    setLoading(false);
                    setRefreshLoading(false);
                    setIsConfigured(true);
                    if(data.action === 'DeleteStack') {
                        if(data.action_status.status === 'completed') {
                            setDeleteStatus('Deleted');
                            setConnectionData(DEFAULT_CONNECTION_DATA);
                            setDeleteState(false);
                            setIsConfigured(false);
                            setOtp('');
                        } else if(data.action_status.status === 'failed') {
                            setDeleteStatus('Failed')
                            setDeleteState(false);
                        } else {
                            setDeleteStatus('deleting...')
                            setDeleteState(true);
                        }
                    }
                } else {
                    setConnectionData(DEFAULT_CONNECTION_DATA);
                    setLoading(false);    
                }
            } else {
                setConnectionData(DEFAULT_CONNECTION_DATA);
                setLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        let requestProtocol = window.location.protocol;
        if (requestProtocol === 'http:') {
            setHttpReq(true);
        } else if (requestProtocol === 'https:') {
            setHttpReq(false);
        }
    }, [])

    useEffect(() => {
        getConfigDetails();
        getStreamList();
    },[]);

    const onRefresh = useCallback(()=>{
        setRefreshLoading(true);
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/safie/status/all')
        .then(res => {
            setRefreshLoading(false);
            const data = res.data.data;
            if(data.action === 'DeleteStack') {
                if(data.action_status.status === 'completed') {
                    setDeleteStatus('Deleted');
                    setDeleteState(false);
                    setIsConfigured(false);
                    setConnectionData(DEFAULT_CONNECTION_DATA);
                    setOtp('');
                } else if(data.action_status.status === 'failed') {
                    setDeleteStatus('Failed')
                    setDeleteState(false);
                } else {
                    setDeleteStatus('deleting...')
                    setDeleteState(true);
                }
            } else {
                if(Object.keys(data).length !== 0) {
                    let inputs = Object.assign({}, data.services["safie-settings1"]);
                    if(checkAllValuesPresent(inputs)){
                        setConnectionData(inputs);
                        setIsConfigured(true);
                    } else {
                        setConnectionData(DEFAULT_CONNECTION_DATA);
                        setRefreshLoading(false);
                    }
                } else {
                    setConnectionData(DEFAULT_CONNECTION_DATA);
                    setRefreshLoading(false);
                }
            }
        })
        .catch(error => {
            setRefreshLoading(false);
            console.log(error);
        });
    },[getConfigDetails]);

    // const onHandleToggle = (value, event) => {
    //     const name = event.target.name;
    //     setConnectionData(prev => ({ ...prev, [name]: value }));
    // }

    // const onSavedStateToggle = (value, event) => {
    //     let finalJson = {
    //         type: 'safie',
    //         namespace: 'system',
    //         enabled: value,
    //         services: {
    //             safie_settings1: {
    //                 enabled: true,
    //                 type: 'safie_settings',
    //                 refresh_token: connectionData.refresh_token,
    //                 client_id: connectionData.client_id,
    //                 client_secret: connectionData.client_secret
    //             }
    //         }
    //     };
    //     setLoading(true);
        
    //     axios.patch(Constants.EDGE_API_ENDPOINT + '/system/safie/all', finalJson)
    //     .then(res => {
    //         swal(`Safie connector ${value ? 'Enabled' : 'Disabled'} successfully.`, { icon: 'success' });
    //         getConfigDetails();
    //     })
    //     .catch(error => {
    //         if(error.response){
    //             var errorObj = error.response.data;
    //             swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
    //         }else{
    //             swal({text: "Unable to connect to the edge-api service" , icon: "error"});
    //         }
    //         setLoading(false);
    //     });
    // }

    const handleOtpChange = useCallback((e)=>{
        setOtp(e.target.value);
    },[]);

    const handleOnChange = (event) => {
        if (event.target.name === 'refresh_token' || event.target.name === 'client_id' || event.target.name === 'client_secret' ) {     
            if (event.target.value.charAt(0) === '_') {
                return event.target.value.replace(/[^\w]/g, '');
            } else {
                event.target.value = event.target.value.replace(/[^\w\-]/g, '');
            }
        } else if (event.target.name === 'scope') {
            event.target.value = event.target.value.replace(/^\s*/, '');
        } else if (event.target.name === 'safie_url') {
            event.target.value = event.target.value.replace(' ', '');
        }
        setConnectionData({ ...connectionData, [event.target.name]: event.target.value });
    };

    const onSave = (typeOfAutorization) => {

        setLoading(true);

        let jsonData = Object.assign({}, connectionData);
        let safieEnable = jsonData.enabled;

        let finalJson = {};
        finalJson.type = 'safie';
        finalJson.namespace = "system";
        finalJson.enabled = safieEnable;
        finalJson.services = {};
        finalJson.services["safie-settings1"] = {};
        finalJson.services["safie-settings1"].enabled = true;
        finalJson.services["safie-settings1"].type = 'safie_settings';
        finalJson.services["safie-settings1"].type_of_authorization = typeOfAutorization;

        if (typeOfAutorization === 'advanced') {
            let refreshToken = jsonData.refresh_token;
            let clientId = jsonData.client_id;
            let clientSecret = jsonData.client_secret;
            let apiVersion = jsonData.api_version;
            let scope = jsonData.scope;
            let safie_url = jsonData.safie_url;
            
            if (isEmpty(clientId)) {
                setLoading(false);
                swal('Please enter client id', { icon: 'error' });
                return;
            }
            if (isEmpty(clientSecret)) {
                setLoading(false);
                swal('Please enter client secret', { icon: 'error' });
                return;
            }
            if (isEmpty(refreshToken)) {
                setLoading(false);
                swal('Please enter refresh token', { icon: 'error' });
                return;
            }
            if (isEmpty(scope)) {
                setLoading(false);
                swal('Please enter scope', { icon: 'error' });
                return;
            }
            if (isEmpty(safie_url)) {
                setLoading(false);
                swal('Please enter url', { icon: 'error' });
                return;
            }

            const lastCharacter = scope.toString().charAt(scope.toString().trim().length - 1);
            if (lastCharacter === ',') {
                setLoading(false);
                swal('Scope should be separated by a comma, comma at the end is not allowed.', { icon: 'error' });
                return;
            } else {
                let splitScopeValues = scope.toString().split(',');
                let invalidScopes = [];

                for (let i = 0; i < splitScopeValues.length; i++) {
                    let trimmedScope = splitScopeValues[i].trim();
                    if (trimmedScope.includes(' ')) {
                        invalidScopes.push(trimmedScope);
                        setLoading(false);
                        swal(`Scope "${trimmedScope}" should be separated by a comma.`, { icon: 'error' });
                        return;
                    }
                }
            }
            let scopeJsonArray = [scope];
            let withoutSpaces = scopeJsonArray.toString().replace(/\s/g, "");
            let splitScopeJson = withoutSpaces.toString().split(',');
            let hasDuplicateScope = splitScopeJson.some((element, index) => {
                return splitScopeJson.indexOf(element) !== index;
            });

            if (hasDuplicateScope) {
                setLoading(false);
                swal(`Duplicate scopes are not allowed.`, { icon: 'error' });
                return;
            }
            finalJson.services["safie-settings1"].refresh_token = refreshToken;
            finalJson.services["safie-settings1"].client_id = clientId;
            finalJson.services["safie-settings1"].client_secret = clientSecret;
            finalJson.services["safie-settings1"].api_version = apiVersion;
            finalJson.services["safie-settings1"].scope = splitScopeJson;
            finalJson.services["safie-settings1"].safie_url = safie_url;
            } else if (typeOfAutorization === 'otp') {
            if (isEmpty(otp)) {
                setLoading(false);
                swal('Please enter One-time Password', { icon: 'error' });
                return;
            }
            finalJson.services["safie-settings1"].otp = otp;
        } else {
            setLoading(false);
            return;
        }

        axios.put(Constants.EDGE_API_ENDPOINT + '/system/safie/all', finalJson)
        .then(res => {
            swal('Safie connector configured successfully.', { icon: 'success' });    
            setLoading(false);
            getConfigDetails();
        })
        .catch(error => {
            setLoading(false);
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const deleteConfiguration = () => {
        if(isStreamPresent) {
            swal('Please disable or delete all Safie streams before deleting Safie connector configuration.', { icon: 'error' });
            return;
        } else {
            swal({
                text: 'Are you sure you want to delete this account ?',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                icon: 'warning'
              })
            .then((isConfirm) => {
                if (isConfirm) {
                    setLoading(true);
                    axios.delete(Constants.EDGE_API_ENDPOINT + '/system/safie/all')
                    .then(res => {
                        swal('Safie connector delete request submitted successfully.', { icon: 'success' });
                        setOtp('');
                        setConnectionData(DEFAULT_CONNECTION_DATA)
                        getConfigDetails();
                    })
                    .catch(error => {
                        getConfigDetails();
                        if(error.response){
                            var errorObj = error.response.data;
                            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                        }else{
                            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                        }
                    });
                }
            });
        }
    };

    const getClockStatus = useCallback(() => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        client.get('/system/clock/status/all')
            .then(res => {
                let data = res.data.data.timezone;
                let splitTimeZone = data.split(' (');
                setCurTimeZone(splitTimeZone[0])
                setStandardTZ(splitTimeZone[1])
                let splitZone = splitTimeZone[1].split(',');
                setStandardTZ(splitZone[0])
            }).catch(error => {
                console.error('clock data');
            });
    }, []);

    useEffect(() => {
        getClockStatus()
    }, []);

    const pasteOTP = async () => {
        try {
            const copidText = await navigator.clipboard.readText();
            setOtp(copidText);
        }
        catch {
            console.warn('Clipboard data not support on HTTP server.');
        }
    };

    const getDateFormate = (Date, curTimezone) => {
        try {
            const zonedTargetDate = convertToTimeZone(Date, { timeZone: curTimezone });
            return zonedTargetDate;
        } catch (err) {
            console.error(err);
        }
    };

    let createdTime = connectionData.configuration_time;
    let newDate = new Date(createdTime * 1000);
    let calculatedDate = getDateFormate(newDate, curTimezone);
    if (calculatedDate === undefined) {
        return '-';
    }

    let date = format(new Date(calculatedDate), 'yyyy/MM/dd');
    let time = format(new Date(calculatedDate), 'HH:mm:ss');

    return (
            <ContentDiv>
                <ResponsiveWrapper>
                    <SectionHeader>
                        <SectionLabel>Safie Connector</SectionLabel>
                    </SectionHeader>
                      { loading &&
                     <div className="loading-msg">
                         <label>{retry ? 'Retrying...' : 'Loading...'}</label>
                            <CircularProgress />
                              </div>
                    }                    { !loading &&
                    <div>
                    {
                    isConfigured ?
                        <StreamPresentDiv>
                            {/* <SelectionControl id="enable" type="switch" label={connectionData.enabled ? "Enabled" : "Disabled"} name="enabled" labelBefore="true" checked={connectionData.enabled || false} onChange={onSavedStateToggle} /> */}
                            <div style={{maxWidth: '640px', marginTop: '10px'}}>
                                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button id='btnRevoke' danger onClick={deleteConfiguration} disabled={deleteState} style={{"background": deleteState, "border": deleteState && 'none', "opacity": deleteStatus==='deleting...' && '0.4'}}>Delete</Button>
                                    <Button id='btndisabled'  style={{display: 'flex', alignItems: 'center'}} disabled={refreshLoading} primary onClick={onRefresh}>Refresh <LoaderDiv className="loader" style={{'marginLeft':'10px', 'display': refreshLoading ? 'block' : 'none'}} /></Button>
                                </div>
                                <TableLayout>
                                    <table style={{ 'width': '100%', 'border-collapse': 'collapse'}}>
                                        <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                                            <Th style={{'padding': '10px', 'textAlign': 'left', 'width': '230px'}}>Client ID</Th>
                                            <Th style={{'padding': '10px'}}>Status</Th>
                                            <Th style={{'padding': '10px'}}>Created</Th>
                                        </tr>
                                        <tr>
                                            <Td style={{'padding': '10px', 'textAlign': 'left'}}>{`${connectionData.client_id} ${connectionData.api_version === "v2" ? '(Safie API 2.x)' : '(Safie API 1.x)'}`}</Td>
                                            <Td style={{'padding': '10px', color: deleteStatus==='Failed' && 'red', fontWeight: 'bold'}}>
                                                {
                                                    deleteState ? 
                                                        deleteStatus
                                                    : deleteStatus==='Failed' ?
                                                            deleteStatus 
                                                        : <div style={{color: connectionData.access_token_status === 'OK' ? 'green' : 'red'}}>
                                                            {connectionData.access_token_status === 'OK' ? 'Good' : 'Bad'}
                                                          </div>
                                                }
                                            </Td>
                                          <Td style={{'padding': '10px'}}>{date} {time} {standaredTZ}</Td>
                                        </tr>
                                    </table>
                                </TableLayout>
                            </div>
                        </StreamPresentDiv>
                    :   <Tabs
                            defaultTab={currentTab}
                            onChange={(tabId) => { setCurrentTab(tabId) }}
                        >
                            <TabList>
                                <Tab tabFor="otpTab">One-time Password</Tab>
                                <Tab tabFor="advancedTab">Advanced</Tab>
                            </TabList>
                            <TabPanel tabId="otpTab">
                            {!loading &&
                                <OtpTabContainer>
                                    <TextFieldLabel id='otp_label' style={{'margin': '16px 0px 15px', 'width': '100%'}}>
                                        Please enter the one-time password generated by <a href="https://safie-auth.scorer.jp/" target="_blank" style={{'white-space': 'nowrap'}}>https://safie-auth.scorer.jp</a>
                                    </TextFieldLabel>
                                    <div style={{display:'flex'}} >
                                    <TextField id="otp" autoComplete="off" name="otp" value={otp} onChange={handleOtpChange} />
                                    {
                                        httpReq ? null :
                                        <FaPaste type='button' style={{'fontSize':'24px', 'cursor':'pointer', 'margin':'5px 0 0 15px', 'color':'#40bde3'}} title="Paste from clipboard" onClick={pasteOTP} />
                                    }
                                    </div>
                                    {/* <MessageContaier>
                                        <MessageLabel style={{width: 'max-content'}}>
                                            <span>To generate OTP please visit this </span>
                                            <a href="https://safie-auth.scorer.jp/" target="_blank">site</a> .
                                        </MessageLabel>
                                    </MessageContaier> */}
                                    <ButtonWrapper>
                                        <Button style={{marginRight: httpReq ? '0px' : '37px'}} primary onClick={()=>{onSave('otp')}}>Submit</Button>
                                    </ButtonWrapper>
                                </OtpTabContainer>}
                            </TabPanel>
                            <TabPanel tabId="advancedTab">
                                <Row>
                                    <AdvancedTabContainer>
                                        {/* <div style={{'display':'flex', width:'100%'}}>
                                            <SelectionControl id="enable" type="switch" label={connectionData.enabled ? "Enabled" : "Disabled"} name="enabled" labelBefore="true" checked={connectionData.enabled || false} onChange={onHandleToggle} />
                                        </div> */}
                                        <InputFieldContainer>
                                            <MandatoryLabel id='client_id_label' style={{'width':'160px'}}>Client ID</MandatoryLabel>
                                            <TextField id="client_id" autoComplete="off" name="client_id" value={connectionData.client_id} onChange={handleOnChange} />
                                        </InputFieldContainer>
                                        <InputFieldContainer>
                                            <MandatoryLabel id='client_secret_label' style={{'width':'160px'}}>Client Secret</MandatoryLabel>
                                            <TextField id="client_secret" autoComplete="off" name="client_secret" value={connectionData.client_secret} onChange={handleOnChange} />
                                        </InputFieldContainer>
                                        <InputFieldContainer>
                                            <MandatoryLabel id='refresh_token_label' style={{'width':'160px'}}>Refresh Token</MandatoryLabel>
                                            <TextField id="refresh_token" autoComplete="off" name="refresh_token" value={connectionData.refresh_token} onChange={handleOnChange} />
                                        </InputFieldContainer>
                                        <InputFieldContainer>
                                            <MandatoryLabel id='api_version_label' style={{'width':'160px'}}>API Version</MandatoryLabel>
                                            <SelectField id="api_version" name="api_version" value={connectionData.api_version} onChange={handleOnChange} disabled={false}>
                                                <SelectOptions items={['v1', 'v2']}/>
                                            </SelectField>
                                        </InputFieldContainer>
                                        <InputFieldContainer>
                                            <MandatoryLabel id='scope_label' style={{'width':'160px'}}>Scope</MandatoryLabel>
                                            <TextField id="scope" autoComplete="off" name="scope" value={connectionData.scope} onChange={handleOnChange} />
                                        </InputFieldContainer>
                                        <InputFieldContainer>
                                            <MandatoryLabel id='safie_url_label' style={{'width':'160px'}}>URL</MandatoryLabel>
                                            <TextField id="safie_url" autoComplete="off" name="safie_url" value={connectionData.safie_url} onChange={handleOnChange} />
                                        </InputFieldContainer>
                                        <ButtonWrapper>
                                            <Button primary onClick={()=>{onSave('advanced')}}>Submit</Button>
                                        </ButtonWrapper>
                                    </AdvancedTabContainer>
                                </Row>
                            </TabPanel>
                        </Tabs>
                    }
                    </div>}
                </ResponsiveWrapper>
            </ContentDiv>
            )
}

export default SafieCameraSettings;