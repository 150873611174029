import React, { useState, useEffect, useCallback,useMemo } from 'react';
import axios from 'axios';
import { SelectionControl, CircularProgress } from 'react-md';
import * as Constants from '../utils/Constants';
import { isEmpty } from '../utils/Utils';
import { Column, Row, Wrapper, Button, TextField, RightDiv, SectionHeader, SectionLabel, SelectFieldLabel, SelectFieldSmall, TextFieldLabel, MessageLabel, TextFieldSmall, Td, Th, TableContainer, PopupContainer, PopupButtons, FieldSet, overlayStyle } from '../utils/Styles';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import {validateIpAddress, onBlurIpAddress} from './NetworkSettings.js';
import styled from 'styled-components';
import { detectBrowser } from '../utils/Utils';
import axiosRetry from 'axios-retry';


const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const ResponsiveButton = styled(Button)`
  margin: 0;
  @media screen and (max-width: 900px) {
    font-size: 0.8em;
  }
  @media screen and (max-width: 830px) {
    font-size: 0.7em;
  }
`;

const TabsContainer = styled(Tabs)`
  width: 870px;
  @media screen and (max-width: 930px) {
    width: 730px;
  }
  @media screen and (max-width: 800px) {
    width: 340px;
  }
  @media screen and (max-width: 400px) {
    width: 280px;
  }
`;

const LeftInputs = styled.div`
  @media screen and (max-width: 800px) {
    width: 310px;
  }
  @media screen and (max-width: 400px) {
    width: 235px;
  }
`;

const IpSettingFieldSet = styled(FieldSet)`
  width: 440px;
  @media screen and (max-width: 930px) {
    width: 350px;
  }
  @media screen and (max-width: 800px) {
    width: 310px;
  }
  @media screen and (max-width: 400px) {
    width: 235px;
    // margin: 0px 0px 70px 0px !important;
  }
`;

const ResponsiveTextFieldLabel1 = styled(TextFieldLabel)`
  width: 180px;
  @media screen and (max-width: 950px) {
    width: 160px;
  }
  @media screen and (max-width: 400px) {
    width: 110px;
  }
`;

const MessageContaier = styled.div`
  padding: 5px 5px 1px 5px;
`;

const GeneralInterface = styled.div`
  // height: 300px;
  display: flex;
  @media screen and (max-width: 930px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
  }
`;

const ContentDiv=styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const ResponsivePopupContainer = styled.div`
  width:  100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  z-index: 5;
  & > div {
    @media screen and (max-width: 750px) {
      transform: scale(0.8);
      background: transparent !important;
    }
  }
`;

const TdMobile = styled.td`
  border: 1px solid #ddd;
  text-align:center;
  padding-left: 5px;
  padding-right: 5px;
  @media screen and (max-width: 1400px) {
    font-size:14px;
  }
  @media screen and (max-width: 450px) {
    font-size:10px;
  }
`;

const NetworkInterfaces = (props) => {
  const [readOnly, setReadOnly] = useState(true);
  const [ifaceData, setIfaceData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInterface, setSelectedInterface] = useState();
  const [loading, setLoading] = useState(false);
  const [retry,setRetry]=useState(false)
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);

  useEffect(()=>{
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  },[]);

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'max-content',
    height: 'auto',
    padding: '5px',
    // minWidth: '65%',
    // maxWidth: '65%',
    border: '2px solid #d7d7d7'
  };

  const editInterface = () => {
    setReadOnly(false);
    setModalOpen(true);
  };

  const addNewInterface = (event) => {
    setReadOnly(true);
    setSelectedInterface(undefined);
    setModalOpen(true);
  };

  const radioButtonOnChange = useCallback((ifaceName) => {
    let ifData = Object.assign({}, ifaceData.services[ifaceName]);
    ifData.iface_name = ifaceName;
    setSelectedInterface(ifData);
    setReadOnly(false);
  }, [ifaceData]);

  const closePopup = (iface_name) => {
    setModalOpen(false);
    setReadOnly(true);
    radioButtonOnChange(iface_name);
  };

  const closePopupAdd = () => {
    setReadOnly(true);
    setModalOpen(false);
  };

  const enableToggle = (value, event) => {
    let ifaceName = event.target.name;
    var jsonData = {
      'enabled': value
    };
    var actionLabel = value ? "enable" : "disable";
    var msg = "\n\n Are you sure you want to " + actionLabel + " this interface?";
    if (value) {
      axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/' + ifaceName + '/all', jsonData)
        .then(res => {
          swal('Interface ' + (value ? 'enabled' : 'disabled') + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      swal({
        text: msg,
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/' + ifaceName + '/all', jsonData)
            .then(res => {
              swal('Interface ' + (value ? 'enabled' : 'disabled') + ' successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      });
    }
  };

  const getInterfaces = () => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/network/interfaces/all')
      .then(res => {
        let ifData = res.data.data;
        client.get('/system/network/interfaces/status')
          .then(res =>{
            let ifStatusData = res.data.data;
            if (ifStatusData && 'if_list' in ifStatusData){
              let ifStatusList = ifStatusData.if_list;
              
              Object.keys(ifStatusList).map((ifName) => {
                if(ifName in ifData.services){
                  ifData.services[ifName].status = ifStatusList[ifName];
                }
                /*else {
                  ifData.services[ifName] = {};
                  ifData.services[ifName].status = ifStatusList[ifName];
                }*/
                return ifName;
              });
            }
            let sortedData = [];
            Object.keys(ifData.services).map((ifName) => {
              let data = ifData.services[ifName];
              data.if_name = ifName;
              data.status = data.status || {};
              data.status.status = data.status.status || ''
              sortedData.push(data);
              return ifName;
            });

            let upStatus = [];
            Object.keys(sortedData).map((ifName) => {
              let data = sortedData[ifName];
              if(data.status.status === 'UP') {
                data.status.route_metric = data.status.route_metric || data.route_metric || '99999'
                upStatus.push(data);
              }
              return ifName;
            });

            let downStatus = [];
            Object.keys(sortedData).map((ifName) => {
              let data = sortedData[ifName];
              if(data.status.status === 'DOWN') {
                data.status.route_metric = data.route_metric || '99999'
                downStatus.push(data);
              }
              return ifName;
            });

            let emptyStatus = [];
            Object.keys(sortedData).map((ifName) => {
              let data = sortedData[ifName];
              if(data.status.status === '') {
                data.status.route_metric = data.route_metric || '99999'
                emptyStatus.push(data);
              }
              return ifName;
            });

            upStatus.sort((a, b) => {
              // if (a.status.route_metric > b.status.route_metric) return 1;
              // if (a.status.route_metric.toLowerCase() < b.status.route_metric.toLowerCase()) return -1;
              return a.status.route_metric - b.status.route_metric;
            });

            downStatus.sort((a, b) => {
              // if (a.status.route_metric.toLowerCase() > b.status.route_metric.toLowerCase()) return 1;
              // if (a.status.route_metric.toLowerCase() < b.status.route_metric.toLowerCase()) return -1;
              return a.status.route_metric - b.status.route_metric;
            });

            emptyStatus.sort((a, b) => {
              // if (a.status.route_metric.toLowerCase() > b.status.route_metric.toLowerCase()) return 1;
              // if (a.status.route_metric.toLowerCase() < b.status.route_metric.toLowerCase()) return -1;
              return a.status.route_metric - b.status.route_metric;
            });

            let SortedStatus = [...upStatus, ...downStatus, ...emptyStatus];

            SortedStatus.sort((a, b) => {
              if (a.status.status.toLowerCase() < b.status.status.toLowerCase()) return 1;
              if (a.status.status.toLowerCase() > b.status.status.toLowerCase()) return -1;
              return 0;
            });

            ifData.sorted_list = SortedStatus;
            setIfaceData(ifData);
            setLoading(false);
          })
          .catch(err => {
            console.log(err);
            setLoading(false);
          });
          setLoading(false);
      }).catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const refreshInterfaces = (event) => {
    setLoading(true);
    document.getElementById('btnRefreshIfaces').textContent = 'Refreshing...';
    let jsonData = { 'action': 'RefreshStatus', 'source': 'EdgeUI' };
    axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/all', jsonData)
      .then(res => {
        getInterfaces();
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() =>{
    if(document.getElementById('btnRefreshIfaces')){
      document.getElementById('btnRefreshIfaces').textContent = 'Refresh';
    }
  }, [ifaceData]);

  useEffect(() => {
    getInterfaces();
  }, []);

  useMemo(()=>{
  document.addEventListener('keydown', (e) => {
    if(e.keyCode == 116){
       e.preventDefault()
       refreshInterfaces();
    }
});
},[])

  return (
    <ContentDiv>
      { (ifaceData === undefined) &&
      <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
        <CircularProgress/>
      </div>
      }
      { ifaceData &&
    <Wrapper style={{'marginTop':'5px'}}>
      <SectionHeader>
        <SectionLabel>Interfaces (Layer 3)</SectionLabel>
      </SectionHeader>
      <Row>
        <div style={{'display': 'flex', 'gap': '10px', 'justify-content': 'flex-end', 'flex-wrap': 'wrap', 'margin': '1em 0'}}>
          { !readOnly &&
            <ResponsiveButton primary id="btnEditIface" name="btnEditIface" onClick={() => editInterface()}>Edit</ResponsiveButton>
          }
          <ResponsiveButton primary id="btnRefreshIfaces" name="btnRefreshIfaces" disabled={loading} onClick={() => refreshInterfaces()}>Refresh</ResponsiveButton>
          <ResponsiveButton primary id="btnAddIfaces" name="btnAddIfaces" onClick={() => addNewInterface()}>Add Interface</ResponsiveButton>
          {
            modalOpen && 
              <ResponsivePopupContainer>
                <Popup modal closeOnDocumentClick={false} open={modalOpen} onClose={() => {}} {...{ contentStyle, overlayStyle}}>
                  <InterfacePopup readOnly={readOnly} closePopupAdd={closePopupAdd} closePopup={closePopup} getInterfaces={getInterfaces} selectedInterface={selectedInterface} availablePorts={props.availablePorts}/>
                </Popup>
              </ResponsivePopupContainer>
          }
        </div>
      </Row>
      <TableLayout>
        <table style={{'min-width': '100%', 'padding':'11px', 'border-collapse': 'collapse'}}>
          <tr style={{'background-color': '#1f303a', 'color': 'white'}}>
            <Th ></Th>
            <Th style={{'text-align': 'left'}}>Name</Th>
            <Th>Status</Th>
            <Th>IP Address</Th>
            <Th>Prefix</Th>
            <Th>Gateway / <br /> Next Hop</Th>
            <Th>Metric</Th>
            <Th>Provider</Th>
            <Th>Enabled</Th>
          </tr>

          { Object.keys(ifaceData.sorted_list).map((idx) => {
            let ifData = ifaceData.sorted_list[idx];
            let ifaceName = ifData.if_name;
            let nickname = ifData.nickname ? ' (' + ifData.nickname + ')' : '';
            let ifaceWithNick = ifaceName + nickname;
            let ipv4 = '-', ipv6 = '-', ipv4_subnet = '-', ipv6_subnet = '-', ipv4_gateway = '-', ipv6_nexthop = '-', status = '-', route = '-', ipv6Provider = '-', Ipv6Arr = [];
            if('status' in ifData){
              ipv4 = ifData.status?.ipv4?.ip;
              
              ipv6 = ifData.status?.ipv6?.map((idx) => {
                let address = idx.addr.split('/');
                return <div>{address[0]}</div>
              })

              Ipv6Arr = ifData.status?.ipv6?.filter((idx) => idx.addr !== '')

              ipv6_subnet = ifData.status?.ipv6?.map((idx) => {
                let subnet = idx.addr.split('/');
                return <> <div>{subnet[1] && `/${subnet[1]}`}</div></>
              })

              ipv6Provider = ifData.status?.ipv6?.map((idx) => {
                let subnet = idx.addr;
                return <> <div>{subnet.length > 0 ? 'SLAAC' : ''}</div></>
              })

              ipv6_nexthop = ifData.status?.ipv6?.map((idx) => { return <div>{idx.nexthop === '' ? '' : idx.nexthop}</div> })

              if (ifData.status.ipv4?.subnet !== '' && ifData.status.ipv4?.subnet !== undefined) {
                ipv4_subnet = '/' + ifData.status.ipv4?.subnet;
              } else {
                ipv4_subnet = '';
              }

              if (ifData.status.ipv4?.gateway !== '' && ifData.status.ipv4?.gateway !== undefined) {
                ipv4_gateway = ifData.status.ipv4?.gateway;
              } else {
                ipv4_gateway = '-';
              }

              status = ifData.status.status;
              route = ifData.status.route_metric;
            }
            
            return(
              <tr>
                <Td><input type="radio" id="select_iface" style={{'cursor':'pointer'}} name="select_iface" value={ifaceName} onClick={()=> radioButtonOnChange(ifaceName)}></input></Td>
                <TdMobile title={ifaceWithNick} style={{'text-align':'left','padding':'10px','white-space': 'nowrap','overflow': 'hidden','text-overflow': 'ellipsis','cursor':'pointer','max-width': '200px'}}>{ifaceWithNick}</TdMobile>
                <Td>{status === '' ? '-': status}</Td>

                {/* IP Address */}
                <Td>
                  {status !== 'UP' ?
                    <> {
                      status === '' ? '-' :
                        <div>
                          {(ipv4 === '' && Ipv6Arr.length === 0) ? '-' :
                            <div style={{ 'color': 'grey', 'textTransform': 'lowercase' }}>
                              <div>{ipv4 === '' || (ifData.dhcp === true && ifData.enabled === false) ? '' : ipv4}</div>
                              <div>{ipv6}</div>
                            </div>
                          }
                        </div>
                      }
                    </>
                    :
                    <div>
                      <div>{ipv4 === '' ? '' : ipv4}</div>
                      <div>{ipv6}</div>
                    </div>
                  }
                </Td>
                {/* =========================== */}

                {/* Prefix */}
                <Td>
                  {status !== 'UP' ?
                    <>
                    {
                       status === '' ? '-' :
                    <div>
                      {(ipv4 === '' && Ipv6Arr.length === 0) ? '-' :
                        <div style={{ 'color': 'grey', 'textTransform': 'lowercase' }}>
                          <div>{ipv4_subnet === '' || (ifData.dhcp === true && ifData.enabled === false) ? '' : ipv4_subnet}</div>
                        <div>{ipv6_subnet}</div>
                        </div>
                      }
                    </div>
                    }
                    </>
                    :
                    <div>
                      <div>{ipv4_subnet}</div>
                      <div>{ipv6_subnet === '' ? '' : ipv6_subnet}</div>
                    </div>
                  }
                </Td>
                {/* ======================= */}

                {/* Gateway/ Next Hop */}
                <Td>
                  {status !== 'UP' ?
                    <>
                      {
                        status === '' ? '-' :
                          <div>
                            {(ipv4 === '' && Ipv6Arr.length === 0) ? '-' :
                              <div style={{ 'color': 'grey', 'textTransform': 'lowercase' }}>
                                <div>{ipv4_gateway === '' || (ifData.dhcp === true && ifData.enabled === false) ? '' : ipv4_gateway}</div>
                                <div>{ipv6_nexthop}</div>
                              </div>
                            }
                          </div>
                      }
                    </>
                      :
                    <div>
                      <div>{ipv4_gateway === '' ? '' : ipv4_gateway}</div>
                      <div>{ipv6_nexthop === '' ? '' : ipv6_nexthop}</div>
                    </div>
                  }
                </Td>
                {/* ================================== */}

                {/* Metrix */}
                <Td>
                  { (ipv4 === '' && Ipv6Arr.length === 0) ? '-' : <div>{ifData.route_metric === '' ? (route === '99999' ? '-' : ('(' + route + ')')) : (route === '99999' ? '-' : route)}</div> }
                </Td>
                {/* ====================== */}

                {/* Provider */}
                <Td>
                  <div>{((status === '' && ipv4 === undefined && ifData.dhcp === true) && '-') || ((ipv4 === '' && status !== 'UP') && '-') || ((ipv4 !== '' && ifData.dhcp === true) && 'DHCPv4') || ((ipv4 === '' && ifData.dhcp === true) && 'DHCPv4') || ((ipv4 !== '' && ifData.dhcp === false) && 'Static')}</div>
                  <div>{ipv6Provider}</div>
                </Td>
                {/* ===================== */}

                {/* Enabled/Disabled */}
                <Td><SelectionControl type="switch" id={ifaceName} name={ifaceName} checked={ifData.enabled || false} labelBefore="true" onChange={enableToggle} style={{ 'display': 'block', 'paddingTop': '10px' }} /></Td>
                {/* ==================== */}
              </tr>
            );
          })}
        </table>
      </TableLayout>
        <MessageContaier>
        {isMobileBrowser ? 
          <MessageLabel>  
            <b>Metric: </b>It is an integer and smaller number means higher priority. Metric in a parentheses is assigned by the system<br/> automatically.
          </MessageLabel> : 
          <MessageLabel> 
          <b>Metric: </b>It is an integer and smaller number means higher priority. Metric in a parentheses is assigned by the system automatically.
          </MessageLabel>}
        </MessageContaier>
    </Wrapper>}
    </ContentDiv>
  );
};

const getroutData = () => {
  return {
    'gateway': '',
    'destination': '',
    'metric': '1024'
  };
};

function getDefaultIfaceData(){
  let data = {
    'type': 'link',
    'auto_dns' : true,
    'dhcp' : true,
    'enabled' : true,
    'gateway' : '',
    'ip' : '',
    'link_type' : 'ethernet',
    'nickname' : '',
    'preferred_dns' : '8.8.8.8',
    'route_metric' : '',
    'subnet_mask' : '',
    'alternate_dns': '',
    'routes': []
  }
  data.routes.push()
  return data;
}

const InterfacePopup = (props) => {
  const { readOnly, closePopup, selectedInterface, availablePorts, getInterfaces, closePopupAdd } = props;
  const [ifacesSettings, setIfacesSettings] = useState(selectedInterface === undefined ? getDefaultIfaceData() : selectedInterface);
  const [index, setIndex] = useState(0);
  const [selectedRow, setSelectedRow] = useState();
  const [refreshPopUpTable, setRefreshPopUpTable] = useState(false);

  const onCancel = useCallback(() => {
    closePopup(ifacesSettings.iface_name);
  },[ifacesSettings])

  useEffect(()=> {
    let pData = selectedInterface === undefined ? getDefaultIfaceData() : selectedInterface;
    if(pData.routes) {
      let ifData = pData.routes;
      let sortedData = [];
      Object.keys(ifData).map((idx) => {
        let data = {};
        let pData = ifData[idx];
        data.gateway= pData.gateway;
        data.destination= pData.destination;
        data.metric= pData.metric;
        sortedData.push(data);
        return idx;
      });
      sortedData.sort(function(a,b){
        return a.metric - b.metric;
      });
      pData.routes = sortedData;
    }
    setIfacesSettings(pData);
    setIndex(Object.keys(pData.routes).length);
  },[]);

  const radioOnChangeInPopup = (idx) => {
    setSelectedRow(idx);
    setRefreshPopUpTable(false);
  };

  const handleDeleteRoute  = () =>  {
    ifacesSettings.routes.splice(selectedRow, 1);
    setIfacesSettings(ifacesSettings);
    setRefreshPopUpTable(true);
  };

  const handleAddRow = () => {
    if(Object.keys(ifacesSettings.routes).length >= 5){
      swal('Maximum five routes can be configured.', {icon: 'error'});
      return;
    }
    setRefreshPopUpTable(false);
    let newIndex = index + 1;
    setIndex(newIndex);
    ifacesSettings.routes.push(getroutData());
    setIfacesSettings(ifacesSettings);
  };

  const handleCheckBoxChange = (event) => {
    setIfacesSettings({...ifacesSettings, [event.target.name]: event.target.checked});
  };

  const handleOnChange = (event) => {
    if(event.target.name === 'preferred_dns' || event.target.name === 'alternate_dns' ) {
      event.target.value = event.target.value.replace(/[^.:\w]/g, '');   
  } 
    setIfacesSettings({...ifacesSettings, [event.target.name]: event.target.value});
  };

  const updateRoutingSetting = (id, name, value) => {
    let idx = id.substring(id.indexOf('-') + 1);
      let data = { ...ifacesSettings };
      if(data.routes[idx]){
        data.routes[idx][name] = value;
        setIfacesSettings(data);
      }
  };

  const handleOnChangeInnerInput = (event, idx) => {
    let pElement = document.getElementById(event.target.id);
    if(pElement){
      pElement.style.borderColor = 'lightgray';
    }
    if((event.target.id === 'gateway-'+ idx || event.target.id === 'destination-'+ idx || event.target.id === 'metric-'+ idx ) && event.target.value.includes(' ')){
      event.target.value = event.target.value.replace(/\s/g, '');
    } else {
      if(event.target.id === 'gateway-'+ idx ) {
        event.target.value = event.target.value.replace(/[^.:\w]/g, '');  
      } else if(event.target.id === 'destination-'+ idx) {
        event.target.value = event.target.value.replace(Constants.IPV6_SPECIAL_CHAR, '');
      } else if(event.target.id === 'metric-'+ idx) {
        event.target.value = event.target.value.replace(/[^\d]/g, ''); 
      }
    }
    updateRoutingSetting(event.target.id, event.target.name, event.target.value);
  };

  const handleIntegerOnChange = (event) => {
    let regEx = /^[1-9][0-9]*$/;
    if(regEx.exec(event.target.value)){
      setIfacesSettings({...ifacesSettings, [event.target.name]: event.target.value});
    } else {
      if(event.target.value === '' || event.target.value === '0'){
        setIfacesSettings({...ifacesSettings, [event.target.name]: event.target.value});
      }
    }
  };

  const refreshTable = () => {
    let jsonData = { 'action': 'RefreshStatus', 'source': 'EdgeUI' };
    axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/all', jsonData)
      .then(res => {
        getInterfaces();
      })
      .catch(error => {
        console.log(error);
      });
  }

  function validateIPaddress(ipaddress) {
    if(ipaddress === '') {
        return;
    } else {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
          return true
        }
        return false
    }
  }

  function validateNetworkIPaddress(ipaddress) {
    if(ipaddress === undefined || ipaddress === ''){
      return true;
    }
    let subnet = 1;
    // const ip = ipaddress.split('/')[0];
    // if(ipaddress.split('/').length > 1){
    //   subnet = parseInt(ipaddress.split('/')[1]);
    // } 

    let IPV4_REG = (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress));
    if (IPV4_REG) {
      return true;
    } else {
      return false;
    }
  }

  const saveInterface = (event) => {
    setRefreshPopUpTable(false);
    let jsonData = Object.assign({}, ifacesSettings);
    let routes = [];

    let ifaceName = '';
    let action = '';
    if(readOnly){
      action = 'added';
    } else {
      action = 'updated';
    }
    
    if(jsonData.status !== undefined) {
      let metric = jsonData.status.route_metric;
      if(metric === '99999') {
        jsonData.status.route_metric = '';
      }
    }
    

    ifaceName = document.getElementById('iface_name').value;
    if(isEmpty(ifaceName)){
      swal('Please enter interface name.', {icon: 'error'});
      return;
    }
    delete jsonData.iface_name;

    if(ifacesSettings.dhcp === false){
      if(ifacesSettings.ip === '') {
        swal('Please enter IP', {icon: 'error'});
        return;
      }
      if (!isEmpty(ifacesSettings.ip)){
        if(!validateIpAddress('ip', ifacesSettings.ip)){
          return;
        }
      }
      
      let spLength = parseInt(ifacesSettings.subnet_prefix_length);
      if(spLength < 1 || spLength > 64){
        swal('Subnet prefix length should be between 1 and 64', {icon: 'error'});
        return;
      }
      if (!isEmpty(ifacesSettings.route_metric)){
        if(ifacesSettings.gateway === ''){
          swal('Metric requires the gateway. Please provide the gateway address, or remove metric value.', {icon: 'error'});
          return;
        }
      }
      if(!isEmpty(ifacesSettings.gateway)){
        if(!validateIpAddress('gateway', ifacesSettings.gateway)){
          return;
        }
      }
    }
    if(!isEmpty(ifacesSettings.route_metric)){
      let rMetric = parseInt(ifacesSettings.route_metric);
      if(rMetric < 0 ||  rMetric > 4294967295){
        swal('Metric value should be between 0 to 4294967295', {icon: 'error'});
        return;
      }
    }

    if(ifacesSettings.dhcp === false && ifacesSettings.auto_dns === true){
      swal('Automatic dns setting is only allowed when ip setting is dhcp', {icon: 'error'});
      return;
    }

    if(ifacesSettings.auto_dns === false){
      if(!isEmpty(ifacesSettings.preferred_dns) && !validateIpAddress('preferred_dns', ifacesSettings.preferred_dns)){
        return;
      }
      if(!isEmpty(ifacesSettings.alternate_dns) && !validateIpAddress('alternate_dns', ifacesSettings.alternate_dns)){
        return;
      }
      if(isEmpty(ifacesSettings.preferred_dns) && isEmpty(ifacesSettings.alternate_dns)) {
        swal('Please enter atleast one "DNS"', { icon: 'error'});
        return;
      }
    }

    for(var i=0; i < ifacesSettings.routes.length; i++){
      let ssid = ifacesSettings.routes[i].gateway;
      for(var j=i+1; j < ifacesSettings.routes.length; j++){
        if(ifacesSettings.routes[i].gateway === ifacesSettings.routes[j].gateway && ifacesSettings.routes[i].destination === ifacesSettings.routes[j].destination && ifacesSettings.routes[i].metric === ifacesSettings.routes[j].metric ){
          swal('Duplicate gateway IP, destination IP and metric.', {icon: 'error'});
          document.getElementById('gateway-' + j).style.borderColor = 'red';
          document.getElementById('destination-' + j).style.borderColor = 'red';
          document.getElementById('metric-' + j).style.borderColor = 'red';
          return;
        }
      }
    }

    for(var k=0; k < ifacesSettings.routes.length; k++){
      if(document.getElementById('route_row-' + k)){
        let routesData = Object.assign({}, ifacesSettings.routes[k]);
        let gateway = routesData.gateway;
        let destination = routesData.destination;
        let metric = routesData.metric;
        if(gateway === ''){
          swal('Please enter gateway', {icon: 'error'});
          document.getElementById('gateway-' + k).style.borderColor = 'red';
          return;
        }
        if(metric === ''){
          swal('Please enter metric', {icon: 'error'});
          document.getElementById('metric-' + k).style.borderColor = 'red';
          return;
        }
        if(!validateIPaddress(gateway)){
          swal('Invalid gateway IP ' + gateway, { icon: 'error'});
          document.getElementById('gateway-' + k).style.borderColor = 'red';
          return;
        }
        if(!validateNetworkIPaddress(destination)){
          swal('Invalid network IP ' + destination, { icon: 'error'});
          document.getElementById('destination-' + k).style.borderColor = 'red';
          return;
        }
        routes[k] = routesData;
      }
    }

    jsonData.routes = routes;
    jsonData.type = 'link';

    axios.put(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/' + ifaceName + '/all', jsonData)
      .then(res => {
        swal('Interface ' + action + ' successfully.', { icon: 'success'});
        refreshTable();
        setTimeout(function(){ window.location.reload(); }, 2000);
      })
      .catch(error => {
        if(error.response){
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
        }else{
          swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
        }
      });
  };

  const deleteInterface = (event) => {
    let ifaceName = ifacesSettings.iface_name;
    swal({text: 'Are you sure you want to delete this interface?',
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
      .then((isConfirm) => {
        if (isConfirm) {
          axios.delete(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/' + ifaceName + '/all')
            .then(res => {
              swal('Interface deleted successfully.', { icon: 'success'});
              refreshTable();
              setTimeout(function(){ window.location.reload(); }, 2000);
            })
            .catch(error => {
              if(error.response){
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
              } else {
                swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
              }
            });
        }
      });
  };
    
  return (
    <div>
      { ifacesSettings !== undefined &&
      <PopupContainer>
        <SectionHeader style={{'display':'flex'}}>
          <SectionLabel>{ readOnly === true ? 'Add' : 'Edit' } Interface</SectionLabel>
          { readOnly ||
            <div style={{'marginLeft': 'auto'}}>
              <Button danger id="btnDeleteIface" name="btnDeleteIface" style={{'margin': '0px'}}  onClick={deleteInterface}>Delete</Button>
            </div>
          }
        </SectionHeader>
        <TabsContainer defaultTab="general" style={{'padding': '6px', 'height': '350px'}}>
          <TabList>
            <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
            <Tab tabFor="dns" className={'rwt__tab'}>DNS</Tab>
            <Tab tabFor="routing" className={'rwt__tab'}>Routing</Tab>
          </TabList>
          <TabPanel tabId="general">
            <GeneralInterface>
              <LeftInputs style={{'padding': '0px 2%'}}>
                <Row style={{display: 'flex', alignItems: 'baseline'}}>
                  <SelectFieldLabel style={{'width':'100px', flexShrink: '0'}}>Port</SelectFieldLabel>
                  { (readOnly === true && availablePorts) &&
                    <SelectFieldSmall style={{width: '100%', flexGrow: '1'}} id="iface_name" name="iface_name" value={ifacesSettings.iface_name} onChange={handleOnChange} disabled={readOnly ? Object.keys( availablePorts.services).length === 0? true : false : readOnly }>
                      { Object.keys(availablePorts.services).map((pName) => {
                        return(<option value={decodeURIComponent(pName)}>{decodeURIComponent(pName)}</option>);
                      })}
                      { Object.keys(availablePorts.services).length === 0 &&
                      <option value='No port available'>No port available</option>
                      }
                    </SelectFieldSmall>
                  }
                  { readOnly === false &&
                    <SelectFieldSmall style={{width: '100%', flexGrow: '1'}} id="iface_name" name="iface_name" value={ifacesSettings.iface_name} onChange={handleOnChange} disabled={!readOnly}>
                      <option value={ifacesSettings.iface_name}>{ifacesSettings.iface_name}</option>
                    </SelectFieldSmall>
                  }
                </Row>
                <Row style={{display: 'flex', alignItems: 'baseline'}}>
                  <TextFieldLabel style={{'width':'100px', flexShrink: '0'}}>Nickname</TextFieldLabel>
                  <TextFieldSmall style={{width: '100%', flexGrow: '1'}} id="nickname" autoComplete="off" name="nickname" value={ifacesSettings.nickname} onChange={handleOnChange} disabled={readOnly && Object.keys( availablePorts.services).length === 0? true : false } maxLength="100"/>
                </Row>
                <Row style={{display: 'flex', alignItems: 'baseline'}}>
                  <TextFieldLabel style={{'width':'100px', flexShrink: '0'}}>Metric</TextFieldLabel>
                  <TextFieldSmall style={{width: '100%', flexGrow: '1'}} id="route_metric" placeholder='1024' autoComplete="off" name="route_metric" value={ifacesSettings.route_metric} onChange={handleIntegerOnChange} disabled={readOnly && Object.keys( availablePorts.services).length === 0? true : false } maxLength="10"/>
                </Row>
              </LeftInputs>
              <div style={{'padding': '0px 2%' , marginTop:"-7px"}}>
                <IpSettingFieldSet>
                  <legend>IP Setting</legend>
                  <input type="checkbox" id="dhcp" name="dhcp" style={{'marginTop':'15px'}} value={ifacesSettings.dhcp} checked={ifacesSettings.dhcp} disabled={readOnly && Object.keys( availablePorts.services).length === 0? true : false } onChange={handleCheckBoxChange} /> 
                DHCP
                  {  !ifacesSettings.dhcp && 
                <div style={{'marginLeft':'5px'}}>
                  <Row style={{display: 'flex', alignItems: 'baseline'}}>
                    <ResponsiveTextFieldLabel1 style={{flexShrink: '0'}}>IP Address</ResponsiveTextFieldLabel1>
                    <TextFieldSmall style={{width: '100%', flexGrow: '1', 'textTransform':'lowercase'}} id="ip" name="ip" autoComplete="off" placeholder='192.168.10.23' value={ifacesSettings.ip} onChange={handleOnChange}/>
                  </Row>
                  <Row style={{display: 'flex', alignItems: 'baseline'}}>
                    <ResponsiveTextFieldLabel1 style={{flexShrink: '0'}}>Subnet Prefix Length</ResponsiveTextFieldLabel1>
                    <TextFieldSmall style={{width: '100%', flexGrow: '1'}} id="subnet_prefix_length" name="subnet_prefix_length" autoComplete="off" value={ifacesSettings.subnet_prefix_length} maxLength="2" placeholder="24" onChange={handleIntegerOnChange}/>
                  </Row>
                  <Row style={{display: 'flex', alignItems: 'baseline'}}>
                    <ResponsiveTextFieldLabel1 style={{flexShrink: '0'}}>Gateway</ResponsiveTextFieldLabel1>
                    <TextFieldSmall style={{width: '100%', flexGrow: '1', 'textTransform':'lowercase'}} id="gateway" name="gateway" placeholder='192.168.10.1' autoComplete="off" value={ifacesSettings.gateway} onChange={handleOnChange}/>
                  </Row>
                </div>
                  }
                </IpSettingFieldSet>
              </div>
            </GeneralInterface>
          </TabPanel>
          <TabPanel tabId="dns">
            <div style={{'height': '350px'}}>
              <div style={{'padding': '10px 2%'}}>
                <Row>
                  <input type="checkbox" id="auto_dns" name="auto_dns" style={{'marginTop':'15px'}} value={ifacesSettings.auto_dns} disabled={readOnly && Object.keys( availablePorts.services).length === 0? true : false } checked={ifacesSettings.auto_dns} onChange={handleCheckBoxChange} /> 
                Automatic
                  { !ifacesSettings.auto_dns && 
                    <div style={{'marginLeft':'5px', width: 'max-content'}}>
                      <Row style={{display: 'flex', alignItems: 'baseline', maxWidth: '310px'}}>
                        <TextFieldLabel style={{width: '120px', flexShrink: '0'}}>Preferred DNS</TextFieldLabel>
                        <TextFieldSmall style={{width: '100%', flexGrow: '1', 'textTransform':'lowercase'}} id="preferred_dns" placeholder='8.8.8.8' autoComplete="off" name="preferred_dns" value={ifacesSettings.preferred_dns} onBlur={onBlurIpAddress} onChange={handleOnChange}/>
                      </Row>
                      <Row style={{display: 'flex', alignItems: 'baseline', maxWidth: '310px'}}>
                        <TextFieldLabel style={{width: '120px', flexShrink: '0'}}>Alternate DNS</TextFieldLabel>
                        <TextFieldSmall style={{width: '100%', flexGrow: '1', 'textTransform':'lowercase'}} id="alternate_dns" placeholder='8.8.4.4' autoComplete="off" name="alternate_dns" value={ifacesSettings.alternate_dns} onBlur={onBlurIpAddress} onChange={handleOnChange}/>
                      </Row>
                    </div>
                  }
                </Row>
              </div>
            </div>
          </TabPanel>
          <TabPanel tabId='routing'>
            <div style={{'height': '350px'}}>
              <Row>
                <RightDiv style={{'display': 'inline-block', 'marginRight': '2px'}}>
                  {selectedRow && <Button id="btnDeleteRoutes" name="btnDeleteRoutes" onClick={() => handleDeleteRoute()}>Delete</Button>}
                  <Button primary id={ Object.keys( availablePorts.services).length === 0 ? "btndisabled" : "btnRoute"} name="btnRoute" onClick={() => handleAddRow()} disabled={readOnly && Object.keys( availablePorts.services).length === 0? true : false } style={{'marginRight': '0'}}>Add Route</Button>
                </RightDiv>
              </Row>
              <Row>
                <TableLayout >
                  {(refreshPopUpTable || ifacesSettings) && 
                  <table style={{'min-width': '100%', 'border-collapse': 'collapse'}}>
                    <tr style={{'background-color': '#1f303a', 'color': 'white'}}>
                      <Th></Th>
                      <Th style={{'textAlign': 'left'}}>Gateway</Th>
                      <Th>Network</Th>
                      <Th>Metric</Th>
                    </tr>
                    { Object.keys(ifacesSettings.routes).map((idx) => (
                    <tr id={'route_row-' + idx}>
                      <Td>
                        <input type="radio" id={'route_radio-' + idx} name={'route_radio'} value={idx} style={{'cursor':'pointer'}} onClick={()=> radioOnChangeInPopup(idx)}></input>
                      </Td>
                      <Td><TextField style={{'margin': '1px', 'textTransform':'lowercase'}} autoComplete="off" maxLength="39" id={'gateway-' + idx} name={'gateway'} placeholder='192.168.10.23' value={ifacesSettings.routes[idx].gateway} onChange={(e) => handleOnChangeInnerInput(e, idx)} /></Td>
                      <Td><TextField style={{'margin': '1px', 'textTransform':'lowercase'}} autoComplete="off" maxLength="42" id={'destination-' + idx} placeholder='192.168.10.1' name={'destination'} value={ifacesSettings.routes[idx].destination} onChange={(e) => handleOnChangeInnerInput(e, idx)} /></Td>
                      <Td><TextField style={{'margin': '1px'}} autoComplete="off" maxLength="10" id={'metric-' + idx} name={'metric'} placeholder='1024' value={ifacesSettings.routes[idx].metric} onChange={(e) => handleOnChangeInnerInput(e, idx)} /></Td>
                    </tr>
                    ))}
                  </table>}
                </TableLayout>
                {/* <MessageContaier>
                  <MessageLabel>
                    <b>Note: </b>Maximum five routes can be configured.<br/>
                  </MessageLabel>
                </MessageContaier> */}
              </Row>
              </div>
          </TabPanel>
          
        </TabsContainer>
        <Row>
          <PopupButtons>
            { ((readOnly === false) || (availablePorts && Object.keys(availablePorts.services).length > 0)) &&
              <Button onClick={saveInterface} primary >Save</Button>
            }
            {readOnly ? 
              <Button id="btnCancelIface" danger onClick={()=> {closePopupAdd();}}>Cancel</Button>
              :<Button id="btnCancelIface" danger onClick={onCancel}>Cancel</Button>}
          </PopupButtons>
        </Row>
      </PopupContainer>
      }
    </div>
  );
};

export default NetworkInterfaces;
