import React, { useState, useEffect, useCallback } from 'react';
import { SelectionControl, CircularProgress } from 'react-md';
import axios from 'axios';
import swal from 'sweetalert';
import { ContentDiv, Wrapper, Label, Spacer, Row, Column, SectionHeader, SectionLabel, RightDiv, TextField, SelectField } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import Accordion from './Accordion';
import AutoRefresh from '../components/AutoRefresh';
import styled from 'styled-components';
import axiosRetry from 'axios-retry';

const ResponsiveWrapper = styled(Wrapper)`
  @media screen and (max-width: 750px) {
    font-size: 0.95rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 550px) {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 0.68rem;
  }
`;

const ArrowDiv=styled.div`
  padding:5px 22px 0px 0px;
@media screen and (max-width: 400px) {
  padding : 2px 22px 0px 0px;
}
`;

const SectionHeaderStatus=styled.div`
margin: 5px;
padding: 5px 5px 10px 15px;
border-bottom: 1px solid lightgray;
background-color: #ffffff;

@media screen and (max-width: 400px) {
  padding: 5px 5px 7px 15px;

}
`;

const Bold=styled.b`
  @media screen and (max-width: 400px) {
    font-size : 9px;
  }
`;

const CurrentStatus = (props) => {
  const [streamStatusData, setStreamStatusData] = useState();
  const [projectStatusData, setProjectStatusData] = useState();
  const [systemStatusData, setSystemStatusData] = useState();
  const [extensionData, setExtensionData] = useState();
  const [retry,setRetry]=useState(false)
  const [checkStreamType,setCheckStreamType]=useState(false)
  const [configure, setConfigure] = useState(false);

  const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
  axiosRetry(client,{
    retries: 15,
    retryDelay: (retryCount, error) => {
      if(retryCount < 15 && retryCount > 5) {
        setRetry(true)
     } else if(retryCount === 15) {
       setRetry(false)
       swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
     }
        return 3000;
    },
    retryCondition: (error) => {
        return true;
    },
});

  function getSystemStatus(){
    client.get('/system/status/all')
      .then(res => {
        let systemStacks = res.data.data;
        if(systemStacks.stacks['system-services'] !== undefined){
          setSystemStatusData(systemStacks);
        } else {
          setSystemStatusData({'stacks':{ 'system-services': {'services':{}}}});
        }

    client.get('/stacks/all')
    .then(res => {
        const stacks = res.data["data"]["stacks"];
        setCheckStreamType(stacks)
        
    }).catch(error => {
        if(error.response){
            var errorObj = error.response.data;
            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
        }else{
            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
        }
    });
      })
      .catch(error => {
        console.log(error);
      });
  }

  function getSafieConnecterData(stackName){
    try{
      return configure;
    }catch(error){
        console.log(error);
    }
}

  function getStreamType(stackName){
    var streamStatus = "";
    try{
     if(checkStreamType){
      return checkStreamType[stackName].services.recorder1.camera_type;
     }
      
    }catch(error){
        console.log(error);
    }
    return streamStatus;
}

const getConfigDetails = () => {
  axios.get(Constants.EDGE_API_ENDPOINT + '/system/safie/all')
  .then(res => {
      if(res.data.data.action === 'DeleteStack') {
          setConfigure(false);
      } else {
          if(res.data.data.services["safie-settings1"].enabled === true ) {
              setConfigure(true);
          } else {
              setConfigure(false);
          }
      }
  })
  .catch(error => {
      console.log(error);
      setConfigure(false);
  });
}

useEffect(() => {
  getConfigDetails();
},[]);

  function getStreamStatus(){
    client.get('/stacks/status/all')
      .then(res => {
        let respData = res.data.data;
        Object.entries(respData.stacks).map(([key, value]) => {
          // Do not show deleted stack
          if(value.running_status.status === 'deleted'){
            delete respData.stacks[key];
          } else {
            if(respData.running_status === undefined){
              respData.running_status = {};
              respData.running_status.status = respData.stacks[key].running_status.status;
            } else if(respData.running_status !== undefined){
              if(respData.running_status !== respData.stacks[key].running_status){
                let existingPriority = Constants.STATUS_PRIORITY[respData.running_status.status];
                let newPriority = Constants.STATUS_PRIORITY[respData.stacks[key].running_status.status];
                if(existingPriority < newPriority){
                  respData.running_status.status = respData.stacks[key].running_status.status;
                }
              }
            }
            // Do not show deleted services
            let servicesList = respData.stacks[key].services;
            Object.entries(servicesList).map(([key1, value1]) => {
              if(value1.running_status.status === 'deleted'){
                delete servicesList[key1];
              }
              return value1;
            });
          }
          return value;
        });
        setStreamStatusData(respData);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getExtension = async() => {
    await client.get('/sdk/extensions/all')
     .then(res => {
      setExtensionData(res.data.data);
    }).catch((err) => {
      console.log(err)
      setExtensionData({});
    });
  }

  useEffect(() =>{
    getExtension();
  },[])

  const getSDKStatus = async() => {
    let extensionData = {}
    await client.get('/sdk/extensions/all')
     .then(res => {
      extensionData = res.data.data;
    }).catch((err) => {
      console.log(err)
    });
    if(Object.keys(extensionData.stacks).length > 0) {
      client.get('/sdk/status/all')
      .then(res => {
        let respData = res.data.data;
        Object.entries(respData.stacks).map(([key, value]) => {
          Object.entries(extensionData.stacks).map(([keyx, valuex]) => {
            if(key === keyx && Object.keys(valuex.meta).length > 0) {
              if(valuex.meta.hide_from_ui === 'true' || valuex.meta.hide_from_ui === true) {
                delete respData.stacks[key];
              }
            } else {
            if(value.running_status.status === 'deleted'){
              delete respData.stacks[key];
            } else {
              if(respData.running_status === undefined){
                respData.running_status = {};
                respData.running_status.status = respData.stacks[key].running_status.status;
                if(key == keyx && Object.keys(valuex.meta).length > 0 && valuex.meta.display_name) {
                  respData.stacks[key].running_status.display_name = valuex.meta.display_name;
                }
              } else if(respData.running_status !== undefined){
                if(Object.keys(valuex.meta).length > 0 && valuex.meta.display_name && key === keyx ) {
                  respData.stacks[key].running_status.display_name = valuex.meta.display_name;
                }
                if(respData.stacks[key] && respData.running_status !== respData.stacks[key].running_status){
                  let existingPriority = Constants.STATUS_PRIORITY[respData.running_status.status];
                  let newPriority = Constants.STATUS_PRIORITY[respData.stacks[key].running_status.status];
                  if(existingPriority < newPriority){
                    respData.running_status.status = respData.stacks[key].running_status.status;
                  }
                }
              }
              // Do not show deleted services
              if(respData.stacks[key]) {
                let servicesList = respData.stacks[key].services;
                Object.entries(servicesList).map(([key1, value1]) => {
                  if(value1.running_status.status === 'deleted'){
                    delete servicesList[key1];
                  }
                  return value1;
                });
              }
            }
            return valuex;
            }
          })
          return value;
        });
        setProjectStatusData(respData);
      })
      .catch(error => {
        console.log(error);
      });
    } else {
      client.get('/sdk/status/all')
      .then(res => {
        let respData = res.data.data;
        Object.entries(respData.stacks).map(([key, value]) => {
            if(value.running_status.status === 'deleted'){
              delete respData.stacks[key];
            } else {
              if(respData.running_status === undefined){
                respData.running_status = {};
                respData.running_status.status = respData.stacks[key].running_status.status;
              } else if(respData.running_status !== undefined){
                if(respData.stacks[key] && respData.running_status !== respData.stacks[key].running_status){
                  let existingPriority = Constants.STATUS_PRIORITY[respData.running_status.status];
                  let newPriority = Constants.STATUS_PRIORITY[respData.stacks[key].running_status.status];
                  if(existingPriority < newPriority){
                    respData.running_status.status = respData.stacks[key].running_status.status;
                  }
                }
              }
              // Do not show deleted services
              if(respData.stacks[key]) {
                let servicesList = respData.stacks[key].services;
                Object.entries(servicesList).map(([key1, value1]) => {
                  if(value1.running_status.status === 'deleted'){
                    delete servicesList[key1];
                  }
                  return value1;
                });
              }
            }
          return value;
        });
        setProjectStatusData(respData);
      })
      .catch(error => {
        console.log(error);
      });
    }
    
  }

  function getDisplayLabel(data, key){
    return (data[key] !== undefined ? data[key] : key);
  }

  function showHideContent(spanId) {
    if(((document.getElementById(spanId).classList).value).includes('hide')){
      document.getElementById(spanId).classList.remove('hide');
      if(spanId === 'section_system' || spanId === 'section_streams' || spanId === 'section_projects'){
        document.getElementById(spanId + '_arrow').innerHTML = '&#9660';
        document.getElementById(spanId + '_arrow').style = "margin: -3.5px;font-size: 19px;color:#4bb5f4";
      }
    }
    else{
      document.getElementById(spanId).classList.add('hide');
      if(spanId === 'section_system' || spanId === 'section_streams' || spanId === 'section_projects'){
        document.getElementById(spanId + '_arrow').innerHTML = '&#9654;';
      }
    }
  }

  const autoRefreshCallback = useCallback(() => {
    getSystemStatus();
    getStreamStatus();
    getSDKStatus();
  }, []);

  useEffect(() => {
    autoRefreshCallback();
  }, [autoRefreshCallback]);

  return (
    <ContentDiv style={{'overflow':'hidden', 'paddingBottom':'65px'}}>
      { (streamStatusData === undefined && projectStatusData === undefined && systemStatusData === undefined) &&
        <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress id="circularProgress"/>
        </div>
      }

      <ResponsiveWrapper>
        <SectionHeaderStatus>
          <SectionLabel>Current Status</SectionLabel>
          <AutoRefresh autoRefresh={autoRefreshCallback} pageName="current_status"/>
        </SectionHeaderStatus>
      </ResponsiveWrapper>

      { systemStatusData &&
        <ResponsiveWrapper>
          <SectionHeader onClick={() => showHideContent('section_system')} style={{'cursor':'pointer', 'backgroundColor': '#fbfbfb'}}>
            <ArrowDiv style={{'float':'left'}}>
              <div id="section_system_arrow" style={{'color':'#4bb5f4', 'fontSize':'15px'}}>&#9654;</div>
            </ArrowDiv>
            <SectionLabel style={{'cursor':'pointer'}}>System </SectionLabel>
            { systemStatusData.stacks['system-services'].running_status &&
                <label className={systemStatusData.stacks['system-services'].running_status.status} style={{'float': 'right'}}>
                  {getDisplayLabel(Constants.STATUS_TYPES, systemStatusData.stacks['system-services'].running_status.status)}
                </label>
            }
          </SectionHeader>
          <Row id="section_system" className="hide">
            <Column style={{'width':'100%'}}>
              { Object.keys(systemStatusData.stacks['system-services'].services).length === 0 &&
                <Label>System services not available.</Label>
              }
              <Accordion allowMultipleOpen showMenu={false}>
                {
                  Object.entries(systemStatusData.stacks['system-services'].services).map(([key,value]) => {
                    return(
                      <div label={getDisplayLabel(Constants.SYSTEM_SERVICES_NAME_LIST, key)} stackName={key} status={value.running_status.status} stackType="system" stackSubType="" className="stackName">
                        {
                          Object.entries(value).map(([key1,value1]) => {
                            if(key1 === 'running_status'){
                              return(
                                <div className="stackServiceNamekey">
                                  <div style={{ 'paddingBottom': '10px'}}>
                                    <Row><b>Status: </b>{getDisplayLabel(Constants.STATUS_TYPES, value1.status)}</Row>
                                    <Row><b>Status Message: </b>{value1.status_message}</Row>
                                    <Row><b>Status Time: </b>{value1.status_time.replace('_', ' ')}</Row>
                                    <Row><b>Log: </b><br/><span style={{'white-space': 'pre-line'}}>{value.running_status.log}</span></Row>
                                  </div>
                                  <p onClick={() => showHideContent('system_services_' + key)} style={{'cursor':'pointer', 'color':'#551A8B'}}><Bold>Parameters</Bold><br /></p>
                                  <span id={'system_services_' + key} className="parameters hide">
                                    {value.env_variables === null ? <div /> : <>
                                      {
                                        Object.entries(value.env_variables).map(([key3, value3]) => {
                                          return(
                                            <span style={{overflowWrap: 'break-word'}}>{value3}<br/></span>
                                          );
                                        })
                                      }
                                    </>}
                                  </span>
                                </div>
                              );
                            }
                            return '';
                          })
                        }
                      </div>
                    );
                  })
                }
              </Accordion>
            </Column>
          </Row>
        </ResponsiveWrapper>
      }

      { streamStatusData &&
        <ResponsiveWrapper>
          <SectionHeader onClick={() => showHideContent('section_streams')} style={{'cursor':'pointer', 'backgroundColor': '#fbfbfb'}}>
            <ArrowDiv style={{'float':'left'}}>
              <span id="section_streams_arrow" style={{'color':'#4bb5f4'}}>&#9654;</span>
            </ArrowDiv>
            <SectionLabel id="streamStatus" style={{'cursor':'pointer'}}>Streams</SectionLabel>
            { streamStatusData.running_status &&
                    <label className={streamStatusData.running_status.status} style={{'float': 'right'}}>
                      {getDisplayLabel(Constants.STATUS_TYPES, streamStatusData.running_status.status)}
                    </label>
            }
          </SectionHeader>
          <Row id="section_streams" className="hide">
            <Column style={{'width':'100%'}}>
              { Object.keys(streamStatusData.stacks).length === 0 &&
                <Label>Stream not available.</Label>
              }
              <Accordion allowMultipleOpen showMenu={true}>
                {
                  Object.entries(streamStatusData.stacks).map(([key,value]) => {
                    let disableMenu = (value.running_status.status === 'desired' && value.running_status.status_message === 'DeleteStack') ? 'disabled' : '';
                    let cameraType=getStreamType(key);
                    let safieConfigure=getSafieConnecterData(key);
                    return(
                      <div label={key} status={value.running_status.status} stackName={key} stackType="streams" stackSubType="" disableMenu={disableMenu} cameraType={cameraType} safieConfigure={safieConfigure} className="stackName">
                        {
                          Object.entries(value).map(([key1,value1]) => {
                            if(key1 === 'running_status'){
                              return(
                                <div style={{'paddingBottom': '10px'}}>
                                  <Row><b>Status: </b>{getDisplayLabel(Constants.STATUS_TYPES, value1.status)}</Row>
                                  <Row><b>Status Message: </b>{value1.status_message}</Row>
                                  <Row><b>Status Time: </b>{value1.status_time.replace('_', ' ')}</Row>
                                </div>
                              );
                            }
                            else if(key1 === 'services'){
                              return(
                                <Accordion allowMultipleOpen showMenu={false}>
                                  {
                                    Object.entries(value1).map(([key2,value2]) => {
                                      let serviceName = key2.replace('1', '');
                                      return(
                                        <div label={serviceName} status={value2.running_status.status} className="stackServiceNamekey">
                                          <div style={{ 'paddingBottom': '10px'}}>
                                            <Row><b>Status: </b>{getDisplayLabel(Constants.STATUS_TYPES, value2.running_status.status)}</Row>
                                            <Row><b>Status Message: </b>{value2.running_status.status_message}</Row>
                                            <Row><b>Status Time: </b>{value2.running_status.status_time.replace('_', ' ')}</Row>
                                            <Row><b>Log: </b><br/><span style={{'white-space': 'pre-line'}}>{value2.running_status.log}</span ></Row>
                                          </div>
                                          <p onClick={() => showHideContent(key + '_' + key2)} style={{'cursor':'pointer', 'color':'#551A8B'}}><Bold>Parameters</Bold><br/></p>
                                          <span id={key + '_' + key2} className="parameters hide">
                                            { value2.env_variables &&
                                    Object.entries(value2.env_variables).map(([key3, value3]) => {
                                      return(
                                        <span>{value3}<br/></span>
                                      );
                                    })
                                            }
                                          </span>
                                        </div>
                                      );
                                    })
                                  }
                                </Accordion>
                              );
                            }
                            return value1;
                          })
                        }
                      </div>
                    );
                  })
                }
              </Accordion>
            </Column>
          </Row>
        </ResponsiveWrapper>
      }

      { projectStatusData &&
        <ResponsiveWrapper>
          <SectionHeader onClick={() => showHideContent('section_projects')} style={{'cursor':'pointer', 'backgroundColor': '#fbfbfb'}}>
            <ArrowDiv style={{'float':'left'}}>
              <span id="section_projects_arrow" style={{'color':'#4bb5f4'}}>&#9654;</span>
            </ArrowDiv>
            <SectionLabel style={{'cursor':'pointer'}}>SDK</SectionLabel>
            { projectStatusData.running_status &&
                    <label className={projectStatusData.running_status.status} style={{'float': 'right'}}>
                      {getDisplayLabel(Constants.STATUS_TYPES, projectStatusData.running_status.status)}
                    </label>
            }
          </SectionHeader>
          <Row id="section_projects" className="hide">
            <Column style={{'width':'100%'}}>
              { Object.keys(projectStatusData.stacks).length === 0 &&
                <Label>Instance not available</Label>
              }
              <Accordion allowMultipleOpen showMenu={true}>
                {
                  Object.entries(projectStatusData.stacks).map(([key, value]) => {
                    let deleteButtonDisable = value.type === 'extension' ? 'disabled' : '';
                    let keyName = (Object.keys(extensionData.stacks).includes(key) && Object.keys(extensionData.stacks[key].meta).length > 0 && extensionData.stacks[key].meta.display_name) ? extensionData.stacks[key].meta.display_name : (key.includes('custom_') ? key.slice(7) : key);
                    let disableMenu = ((value.running_status.status === 'desired' && value.running_status.status_message === 'DeleteStack') || (value.type === 'extension')) ? 'disabled' : '';
                    return(
                      <div label={Constants.SDK_TYPES[value.type] + ' - ' + keyName + ''} stackName={key} status={value.running_status.status} stackType="sdk" stackSubType={value.type} disableMenu={disableMenu} deleteButtonDisable={deleteButtonDisable} show className="stackName">
                        {
                          Object.entries(value).map(([key1,value1]) => {
                            if(key1 === 'running_status'){
                              return(
                                <div style={{'paddingBottom': '10px'}}>
                                  <Row><b>Status: </b>{getDisplayLabel(Constants.STATUS_TYPES, value1.status)}</Row>
                                  <Row><b>Status Message: </b>{value1.status_message}</Row>
                                  <Row><b>Status Time: </b>{value1.status_time.replace('_', ' ')}</Row>
                                </div>
                              );
                            }
                            else if(key1 === 'services'){
                              return(
                                <Accordion allowMultipleOpen showMenu={false}>
                                  {
                                    Object.entries(value1).map(([key2,value2]) => {
                                      return(
                                        <div label={key2.includes('custom_') ? key2.slice(7).replace(keyName, '').substring(1) : key2} status={value2.running_status.status} className="stackServiceNamekey">
                                          <div style={{'paddingBottom': '10px'}}>
                                            <Row><b>Status: </b>{getDisplayLabel(Constants.STATUS_TYPES, value2.running_status.status)}</Row>
                                            <Row><b>Status Message: </b>{value2.running_status.status_message}</Row>
                                            <Row><b>Status Time: </b>{value2.running_status.status_time.replace('_', ' ')}</Row>
                                            <Row><b>Log:</b><br/><span style={{'white-space': 'pre-line'}}>{value2.running_status.log}</span></Row>
                                          </div>
                                          <p onClick={() => showHideContent(key + '_' + key2)} style={{'cursor':'pointer', 'color':'#551A8B'}}><Bold>Parameters</Bold><br /></p>
                                          <span id={key + '_' + key2} className="parameters hide">
                                            { value2.env_variables &&
                                            Object.entries(value2.env_variables).map(([key3, value3]) => {
                                              return(
                                                <span>{value3}<br/></span>
                                              );
                                            })
                                            }
                                          </span>
                                        </div>
                                      );
                                    })
                                  }
                                </Accordion>
                              );
                            }
                            return '';
                          })
                        }
                      </div>
                    );
                  })
                }
              </Accordion>
            </Column>
          </Row>
        </ResponsiveWrapper>
      }
    </ContentDiv>
  );
};

export default CurrentStatus;
