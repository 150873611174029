import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { SelectionControl, CircularProgress } from 'react-md';
import { Row, Button, SectionHeader, SectionLabel, CenterDiv, Label, TableContainer, Td, Th, } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import swal from 'sweetalert';
import styled from 'styled-components';
import axiosRetry from 'axios-retry';
import { convertToTimeZone } from 'date-fns-timezone';
import { format } from 'date-fns';
import APITesterLink from './APITesterLink';

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
  @media screen and (max-width: 750px) {
    font-size: 0.95rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

const ContentDiv = styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Table = styled.table`
  border-collapse: collapse; 
  table-layout: fixed;
  min-width: 100%;
`;

export const onRefreshApiTester = React.createContext({
  onRefresh: false
});

const APITester = (props) => {
  const [isEditPopup, setEditPopup] = useState(true);
  const [apiTesterData, setApiTesterData] = useState();
  const [loading, setLoading] = useState(false);
  const [onRefresh, setOnRefresh] = useState(false);
  const [CurTimezone, setCurTimeZone]=useState();
  const [standaredTZ, setStandardTZ]=useState();
  const [retry,setRetry]=useState(false)
  let pathArray = window.location.pathname.split('/');
  const basename = pathArray[2];


  const enableToggle = (value, event) => {
    let apiTesterName = event.target.name;
    var actionLabel = value ? "enable" : "disable";

    var jsonData = {
      'namespace': Constants.NAMSPACES.SDK,
      'type': Constants.SDK_SERVICE_TYPES.API_TESTER,
      'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE
    };

    var msg = "\n\n Are you sure you want to "+ actionLabel +" this API Tester?";

    if (value) {
      axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/api_tester/' + apiTesterName + '/all', jsonData)
        .then(res => {
          swal('API Tester ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      swal({
        text: msg,
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/api_tester/' + apiTesterName + '/all', jsonData)
            .then(res => {
              swal('API Tester ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      })
    }
  };

  const getApiTester = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
         setRetry(true)
      } else if(retryCount === 15) {
        setRetry(false)
        swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
      }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/sdk/api_tester/all')
      .then(res => {
        let data = res.data.data;
        if(Object.keys(res.data.data.stacks).length === 0) {
          setEditPopup(false);
        }
        let sortedData = [];
        if('stacks' in data){
          Object.keys(data.stacks).map((stackName) => {
            let stackData = data.stacks[stackName];
            stackData.name = stackName;
            sortedData.push(stackData);
            return stackName;
          });
          sortedData.sort(function(a,b){
            const dateFormatB = b.created_time.split(' ');
            const dateFormatA = a.created_time.split(' ');
            return dateFormatB - dateFormatA;
          });
        }
        data["sorted_list"] = sortedData;
        if(Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].name;
          let instData = Object.assign({}, data.stacks[name].services.apitester1);
          instData.instance_id = name;
          instData.nickname = data.sorted_list[0].nickname;
          setEditPopup(true);
        }

        client.get('/sdk/api_tester/status/all')
          .then(res => {
            let apiTesterStatusData = res.data.data;
            if (apiTesterStatusData && 'stacks' in apiTesterStatusData) {
              let apiTesterStatusList = apiTesterStatusData.stacks;
              Object.keys(apiTesterStatusList).map((apiTesterName) => {
                if (apiTesterName in data.stacks) {
                  data.stacks[apiTesterName].status = apiTesterStatusList[apiTesterName];
                }
                return apiTesterName;
              });
              setApiTesterData(data);
            } else {
              setApiTesterData(data);
            }
            setLoading(false);
            setOnRefresh(false);
          })
          .catch(err => {
            setApiTesterData(data);
            setOnRefresh(false);
            setLoading(false);
          });
      }).catch(error => {
        setApiTesterData([]);
        setLoading(false);
      });
  },[]);

  const addInstance = (event) => {
    let jsonData = Object.assign({});
    let apiTesterName = 'apitester1';

    jsonData.instance_id = 'apitester1';
    jsonData.network_access= 'allow_inside_device';
    jsonData.type = 'apitester';
    jsonData.enabled = true;

    let action = isEditPopup ? 'updated' : 'added';
    let finalJson = {};
    finalJson.enabled = apiTesterData.stacks[apiTesterName] === undefined ? true : apiTesterData.stacks[apiTesterName].enabled;
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = 'api-tester';
    finalJson.services = {};
    delete jsonData.nickname;
    finalJson.services.apitester1 = Object.assign({}, jsonData);

    if (action === 'added') {
      axios.put(Constants.EDGE_API_ENDPOINT + '/sdk/api_tester/' + apiTesterName + '/all', finalJson)
        .then(res => {
          swal('API Tester enabled successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    }
  };

  const deleteApiTester = (event) => {
    let apiTesterName = 'apitester1';
    swal({
      text: `Are you sure you want to delete this API Tester?`,
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
      .then((isConfirm) => {
        if (isConfirm) {
          axios.delete(Constants.EDGE_API_ENDPOINT + '/sdk/api_tester/' + apiTesterName + '/all')
            .then(res => {
              swal('API Tester deleted successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              console.log('in error');
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      });
  };

  const refreshInstances = (event) => {
    setLoading(true);
    setOnRefresh(true);
    document.getElementById('btnRefrehInstance').textContent = 'Refreshing...';
    getApiTester();
  };

  function getDisplayLabel(data, key) {
    return (data[key] !== undefined ? data[key] : key);
  }

  useEffect(() => {
    if (document.getElementById('btnRefrehInstance')) {
      document.getElementById('btnRefrehInstance').textContent = 'Refresh';
    }
  }, [apiTesterData]);

  useEffect(() => {
    getApiTester();
  }, [getApiTester]);

  const getClockStatus = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    client.get('/system/clock/status/all')
    .then(res => {
      let data=res.data.data.timezone;
      let splitTimeZone = data.split(' (');
      setCurTimeZone(splitTimeZone[0])
      setStandardTZ(splitTimeZone[1])
      let splitZone = splitTimeZone[1].split(',');
      setStandardTZ(splitZone[0])
    }).catch(error => {
      console.error('clock data');
    });
  },[]);

  useEffect(()=>{
    getClockStatus()
  },[])

  const getDateFormate = (Date,CurTimezone) => {     
    try {
      const zonedTargetDate = convertToTimeZone(Date, { timeZone: CurTimezone });
      return zonedTargetDate;
    } catch (err) {
      console.error(err);
      // return new Date(Date);
    }
  };

  return (
    <ContentDiv>
      { (apiTesterData === undefined) &&
        <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress />
        </div>
      }
      {apiTesterData &&
        <Wrapper style={{ 'padding': '20px' }}>
          <Row>
            <SectionHeader>
              <SectionLabel>API Tester</SectionLabel>
            </SectionHeader>
          </Row>
          <Row>
            <ActionButtonContainer>
              {
                apiTesterData.stacks && Object.keys(apiTesterData.stacks).length > 0 &&
                <Button danger id="btnDeleteInstance" name="btnDeleteInstance" onClick={deleteApiTester} >Delete</Button>
              }
              {apiTesterData.stacks && Object.keys(apiTesterData.stacks).length > 0 &&
                <Button primary id="btnRefrehInstance" name="btnRefrehInstance" disabled={loading} onClick={() => refreshInstances()}>Refresh</Button>
              }
            </ActionButtonContainer>
          </Row>
          {apiTesterData.stacks && Object.keys(apiTesterData.stacks).length > 0 &&
            <TableLayout>
              <Table>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th>Status</Th>
                  <Th>Created</Th>
                  <Th>Dashboard</Th>
                  <Th>Enabled</Th>
                </tr>
                { Object.keys(apiTesterData.sorted_list).map((index) => {
                  let instName = apiTesterData.sorted_list[index].name;
                  let stackData = apiTesterData.sorted_list[index].services.apitester1;
                  let apiTesterName = stackData.instance_id;
                  let formattedDate = apiTesterData.stacks[instName].created_time.split(' ');
                  var newDate = new Date(formattedDate*1000);
                  let calculatedDate = getDateFormate(newDate,CurTimezone);
                  if(calculatedDate === undefined){
                    return ;
                  }
                  let date = format(new Date(calculatedDate), 'yyyy/MM/dd');
                  let time = format(new Date(calculatedDate), 'HH:mm:ss');
                  let enabled = apiTesterData.stacks[instName].enabled;
                  let apiTesterStatus = 'recovering';
                  if ('status' in apiTesterData.stacks[instName]) {
                    apiTesterStatus = apiTesterData.stacks[instName].status.running_status.status;
                  }
                  let host = '-';
                  let port = stackData.external_port;
                  let dockerName;
                  if(stackData.network_access === 'allow_inside_device'){
                    host = instName;
                    dockerName = 'sdk_bridge_network';
                  }
                  let path = '';
                  if(window.location.pathname.includes('/proxy')) {
                    path = window.location.host + '/proxy/' + basename + '/';
                  } else {
                    path = window.location.host;
                  }

                  return (
                    <tr key={index}>
                      <Td style={{ 'text-align': 'center', 'white-space': 'nowrap' }}>
                        {apiTesterStatus === '-' ?
                          '-' :
                          <label className={apiTesterStatus}>
                            {getDisplayLabel(Constants.STATUS_TYPES, apiTesterStatus)}
                          </label>}
                      </Td>
                      <Td>{date} {time} {standaredTZ}</Td>
                      <Td>
                        { enabled ? 
                        <onRefreshApiTester.Provider value={{ onRefresh }}>
                          <APITesterLink enabled={enabled} path={path} port={port} apiTesterStatus={apiTesterStatus} />
                        </onRefreshApiTester.Provider> : <span style={{'color': 'gray'}}>-</span> }
                      </Td>
                      <Td><SelectionControl type="switch" id={apiTesterName} name={apiTesterName} value={apiTesterData.stacks[instName].enabled} checked={apiTesterData.stacks[instName].enabled} labelBefore="true" onChange={enableToggle} style={{ 'display': 'block', 'paddingTop': '10px' }} /></Td>
                    </tr>
                  ); 
                })}
              </Table>
            </TableLayout>
          }
          {apiTesterData.stacks && Object.keys(apiTesterData.stacks).length === 0 &&
            <Row>
              <Label style={{ 'margin-left': '25px', 'margin-top': '10px' }}>API Tester is not enabled.</Label>
            </Row>
          }
          <Row>
            {apiTesterData.stacks && Object.keys(apiTesterData.stacks).length === 0 &&
              <Button style={{ 'margin-left': '25px', 'margin-top': '16px' }} primary id="btnAddInstance" name="btnAddInstance" onClick={addInstance}>Enable</Button>
            }
          </Row>
        </Wrapper>
      }
    </ContentDiv>
  );
};

export default APITester;
