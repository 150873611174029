import React, { Component, useState, useEffect } from 'react';
import { TabPanel } from 'react-web-tabs';
import axios from 'axios';
import { Label, Label3, Spacer, TextField, SelectField, Row, Column, LeftDiv, RightDiv, ImageIcon, Button, ButtonSmall, DelteButtonSmall } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import swal from "sweetalert";
import { List, ListItem, SelectionControl } from 'react-md';
import styled from 'styled-components';
import { ReactComponent as CopySVG } from '../images/copy.svg';

const HR = styled.hr`
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
`;

const ResponsiveHR = styled(HR)`
    display: none;
    @media screen and (max-width: 1100px) {
        display: block;
        margin-top: 25px;
    }
`;

const ResponsiveRow = styled(Row)`
    display: flex;
    @media screen and (max-width: 1100px) {
        flex-direction: column;
    }
    & > ${Column} {
        @media screen and (max-width: 1100px) {
            width: 100%;
            max-width: 600px;
        }
    }
`;

const LabelDestination = styled.label`
  display:inline-block;
  margin-bottom:5px;
  @media screen and (max-width: 400px) {
    font-size:8px !important;
}
`;
const LabelDestination1 = styled.label`
  display:inline-block;
  margin-bottom:5px;
  user-select : text;
  @media screen and (max-width: 400px) {
    font-size:9px !important;
}
`;

const IntervalDiv= styled.div`
    @media screen and (max-width: 400px) {
        margin-top : 10px;
    }
`;

const CopyButtonDestination = styled(CopySVG)`
  height: 20px;
  width: 18px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
  padding: 2px !important;
  margin: 1px 0px 5px 0px !important;
  font-size: 0.7em !important;
  width: 40px;
  @media screen and (max-width: 550px) {
    height: 18px;
    width: 18px;
    margin: -1px 2px 6px 5px !important;
  }
`;

const StreamForwardingTab = (props) => {
    const [readOnly, setReadOnly] = useState(props.readOnly);
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    var isViewPage = props.readOnly;
    const [inputs, setInputs] = useState();
    var initialValues = props.data;
    var stackName = props.stackName;
    var stackStatus = props.stackStatus;
    var serviceName = initialValues.service_name;

    // Update parent with inputs value 
    props.parentCallback(inputs);

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
        } else {
        setIsMobileBrowser(isMobileBrowser);
        }
    },[]);

    useEffect(() => {
        if(isViewPage){
            setInputs(initialValues);
        }
    }, [initialValues.type !== undefined]);

    function validateEndpoints(){
        let destArr = inputs["destinations"];
        for(var i=0; i<destArr.length;i++){
            if(destArr[i].includes("://*")){
                swal({text: `\n\nEndpoint type 'connect' can not have *`});
                return false;
            }
        }
        return true;
    }

    const handleOnChange = (event) => {
        if(event.target.name === "sf_endpoint_type" && event.target.value === "connect"){
            if(!validateEndpoints()){
                return;
            }
        }
        event.persist();
        setInputs({...inputs, [event.target.name]: event.target.value});
    }

    const handleIntegerOnChange = (event) => {
        event.persist();
        let regEx = /^[1-9][0-9]*$/
        if(regEx.exec(event.target.value)){
            setInputs({...inputs, [event.target.name]: event.target.value});
        } else {
            if(event.target.value == "" || event.target.value == "0"){
                setInputs({...inputs, [event.target.name]: event.target.value});
            }
        }
    }

    const handleFloatOnChange = (event) => {
        event.persist();
        let regEx = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/
        if(regEx.exec(event.target.value)){
            setInputs({...inputs, [event.target.name]: event.target.value});
        } else {
            let dotIndex = event.target.value.indexOf(".");
            if(event.target.value === ""){
                setInputs({...inputs, [event.target.name]: event.target.value});
            } else if(dotIndex > 0 && dotIndex ===  event.target.value.length-1){
                setInputs({...inputs, [event.target.name]: event.target.value});
            } else if(dotIndex === 0 && event.target.value.length ===1){
                setInputs({...inputs, [event.target.name]: event.target.value});
            }
        }
    }

    const addStreamForwarder = (event) => {
        event.persist();
        setInputs(Constants.STREAM_FORWARDER_DEFAULT_VALUES);
        setReadOnly(false);
    }

    function deleteStreamForwarderRequest () {
        axios.delete(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/" + serviceName)
            .then(res => {
                setInputs(undefined);
                swal("Stream forwarder deleted successfully.", { icon: "success"});
                setTimeout(function(){ window.location.reload() }, 2000);
            })
            .catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                }else{
                    swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                }
            });
    }

    const deleteStreamForwarder = (event) => {
        event.persist();
        if(isViewPage && initialValues.type !== undefined){
            swal({
                text: "\n\nAre you sure you want to delete this stream forwarder?",
                buttons: ['No', 'Yes'],
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    deleteStreamForwarderRequest ()
                }
            });

        } else {
            setInputs(undefined);
        }
    }

    const editStreamForwarder = (event) => {
        setReadOnly(false);
    }

    const cancelStreamForwarder = (event) => {
        window.location.reload()
    }

    const addDestination = (event) => {
        if(inputs["destination_text"] === undefined){
            return;
        }

        if(inputs["sf_endpoint_type"] === "connect" && inputs["destination_text"].includes("://*")){
            swal({text: `\n\nEndpoint type 'connect' can not have *`});
            return;
        }

        let destinationsList = inputs["destinations"];
        if(destinationsList === undefined){
            destinationsList = [];
        }

        let destinationText = inputs["destination_text"].trim();
        if(destinationsList.indexOf(destinationText) !== -1){
            swal({text: "\n\nDestination already exists"});
            return;
        }

        if(destinationText.startsWith("tcp://") ||
           destinationText.startsWith("ipc://") ||
           destinationText.startsWith("pgm://") ||
           destinationText.startsWith("epgm://"))
       {
            destinationsList.push(destinationText);
            setInputs({...inputs, ["destinations"]: destinationsList});
            setInputs({...inputs, ["destination_text"]: ""});
       } else {
           swal({text: "\nDestination must be starts with tcp://, ipc://, pgm://, epgm://"});
       }
    }

    const clickToCopy = (elementId) => {
        let copyElement = document.getElementById(elementId);
        const textarea = document.createElement('textarea');
        textarea.id = 'copyTextArea';
        textarea.value = copyElement.innerText;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.width = '0px';
        textarea.style.height = '0px';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        copyElement.blur();
        document.getElementById('copyTextArea').remove();
      };

    const deleteDestination = (event) => {
        if(!readOnly){
            let index = event.target.getAttribute("data");
            let destArr = inputs["destinations"];
            let data = {...inputs};
            data.destination_text = destArr.splice(index, 1)[0];
            data.destinations = destArr
            setInputs(data);
        }
    }
    
    const handleToggleChange = (value, event) => {
        if(!readOnly){
            setInputs({...inputs, [event.target.id]: value});
            if(event.target.id == "sf_network_mode") {
                if (value == true){
                    setInputs({...inputs, ["network_mode"]: "host"});
                } else {
                    setInputs({...inputs, ["network_mode"]: ""});
                }
            }
        }
    };

    function isEmpty(val){
        if(val === undefined || val.trim() === ""){
            return true;
        }else{
            return false;
        }
    }

    const saveStreamForwarder = (event) => {
        if(inputs["destinations"].length === 0) {
            swal({text:"Please add destination", icon: "error"})
            return;
        }
        if(inputs["sf_frame_rate_logging"] === true){
            if(isEmpty(inputs["frame_rate_logging_interval"])) {
                swal({text:"Please enter frame rate logging interval", icon: "error"});
                return;
            }
            if(parseFloat(inputs["frame_rate_logging_interval"])===0.0) {
                swal({text:"frame rate logging interval should be > 0", icon: "error"});
                return;
            }
        } else if(inputs["sf_frame_rate_logging"] === false){
                if(parseFloat(inputs["frame_rate_logging_interval"])===0.0){
                    inputs["frame_rate_logging_interval"] = ""
                }
        }
        if(isEmpty(inputs["emit_interval"])) {
            swal({text:"Please enter emit interval", icon: "error"});
            return;
        }
        if(isEmpty(inputs["watchdog_timeout"])) {
            swal({text:"Please enter watchdog timeout", icon: "error"});
            return;
        }
        if(parseInt(inputs["watchdog_timeout"])< 0) {
            swal({text:"Watchdog timeout should be >= 0", icon: "error"});
            return;
        }
        var streamForwarderJson = Object.assign({}, inputs);
        streamForwarderJson["enabled"] = stackStatus;
        streamForwarderJson["frame_rate_logging"] = streamForwarderJson["sf_frame_rate_logging"];
        streamForwarderJson["endpoint_type"] = streamForwarderJson["sf_endpoint_type"];
        streamForwarderJson["network_access"] = streamForwarderJson["sf_network_access"];
        delete streamForwarderJson["sf_frame_rate_logging"];
        delete streamForwarderJson["sf_endpoint_type"];
        delete streamForwarderJson["destination_text"];

        serviceName = (serviceName === undefined) ? streamForwarderJson["type"] + "1" : serviceName;
        if(streamForwarderJson["type"] === 'stream_forwarder'){
            serviceName = 'stream-forwarder1'
        }
        axios.put(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/" + serviceName , streamForwarderJson)
        .then(res => {
            setReadOnly(true);
            //swal("Stream forwarder updated successfully.", { icon: "success"});
            setTimeout(function(){ window.location.reload() }, 500);
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service", icon: "error"});
            }
        });
    }

    const handleRadioChange = (event) => {
        event.persist();
        let data = { ...inputs };
        data[event.target.name] = event.target.value;
        setInputs(data);
      };

    return (
            <TabPanel tabId="streamForwardingTab">
                <Row>
                    <ResponsiveRow>
                    { (inputs === undefined || inputs.type === undefined) &&
                        <Column>
                            <Button primary onClick={addStreamForwarder}>Add Stream Forwarder</Button>
                        </Column>
                    }
                    </ResponsiveRow>
                </Row>
            { (inputs !== undefined && inputs.type !== undefined) &&
                <Row>
                    <ResponsiveRow>
                        <Column>
                            <Row>
                                <fieldset style={{ 'margin-top': '20px', 'margin-left': '-1px' }}>
                                <legend>Network Access</legend>
                                <Row style={{ 'marginTop': '15px' }}>
                                    <div style={{ 'display': 'flex', 'paddingBottom': '15px', 'flexDirection':isMobileBrowser ? 'column' : 'row' }}>
                                    <div>
                                        <input type="radio" id="sf_network_access" name="sf_network_access" value="deny_all" checked={inputs.sf_network_access === 'deny_all' || false} onChange={handleRadioChange} disabled={readOnly} /> {Constants.PROJECT_NETWORK_ACCESS.deny_all}
                                    </div>
                                    <div style={{ 'marginLeft': isMobileBrowser ? '0':'15px' }}>
                                        <input type="radio" id="sf_network_access" name="sf_network_access" value="allow_inside_device" checked={inputs.sf_network_access === 'allow_inside_device' || false} onChange={handleRadioChange} disabled={readOnly} /> {Constants.PROJECT_NETWORK_ACCESS.allow_inside_device}
                                    </div>
                                    <div style={{ 'marginLeft': isMobileBrowser ? '0':'15px'}}>
                                        <input type="radio" id="sf_network_access" name="sf_network_access" value="allow_all" checked={inputs.sf_network_access === 'allow_all' || false} onChange={handleRadioChange} disabled={readOnly} /> {Constants.PROJECT_NETWORK_ACCESS.allow_all}
                                    </div>
                                    </div>
                                    </Row>
                                </fieldset>
                            </Row>
                            <Row>
                            <fieldset  style={{ 'margin-top': '20px', 'margin-left': '-1px' }}>
                                <legend>Endpoint Type</legend>
                                <Row style={{ 'marginTop': '15px' }}>
                                <div style={{ 'display': 'flex', 'paddingBottom': '15px', 'flexDirection':isMobileBrowser ? 'column' : 'row' }}>
                                    <div>
                                        <input type="radio" id="sf_bind" name="sf_endpoint_type" value="bind" onChange={handleOnChange} checked={inputs.sf_endpoint_type === "bind" || false} disabled={readOnly}></input> Bind
                                    </div>
                                    <div style={{ 'marginLeft': isMobileBrowser ? '0':'15px' }}>
                                        <input type="radio" id="sf_connect" name="sf_endpoint_type" value="connect" onChange={handleOnChange} checked={inputs.sf_endpoint_type === "connect" || false} disabled={readOnly}></input> Connect
                                    </div>
                                </div>
                                </Row>
                            </fieldset>
                            </Row>
                            <Row style={{'width':'100%'}}>
                                <Label>Destinations</Label>
                                {isMobileBrowser ? 
                                    <div style={{'width':'100%', 'display':'flex'}}>
                                        <div style={{'width':'75%'}}>
                                            <TextField name="destination_text" type="text" disabled={readOnly} onChange={handleOnChange} value={inputs.destination_text}/>
                                        </div>
                                        <div style={{'marginLeft':'auto'}}>
                                            <Button primary style={{"float": "right",  'margin':'0'}} onClick={addDestination} disabled={readOnly}>Add</Button>
                                        </div>
                                    </div>: 
                                    <div style={{'width':'100%'}}>
                                        <LeftDiv style={{"width": "85%", padding:'0'}}>
                                            <TextField name="destination_text" type="text" disabled={readOnly} onChange={handleOnChange} value={inputs.destination_text}/>
                                        </LeftDiv>
                                        <RightDiv style={{"width": "15%", "marginTop": "-1em"}}>
                                            <Button primary style={{"float": "right"}} onClick={addDestination} disabled={readOnly}>Add</Button>
                                        </RightDiv>
                                    </div>}
                            </Row>
                            <Row style={{'width':'100%', 'marginBottom':(inputs.destinations === undefined || inputs.destinations.length === 0) ? '10px' :'40px'}}>
                            <List id="destinations" name="destinations" style={{"marginTop": "10px", "marginBottom": "10px", 'width':'100%'}}>
                                { inputs.destinations !== undefined && Object.keys(inputs.destinations).map((index) => {
                                    let isActive = (parseInt(index) === inputs.destinations.length-1) ? true : false;
                                    return(
                                        <div>
                                           {isMobileBrowser ? 
                                            <div style={{'width':'100%', 'display':'flex', 'marginTop': '10px'}}>
                                                <div style={{'display':'flex'}}>
                                                    <LabelDestination1 id={inputs.destinations[index] + 'destinationTextSF'} style={{'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)'}}>{inputs.destinations[index]}</LabelDestination1>
                                                    <div style={{'display':'flex'}}>
                                                        <LabelDestination1 style={{'fontSize':'13px'}}>{" (" + inputs["sf_endpoint_type"] + ")"}</LabelDestination1>
                                                        <CopyButtonDestination primary onClick={() => clickToCopy(inputs.destinations[index] + 'destinationTextSF')}>Copy</CopyButtonDestination>
                                                    </div>
                                                </div>
                                                <div style={{'marginLeft':'auto'}}>
                                                    <DelteButtonSmall style={{"float": "right"}} data={index} onClick={deleteDestination}>Delete</DelteButtonSmall>
                                                </div>
                                            </div>:
                                            <div>
                                                <LeftDiv style={{"width": "80%", 'paddingTop':'5px', 'marginBottom':'10px','display':'flex'}}>
                                                    <LabelDestination1 id={inputs.destinations[index] + 'destinationTextSF'}  style={{'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)'}}>{inputs.destinations[index]}</LabelDestination1>
                                                    <div style={{'display':'flex'}}>
                                                        <LabelDestination1 style={{'fontSize':'13px'}}>{" (" + inputs["sf_endpoint_type"] + ")"}</LabelDestination1>
                                                        <CopyButtonDestination primary onClick={() => clickToCopy(inputs.destinations[index] + 'destinationTextSF')}>Copy</CopyButtonDestination>
                                                    </div>
                                                </LeftDiv>
                                                <ButtonSmall style={{"float": "right", 'marginRight':'0', 'padding': '0.3em 0.9em'}} data={index} onClick={deleteDestination}>Delete</ButtonSmall>
                                            </div>}
                                        </div>)
                                    })
                                }
                                {(inputs.destinations === undefined || inputs.destinations.length === 0)&&
                                    <LabelDestination style={{'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)'}}>Destination list is empty</LabelDestination>                                   
                                }
                                </List>
                                {isMobileBrowser && <HR /> }
                                </Row>
                        </Column>
                        <Column>
                            <fieldset style={{'margin-top': '20px'}}>
                                <legend>
                                Frame Rate Logging
                                </legend>
                                <div style={{"padding": "12px 0px", "width":"100%", "display":"flex"}}>
                                    <div style={{"width":"40%", "flex-shrink": "0"}}>
                                    <SelectionControl id="sf_frame_rate_logging" name="sf_frame_rate_logging" type="switch" label={inputs.sf_frame_rate_logging === true ? "Enabled" : "Disabled"} labelBefore="true" checked={inputs.sf_frame_rate_logging || false} onChange={handleToggleChange}/>
                                    </div>
                                    <IntervalDiv style={{"paddingTop": "4px", "width":"100%", "display":"inline-flex", "flex-grow": "1", "align-items": "baseline"}}>
                                    <Label style={{flexShrink: '0'}}>Interval (Secs)&nbsp;</Label>
                                    <div>
                                        <TextField name="frame_rate_logging_interval" type="text" disabled={readOnly || !inputs.sf_frame_rate_logging} maxLength="4" onChange={handleFloatOnChange} value={inputs.frame_rate_logging_interval}/>
                                    </div>
                                    </IntervalDiv>
                                </div>
                            </fieldset>
                            <div>
                                <Label>Emit Interval (Secs)</Label>
                                <Label style={{"float": "right", "fontSize":"0.9em"}}>(0 - Emit all frames)</Label>
                            </div>
                            <TextField name="emit_interval" type="text" disabled={readOnly} maxLength="5" onChange={handleFloatOnChange} value={inputs.emit_interval}/>
                            <Label style={{"paddingTop": "10px"}}>Watchdog Timeout (Secs)</Label>
                            <Label style={{"float": "right", "fontSize":"0.9em", "paddingTop": "10px"}}>(0 - Disabled)</Label>
                            <TextField name="watchdog_timeout" type="text" disabled={readOnly} onChange={handleIntegerOnChange} value={inputs.watchdog_timeout}/>
                            <Row>
                                <ResponsiveHR />
                            </Row>
                        </Column>
                    </ResponsiveRow>
                    <Row>
                        <Column style={{width: '100%'}}>
                            <div style={{"paddingTop": "10px", "color": "gray", "fontSize": "0.9em"}}>
                                <Label style={{"fontWeight": "bold", "marginRight": "10px"}}>Special Addresses</Label>
                                {
                                Object.keys(Constants.FOG_BROADCASTER_ALIASES).map((index)=> {
                                    return(
                                        <div style={{"display": "flex"}}>
                                            <Label> {Constants.FOG_BROADCASTER_ALIASES[index]}</Label>
                                        </div>
                                        )
                                })
                                }
                            </div>
                        </Column>
                    </Row>
                    <Row>
                    <Column>
                    </Column>
                    <Column>
                    <RightDiv>
                    {isViewPage &&
                        <div style={{"float":"left"}}>
                            { readOnly == true &&
                                <Button primary onClick={editStreamForwarder}>Edit</Button>
                            }
                            { readOnly == false &&
                                <div>
                                <Button primary onClick={saveStreamForwarder}>Save</Button>
                                { initialValues.type != undefined &&
                                    <Button primary onClick={cancelStreamForwarder}>Cancel</Button>
                                }
                                </div>
                            }
                        </div>
                    }
                    <Button onClick={deleteStreamForwarder}>{(isViewPage && initialValues.type != undefined) ? "Delete" : "Cancel"}</Button>
                    </RightDiv>
                    </Column>
                    </Row>
                </Row>
                 }
            </TabPanel>
            )
}

export default StreamForwardingTab;