import React, { Component } from "react";
import PropTypes from "prop-types";
import { ListItem, MenuButton } from 'react-md';
import swal from "sweetalert";
import axios from 'axios';
import { RotateLabel, Label } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import styled from "styled-components";
import axiosRetry from 'axios-retry';

const ResponsiveMenuButton = styled(MenuButton)`
    width: auto !important;
    height: auto !important;
    & > i > label {
        font-size: 20px !important;
        @media screen and (max-width: 700px) {
            font-size: 16px !important;
        }
    }
    @media screen and (max-width: 750px) {
        font-size: 0.95rem;
    }
    @media screen and (max-width: 700px) {
        font-size: 0.9rem;
    }
    @media screen and (max-width: 550px) {
        font-size: 0.75rem;
    }
    @media screen and (max-width: 400px) {
        font-size: 0.68rem;
    }
`;

class AccordionSection extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
        isOpen: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        statusType: PropTypes.string.isRequired,
        stackType: PropTypes.string.isRequired,
        disableMenu: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        cameraType:PropTypes.func.isRequired,
        safieConfigure:PropTypes.string.isRequired
    };

    onClick = () => {
        this.props.onClick(this.props.label);
    };

    getProjectData = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client, {
          retries: 4,
          retryDelay: (retryCount, error) => {
            if (retryCount === 4) {
              swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
            }
            return 3000;
          },
          retryCondition: (error) => {
            return true;
          },
        });
        client.get('/sdk/project/all')
          .then(res => {
            let data = res.data.data;
            if (Object.keys(res.data.data.stacks).length === 0) {
              this.setState({ editPopup: false });
            }
            let sortedData = [];
            if ('stacks' in data) {
              Object.keys(data.stacks).map((stackName) => {
                let stackData = data.stacks[stackName];
                stackData.name = stackName;
                sortedData.push(stackData);
                return stackName;
              });
            }
            data["sorted_list"] = sortedData;
            this.setState({ availableVolumes: data["sorted_list"] });
          })
          .catch(error => {
            console.log(error);
          });
      };
    
      componentDidMount() {
        this.getProjectData();
      }

      getLicenses = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client, {
          retries: 4,
          retryDelay: (retryCount, error) => {
            if (retryCount === 4) {
              swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
            }
            return 3000;
          },
          retryCondition: (error) => {
            return true;
          },
        });
        client.get('/sdk/license_mgr/all')
          .then(res => {
            let data = res.data.data;
                let sortedData = [];
                if (data && "lic-mgr" in data.stacks) {
                    Object.keys(data.stacks["lic-mgr"].services).map((licenseKey) => {
                        let licenseData = data.stacks["lic-mgr"].services[licenseKey];
                        licenseData.name = licenseKey;
                        sortedData.push(licenseData);
                        return licenseKey;
                    });
                }
                const sortedData1 = Object.keys(sortedData).map((item) => {
                    let test = sortedData[item];
                    const licenseObj = {};
                    for (const key in test) {
                        if (test[key].hasOwnProperty('key')) {
                            licenseObj[key] = test[key];
                        }
                    }
                    return licenseObj;
                })
                const filterLicense = sortedData1.reduce((result, obj) => {
                    for (const key in obj) {
                        obj[key].id = key;
                        result.push(obj[key]);
                    }
                    return result;
                }, []);
                data['licenseServices'] = filterLicense;
                this.setState({ availableKeys: data});
          })
          .catch(error => {
            console.log(error);
          });
      };

      componentDidMount() {
        this.getLicenses();
      }

    deleteStack = (stackType, stackSubType, stackName,instanceId) => {
        const { availableVolumes } = this.state;
        const { availableKeys } = this.state;
        let projectName = '';
        var stackLabel = (stackType === "streams" ? "Stream" : "Project")
        var apiUrl = (stackType === "streams" ? Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all" : Constants.EDGE_API_ENDPOINT + '/projects/' + stackName + "/all")
        if (stackType === "streams") {
            stackLabel = "stream";
            apiUrl = Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all"
        } else if (stackType === "sdk") {
            if (stackSubType === 'project') {
                stackLabel = "project";
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/project/' + stackName + '/all';
            } else if (stackSubType === 'bi-metabase') {
                stackLabel = "metabase";
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + stackName + '/all';
            } else if(stackSubType === 'sdk-volume'){
                stackLabel = "volume";
                let volumeNameArr =[];
                if (Array.isArray(availableVolumes)) {
                    for (const item of availableVolumes) {
                      const instance = item.services.project1;
                      Object.keys(instance.volumes).map((idx) => {
                        Object.keys(instance.volumes[idx]).map((i) => {
                          volumeNameArr.push(i);
                        })
                      });
                        if (volumeNameArr && volumeNameArr.includes(stackName) === true) {
                            projectName = instance.project_name; // Get the project name
                            if (volumeNameArr && volumeNameArr.includes(stackName)) {
                                swal('This volume is attached with SDK project(s). Please delete the project(s) or detach the volume from the project(s).', { icon: 'error' });
                            } 
                            return true;
                        }
                    }
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/volume/' + stackName + '/all';
            } else if (stackSubType === 'rdb-mysql') {
                stackLabel = "relational database";
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + stackName + '/all';
            } else if (stackSubType === 'email-client') {
                stackLabel = "email client";
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/email_clients/' + stackName + '/all';
            } else if (stackSubType === 'license-mgr') {
                stackLabel = "license manager";
                if (availableKeys && availableKeys.licenseServices.length >= 1) {
                    swal('Please remove all license codes to delete the License Manager.', { icon: 'error' });
                    return;
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/license_mgr';
            } else if (stackSubType === 'api-tester') {
                stackLabel = "api tester";
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/api_tester/' + stackName + '/all';
            } else {
                return;
            }
        }

        swal({
            text: "\n\nAre you sure you want to delete this " + stackLabel + "?",
            buttons: ['No', 'Yes'],
            dangerMode: true
        })
            .then((isConfirm) => {
                if (isConfirm) {
                    axios.delete(apiUrl)
                        .then(res => {
                            swal((stackLabel === 'api tester' ? 'API tester' :  stackLabel[0].toUpperCase() + stackLabel.substring(1))  + " delete request submitted successfully.", { icon: "success" });
                            setTimeout(function () { window.location.reload() }, 2000);
                        })
                        .catch(error => {
                            if (error.response) {
                                var errorObj = error.response.data;
                                swal("Error Code: " + errorObj.error.code + "\nError Message: " + errorObj.error.message, { icon: "error" });
                            } else {
                                swal({ text: "Unable to connect to the edge-api service", icon: "error" });
                            }
                        });
                }
            });
    };

    enableDisableStack = (cameraType,stackType, stackSubType, stackName, actionLabel,safieConfigure) => {
        const { availableKeys } = this.state;
        let action = '';
        let stackLabel = '';
        let jsonData = {};
        let apiUrl = '';
        if (stackType === "streams") {
            action = (actionLabel === "Enable" ? "start" : "stop")
            stackLabel = "Stream";
            jsonData = {
                "namespace": "default",
                "action": action,
                "source": "Edge UI"
            }
            apiUrl = Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName;
        } else if (stackType === "sdk") {
            if (stackSubType === 'project') {
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "Project";
                jsonData = {
                    "namespace": "sdk",
                    "type": "project",
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                    "source": "Edge UI"
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/project/' + stackName + '/all';
            } else if (stackSubType === 'api-tester') {
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "API Tester";
                jsonData = {
                    "namespace": "sdk",
                    "type": "api-tester",
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                    "source": "Edge UI"
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/api_tester/' + stackName + '/all';
            } else if (stackSubType === 'bi-metabase') {
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "Metabase";
                jsonData = {
                    "namespace": "sdk",
                    "type": "bi-metabase",
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                    "source": "Edge UI"
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + stackName + '/all';
            } else if (stackSubType === 'rdb-mysql') {
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "Relational Database";
                jsonData = {
                    "namespace": "sdk",
                    "type": "rdb-mysql",
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                    "source": "Edge UI"
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + stackName + '/all';
            } else if (stackSubType === 'email-client') {
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "Email Client";
                jsonData = {
                    "namespace": "sdk",
                    "type": "email-client",
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                    "source": "Edge UI"
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/email_clients/' + stackName + '/all';
            } else if (stackSubType === 'license-mgr') {
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "License Manager";

                if ((availableKeys && availableKeys.licenseServices.length >= 1)) {
                    swal('Please remove all license codes to disable the License Manager.', { icon: 'error' });
                    return;
                }

                jsonData = {
                    "namespace": "sdk",
                    "type": "license-mgr",
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                    "source": "Edge UI"
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/license_mgr';
            } else if (stackSubType === 'extension') {
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "Extension";
                jsonData = {
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/extensions/' + stackName + '/all';
            } else {
                return;
            }
        }
        if(cameraType=="SAFIE" && !safieConfigure){
            swal(`\n\nSafie Connector is not configured yet. \nPlease configure it from Settings > Safie Connector.`, {
                buttons: {
                  cancel: "Cancel",
                  configure: {
                    text: "Go to Settings",
                    value: "catch",
                  },
                },
              })
              .then((value) => {
                switch (value) {
                  case "catch":
                    window.location.href = 'safie-camera-settings'
                    break;
                  default:
                }
              });
        }else{
            swal({
                text: "\n\nAre you sure you want to " + actionLabel.toLowerCase() + " this " + stackLabel.toLowerCase() + "?",
                buttons: ['No', 'Yes'],
                dangerMode: true,
            }).then(function (isConfirm) {
                if (isConfirm) {
                    axios.post(apiUrl, jsonData)
                        .then(res => {
                            swal(stackLabel + " " + actionLabel.toLowerCase() + " request submitted successfully.", { icon: "success" });
                            setTimeout(function () { window.location.reload() }, 2000);
                        })
                        .catch(error => {
                            if (error.response) {
                                var errorObj = error.response.data;
                                swal("Error Code: " + errorObj.error.code + "\nError Message: " + errorObj.error.message, { icon: "error" });
                            } else {
                                swal({ text: "Unable to connect to the edge-api service", icon: "error" });
                            }
                        });
                }
            });
        }

        
    };

    render() {
        const { onClick, deleteStack, enableDisableStack, props: { isOpen, label,cameraType,safieConfigure, stackName, statusType, stackType, stackSubType, disableMenu, deleteButtonDisable } } = this;
        const enabledMenuLabel = statusType === "disabled" ? "Enable" : "Disable";

        return (
            <div style={{ "background": "#fff", "border": "1px solid #d3d3d38a", "borderBottom": "1px solid #d3d3d38a", "padding": isOpen ? "0px" : "5px 5px", "color": "#000", "marginBottom": "5px" }}>
                <div style={{ cursor: "pointer", background: isOpen ? "#d3d3d38a" : "#fff", padding: "5px", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        { isOpen ? 
                            <span onClick={onClick} style={{ "display": "inline-block","marginLeft":"1px", "marginRight": "15px", "color": "#4bb5f4", "fontSize":"19px" }}>&#9660;</span>
                            :
                            <span onClick={onClick} style={{ "display": "inline-block", "marginRight": "15px", "color": "#4bb5f4" , "fontSize":"14px"}}>&#9654;</span>
                        }
                        <div onClick={onClick} style={{ "display": "inline-block" }}>
                            <label style={{ "fontWeight": "bold", "cursor": "pointer", "marginRight": "-15px", 'white-space': 'nowrap' }}>{label} </label>
                        </div>
                    </div>
                    <div style={{ "display": "flex", alignItems: 'center', marginRight: '10px' }}>
                        <Label onClick={onClick} style={{ cursor: "pointer", margin: '0'}} className={statusType}>{Constants.STATUS_TYPES[statusType] !== undefined ? Constants.STATUS_TYPES[statusType] : statusType}</Label>
                        <div >
                            {(this.props.showMenu === true) &&
                                <>
                                {stackSubType === 'sdk-volume' ?
                                    <ResponsiveMenuButton
                                        style={{ 'paddingRight': '20px' }}
                                        id="menu-button"
                                        icon
                                        menuItems={[
                                            <ListItem key={1} primaryText={enabledMenuLabel} onClick={() => enableDisableStack(cameraType, stackType, stackSubType, stackName, enabledMenuLabel, safieConfigure)} disabled={true} />,
                                            <ListItem key={2} primaryText="Delete" onClick={() => deleteStack(stackType, stackSubType, stackName)} disabled={deleteButtonDisable} />,
                                        ]}
                                        centered
                                        anchor={{
                                            x: MenuButton.VerticalAnchors.CENTER,
                                            y: MenuButton.VerticalAnchors.CENTER,
                                        }}
                                    >
                                        <RotateLabel>...</RotateLabel>
                                    </ResponsiveMenuButton>
                                    : <ResponsiveMenuButton
                                        style={{ 'paddingRight': '20px' }}
                                        id="menu-button"
                                        icon
                                        menuItems={[
                                             <ListItem key={1} primaryText={enabledMenuLabel} onClick={() => enableDisableStack(cameraType, stackType, stackSubType, stackName, enabledMenuLabel, safieConfigure)} />,
                                            <ListItem key={2} primaryText="Delete" onClick={() => deleteStack(stackType, stackSubType, stackName)} disabled={deleteButtonDisable} />,
                                        ]}
                                        centered
                                        anchor={{
                                            x: MenuButton.VerticalAnchors.CENTER,
                                            y: MenuButton.VerticalAnchors.CENTER,
                                        }}
                                    >
                                        <RotateLabel>...</RotateLabel>
                                    </ResponsiveMenuButton>
                                }
                            </>
                            }
                        </div>
                    </div>
                </div>

                {isOpen && (
                    <div style={{ "background": "#fff", "marginTop": 0, "padding": "10px 20px", "color": "#000" }}>
                        {this.props.children}
                    </div>
                )}
            </div>
        );
    }
}

export default AccordionSection;
