import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Wrapper, MandatoryLabel, Label, PopupButtons, TableContainer, overlayStyle, PopupContainer, CenterDiv,  TextFieldSmall, Th, Td, Row, SectionHeader, Button, SectionLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import swal from "sweetalert";
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import axiosRetry from 'axios-retry';


const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 3px;
  height: auto;
  @media screen and (max-width: 500px) {
    width: 220px;
    justify-content: flex-end;
    margin-left:0px;
    font-size: 8px;
  }
`;

const SectionHeaderIPv4=styled.div`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  background-color: #ffffff;
`;

const ButtonDiv = styled.div`
  margin-top : -5px;
  @media screen and (max-width: 680px) {
    margin-top : 0px;
}
  @media screen and (max-width: 400px) {
    margin-top : 1px;
}
`;

const Div = styled.div`
  border-bottom: 1px solid lightgray;
  margin : -5px 0 15px 0;
`;


const ResponsiveInput = styled(TextFieldSmall)`
  width: 250px;
  @media screen and (max-width: 750px) {
    width: 160px;
  }
  @media screen and (max-width: 650px) {
    width: 100px;
  }
  @media screen and (max-width: 500px) {
    width: 120px;
  }
`;

const ResponsiveTable = styled(TableContainer)`
  height: 270px;
  @media screen and (max-width: 500px) {
    height: 230px;
  }
`;

const ResponsivePopup = styled.div`
  @media screen and (max-width: 750px) {
    width: 550px;
    height: 100%;
    overflow-y: auto;
  }
  @media screen and (max-width: 650px) {
    width: 450px;
  }
  @media screen and (max-width: 500px) {
    width: 350px;
  }
  @media screen and (max-width: 415px) {
    width: 300px;
  }
`;

const ContentDiv=styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const IPv4RoutingSettings = ({initialRouteData, isLoaded}) => {
  const [routeData, setRouteData] = useState(initialRouteData);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(isLoaded);
  const [retry,setRetry]=useState(false)
  const [popupType, setPopupType] = useState('');
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const [interfaceList, setInterfaceList] = useState();

  useEffect(()=>{
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  },[]);

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    padding: '5px',
    border: '2px solid #d7d7d7'
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const getRoutes = () => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/network/interfaces/show_ipv4_routes/status/all')
    .then(res => {
      let ifData = res.data.data;
      let sortedData = [];
      Object.keys(ifData).map((idx) => {
        let data = {};
        let pData = ifData[idx];
        data.destination = pData[1].destination
        data.gateway = pData[1].gateway
        data.genmask = pData[1].genmask
        data.metric = pData[1].metric
        data.inf_name = pData[0]
        sortedData.push(data);
        return idx;
      });
      sortedData.sort(function(a,b){
        return a.metric - b.metric;
      });
      setRouteData(sortedData) 
      setLoading(false)
    }).catch(err => {
      setLoading(true)
      console.log(err);
    });
  }

  const testRoute = () => {
    setModalOpen(true);
    setPopupType('routeTest');
  };

  const getConfiguredInterfacelList = () => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/network/interfaces/all') 
    .then(res => {
      const dataList = Object.keys(res.data.data.services)
      let sortedData = [];
      Object.keys(dataList).map((idx) => {
        let data = {};
        let pData = dataList[idx];
        data.name = pData;
        data.value = pData;
        sortedData.push(data);
        return idx;
      });
      setInterfaceList(sortedData);
    }).catch(err => {
      setLoading(true)
      console.log(err);
    });
  }

  useEffect(()=>{
    getConfiguredInterfacelList();
  },[]);

  const refreshRoutes = (event) => {
    setLoading(true);
    getRoutes();
  };

    return (
      <ContentDiv>
          <Wrapper style={{'marginTop':'5px'}}>

                   <Row>
                  <ActionButtonContainer style={{marginRight:'12px'}}>
                    {/* <Button primary id="ipv4_btnPing" name="btnPing" onClick={onClickPing}>Ping</Button> */}
                    <Popup closeOnDocumentClick={false} modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                      <TestRoutePopup closePopup={closePopup} popupType={popupType} interfaceList={interfaceList} />
                    </Popup>
                  </ActionButtonContainer>
                </Row>
                {/* <Spacer /> */}
                <div style={{display:'flex','justifyContent':'space-between'}}>
                <div>
                <SectionHeaderIPv4>
                    <SectionLabel>IPv4 Routes</SectionLabel>
                </SectionHeaderIPv4>
                </div>
                <ButtonDiv>
                    <Button primary id="ipv4_btnRefreshRoutes" name="btnRefreshRoutes" onClick={() => refreshRoutes()}>{loading ? 'Refreshing...' : 'Refresh'}</Button>
                    <Button primary id="ipv4_btnTestRoute" name="btnTestRoute" onClick={testRoute}>Test Route</Button>
                </ButtonDiv>
                </div>
                <Div></Div>
                {(routeData) ?
                  <>
                {Object.keys(routeData).length > 0 ?
                <TableContainer style={{overflowX: 'auto'}}>
                  <table style={{'width': '100%', 'padding':'11px', 'borderCollapse':'collapse'}}>
                    <tr style={{'background-color': '#1f303a','color': 'white'}}>
                      <Th style={{'padding': '10px', 'textAlign':'left', 'width': '200px'}}>Destination</Th>
                      <Th style={{'padding': '10px', 'width': '200px'}}>Gateway</Th>
                      <Th style={{'padding': '10px', 'width': '200px'}}>Genmask</Th>
                      <Th style={{'padding': '10px', 'width': '100px'}}>Metric</Th>
                      <Th style={{'padding': '10px', 'width': '120px'}}>Interface</Th>
                    </tr>
                    {Object.keys(routeData).map((idx) => {
                      let route_data = routeData[idx]
                      return(
                        <tr>
                          <Td style={{'padding': '10px', 'textAlign':'left'}}>{route_data.destination}</Td>
                          <Td>{route_data.gateway}</Td>
                          <Td>{route_data.genmask}</Td>
                          <Td>{route_data.metric}</Td>
                          <Td>{route_data.inf_name}</Td>
                        </tr>
                      )
                    })}

                  </table>
                </TableContainer>
                // <MessageContaier>
                //      {isMobileBrowser ? 
                //       <MessageLabel>  
                //         <b>Metric: </b>It is an integer and smaller number means higher priority. Metric in a parentheses is assigned by the system automatically.
                //       </MessageLabel> : 
                //       <MessageLabel> 
                //       <b>Metric: </b>It is an integer and smaller number means higher priority. Metric in a parentheses is assigned by the system automatically.
                //       </MessageLabel>} 
                //   </MessageContaier>
                :
                <CenterDiv>
                  <Label>No route found.</Label>
                </CenterDiv>}
              </> : 
                <CenterDiv>
                  <Label>IPv4 routes not found.</Label>
                </CenterDiv>
              }
          </Wrapper>
      </ContentDiv>
    )
  }

const TestRoutePopup = (props) => {
  const { closePopup, popupType, interfaceList } = props;
  const [destination, setDestination] = useState('8.8.8.8');
  const [routesData, setRoutesData] = useState([]);
  const [loading, setLoading] = useState();
  const [disableSubmitTestButton, setDisableSubmitTestButton] = useState(false);
  const [disableResetTestButton, setDisableRestTestButton] = useState(false);

  const onSubmit = (destination) => {
    const value = validateIPaddress(destination);
      if(value) {
        getDestination();
      }
  }

  const getDestination = () => {
    setLoading(true);
    setDisableSubmitTestButton(true);
    axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/get_routes/' + destination + '/status/all') 
    .then(res => {
      let ifData = res.data.data[0];
      let data = {};
      let sortedData = [];
      let routesDataArray = [...routesData];
      Object.keys(ifData).map((idx) => {
        data.destination = destination;
        data.ifName = ifData[idx].device;
        data.gateway = ifData[idx].gateway;
        data.source = ifData[idx].source
        sortedData.push(data);
        return idx;
      })
      if(Object.keys(routesDataArray).length > 9) {
        routesDataArray.splice(0, 0, data);
        routesDataArray.splice(-1);
      } else {
        routesDataArray.splice(0, 0, data);
      }
      setRoutesData(routesDataArray);
      setLoading(false);
      setDisableSubmitTestButton(false);
    }) .catch(error => {
      console.error(error);
      setLoading(false);
      setDisableSubmitTestButton(false);
    });
  }

  function validateIPaddress(ipaddress) {

    if(ipaddress === undefined || ipaddress === '') {
      swal('Please enter IPv4 destination address.', { icon: 'error'});
      return;
    } else {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
          return true
        } 
        swal('Please enter valid IPv4 destination address.', { icon: 'error'});
        return false
    } 
  } 

  const handleOnChange = (event) => {
    setDisableSubmitTestButton(false);
    if (event.target.name === 'destination' && event.target.value.includes(' ')) {     
      event.target.value = event.target.value.replace(/\s/g, '');    
    } else {
      if(event.target.name === 'destination' ) {
        event.target.value = event.target.value.replace(/[^.:\w]/g, '');
      } 
      setDestination(event.target.value);
    }
  }

  const onReset = () => {
    setDisableSubmitTestButton(false);
    setDestination('');
  }

  useEffect(() => {
    if(destination) {
      setDisableRestTestButton(false);
    } else {
      setDisableRestTestButton(true); 
    }
  },[destination]);

  const OnhandleKeyPress = (event, destination) => {
      if (event.key === "Enter") {
        onSubmit(destination);
      }
  }
  return(
    <ResponsivePopup>
      <PopupContainer>
        {popupType === 'routeTest' && 
          <>
            <SectionHeader style={{ 'display': 'flex' }}>
              <SectionLabel>Test Route (IPv4)</SectionLabel>
            </SectionHeader>
            <Container>
              <Row style={{'display':'flex', 'width':'100%', 'align-items': 'baseline', 'flex-wrap': 'wrap'}}>
                <div>
                  <MandatoryLabel style={{'width':'100px'}}>Destination</MandatoryLabel>
                  <ResponsiveInput style={{'textTransform':'lowercase'}} id="ipv4_destination" autoComplete="off" placeholder='8.8.8.8' value={destination} onKeyPress={(e) => OnhandleKeyPress(e, destination)} name="destination" onChange={handleOnChange} />
                </div>
                <ButtonContainer>
                  <Button primary autoFocus disabled={loading || disableSubmitTestButton} id='ipv4_btndisabled' onClick={() => onSubmit(destination)}>
                    <div style={{'display': 'flex'}}>
                    {loading && <span>Submit</span>}
                    {!loading && <span style={{'margin':'0 12.5px'}}>Submit</span>}
                    {loading && (
                      <div class="loader" style={{'marginLeft':'10px', 'marginTop': '2px'}}></div>
                      )}
                    </div>
                  </Button>
                  <Button primary id="btndisabled" style={{'marginLeft':'10px'}} name="btndisabled" disabled={disableResetTestButton} onClick={onReset}>Clear</Button>
                </ButtonContainer>
              </Row>
              <ResponsiveTable className='tableFixHead' style={{'marginTop':'10px'}}>
                <table style={{'min-width': '100%', 'borderSpacing': '0', 'tableLayout': 'fixed', 'borderCollapse': 'separate'}}>
                  <tr style={{'background-color': '#1f303a', 'borderSpacing': '0','color': 'white', 'position': 'sticky', 'top': '0'}}>
                    <Th>Destination</Th>
                    <Th>Gateway</Th>
                    <Th>Interface</Th>
                    <Th>Source</Th>
                  </tr>
                  { Object.keys(routesData).map((index) => {
                     return(
                      <tr>
                      <Td style={{'padding': '9px', 'textTransform':'lowercase'}}>{(routesData[index].destination === null || routesData[index].destination=== '' ) ? '-' : routesData[index].destination}</Td>
                      <Td>{(routesData[index].gateway === null || routesData[index].gateway === '' ) ? '-' : routesData[index].gateway}</Td>
                      <Td>{(routesData[index].ifName === null || routesData[index].ifName === '' ) ? '-' : routesData[index].ifName}</Td>
                      <Td>{(routesData[index].source === null || routesData[index].source === '' ) ? '-' : routesData[index].source}</Td>
                    </tr>
                     );
                  }
                 )}
                </table>
              </ResponsiveTable>
              <Row>
                <PopupButtons>
                  <Button id="btnCancel" name="btnCancel" onClick={closePopup}>Cancel</Button>
                </PopupButtons>
              </Row>
            </Container>
          </>}
      </PopupContainer>
    </ResponsivePopup>
  );
}

export default IPv4RoutingSettings;