import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { ContentDiv, Spacer, Wrapper, SectionHeader, SectionLabel } from '../utils/Styles';
import swal from 'sweetalert';
import * as Constants from '../utils/Constants';
import { isEmpty } from '../utils/Utils';
import OnBoardWifi from './OnBoardWifi';
import NetworkInterfaces from './NetworkInterfaces';
import IPRoutingContainer from './IPRoutingContainer';
import NetworkPorts from './NetworkPorts';
import VpnClient from './VpnClient';
import DNSSettings from './DNSSettings';
import AccessControl from './AccessControl'
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import UserLogin from './UserLogin';


const ResponsiveTabList = styled.div`  
  @media screen and (max-width: 832px) {
    & .rwt__tab {
      padding: 12px;
    }
  }
  @media screen and (max-width: 500px) {
    & .rwt__tab {
      padding: 7px;
    }
  }
  @media screen and (max-width: 375px) {
    & .rwt__tab {
      padding: 5px;
    }
  }
`;

const UpperWrapper = styled(Wrapper)`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  
  @media screen and (max-width: 750px) {
    font-size: 0.95rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.7rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 0.68rem;
  }
`;

export const onBlurIpAddress = (event) => {
  if (event.target.id === 'gateway' && isEmpty(event.target.value)) {
    document.getElementById(event.target.id).style.borderColor = 'lightgray';
    return;
  }
  else if (event.target.id === 'ip' && isEmpty(event.target.value)) {
    document.getElementById(event.target.id).style.borderColor = 'lightgray';
    return;
  }

  if (event.target.id === 'gateway' || event.target.id === 'ip') {
    validateIpAddress(event.target.id, event.target.value);
  }
  else if (event.target.id === 'network_address') {
    validateNetworkAddress(event.target.id, event.target.value);
  }
};

export const validateIpAddress = (id, value) => {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)) {
    if (document.getElementById(id)) {
      document.getElementById(id).style.borderColor = 'lightgray';
    }
    return true;
  } else {
    if(id === 'gateway') {
      swal('Invalid gateway: ' + value, { icon: 'error' });
    } else {
      swal('Invalid IPv4 address: ' + value, { icon: 'error' });
    }
    if (document.getElementById(id)) {
      document.getElementById(id).style.borderColor = 'red';
    }
    return false;
  }
};

export const validateNetworkAddress = (id, value) => {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.([1]\/?([1-9]|[12][0-9]|3[0-2])?)$/.test(value)) {
    if (document.getElementById(id)) {
      document.getElementById(id).style.borderColor = 'lightgray';
    }
    setNetworkAccessIP(value);
    setDHCPRange(value);
    return true;
  } else {
    swal('Invalid network address: ' + value, { icon: 'error' });
    if (document.getElementById(id)) {
      document.getElementById(id).style.borderColor = 'red';
    }
    return false;
  }
};

const setNetworkAccessIP = (networkAddressIP) => {
  let naip = networkAddressIP.split('/');
  let ipData = naip[0].split('.');
  let accessIp = ipData.join('.');
  return accessIp;
};

const setDHCPRange = (network_ip) => {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.([1]\/?([1-9]|[12][0-9]|3[0-2])?)$/.test(network_ip)) {
    const Netmask = require('netmask').Netmask;
    let na = network_ip;
    let allData = new Netmask(na);
    let firstIp = allData.first.split('.');
    firstIp[3] = parseInt(firstIp[3]) + 1;
    let lastIp = allData.last.split('.');
    let firstAddress = firstIp.join('.');
    let lastAddress = lastIp.join('.');
    return `DHCP Range : [ ${firstAddress} - ${lastAddress} ]`;
  } else {
    return 'Invalid network address';
  }
};

const NetworkSettings = (props) => {
  const hostVPU = Constants.AD_HW_VPU;
  const [availablePorts, setAvailablePorts] = useState();
  const [interfacesData, setInterfacesData] = useState();
  const [selectedTab, setSelectedTab] = useState('general');
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const history = useHistory();

  const setParams = useCallback(() => {
    const params = '?' +
      `${selectedTab === 'general' ? 'general' : '&selectedTab=' + selectedTab}`
    if (params === '?') {
      return '';
    }
    return params;
  }, [selectedTab]);

  const fetchHistoryParams = useCallback(() => {
    const string = new URLSearchParams(historyParams);
    let newParams = {};
    newParams.selectedTab = string.get('selectedTab');

    if (newParams.selectedTab) {
      setSelectedTab(newParams.selectedTab);
    }
  }, [historyParams]);

  useEffect(()=>{
    fetchHistoryParams();
  },[fetchHistoryParams]);

  useEffect(() => {
    history.push('/network' + setParams());
  }, [history, setParams]);

  useEffect(() => {
    let portData = {};
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/ports/all')
      .then(res => {
        portData = res.data.data;
        if (portData) {
          axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/ports/status')
            .then(res => {
              let data = res.data.data;
              if (data && 'port_list' in data) {
                let portStatusList = data.port_list;
                Object.keys(portStatusList).map((portName) => {
                  if (portName in portData.services) {
                    portData.services[portName].status = portStatusList[portName];
                  }/* else {
                portData.services[portName] = {};
                portData.services[portName].status = portStatusList[portName];
              }*/
                  return portName;
                });
              }
              // Delete Bond members from port list
              let membersToDelete = [];
              Object.keys(portData.services).map((portName) => {
                if (portData.services[portName].type === 'bond') {
                  let members = portData.services[portName].members;
                  members.map((member) => {
                    if (member in portData.services) {
                      membersToDelete.push(member);
                    }
                    return member;
                  });
                }
                return portName;
              });
              membersToDelete.map((member) => {
                if (member in portData.services) {
                  delete portData.services[member];
                }
                return member;
              });

              if (props.hostVPU === 'MMAL') {
                axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/brcm/all')
                  .then(res => {
                    let brcmData = res.data.data;
                    if (brcmData && 'brcm_wifi' in brcmData && 'ap_enabled' in brcmData) {
                      // Remove wlan0 if brcm_wifi flag is disabled and use as access point is enabled
                      if (brcmData.brcm_wifi === false && brcmData.ap_enabled === true) {
                        if ('wlan0' in portData.services) {
                          delete portData.services.wlan0;
                        }
                      }
                    }
                    axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/all')
                      .then(res => {
                        setInterfacesData(res.data.data);
                        let ifData = res.data.data;
                        Object.keys(ifData.services).map((ifName) => {
                          if (ifName in portData.services) {
                            delete portData.services[ifName];
                          }
                          return ifName;
                        });
                        setAvailablePorts(portData);
                      })
                      .catch(error => {
                        setAvailablePorts(portData);
                        console.log(error);
                      });
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else {
                axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/interfaces/all')
                  .then(res => {
                    setInterfacesData(res.data.data);
                    let ifData = res.data.data;
                    Object.keys(ifData.services).map((ifName) => {
                      if (ifName in portData.services) {
                        delete portData.services[ifName];
                      }
                      return ifName;
                    });
                    setAvailablePorts(portData);
                  })
                  .catch(error => {
                    setAvailablePorts(portData);
                    console.log(error);
                  });
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [props.hostVPU]);

  function onTabChange(tabId){
    setSelectedTab(tabId);
  }

  return (
    <ContentDiv>
      <UpperWrapper>
      <SectionHeader>
        <SectionLabel>Network</SectionLabel>
      </SectionHeader>
      <Tabs defaultTab={selectedTab} onChange={onTabChange}>
        <TabList>
          <ResponsiveTabList>
            <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
            <Tab tabFor="dns" className={'rwt__tab'}>DNS</Tab>
            <Tab tabFor="routes" className={'rwt__tab'}>Routes & Ping</Tab>
            <Tab tabFor="vpnClient" className={'rwt__tab'}>User-defined VPN</Tab>
            <Tab tabFor="trust_policies" className={'rwt__tab'}>Trust Policies</Tab>
            <Tab tabFor="user_login" className={'user_login'}>Login</Tab>
          </ResponsiveTabList>
        </TabList>
        <TabPanel tabId="general">
          <NetworkInterfaces availablePorts={availablePorts} />
          <Spacer />
          <NetworkPorts hostVPU={hostVPU} availablePorts={availablePorts} interfacesData={interfacesData} />
          <Spacer />
          {hostVPU === 'MMAL' &&
            <OnBoardWifi />
          }
        </TabPanel>
        <TabPanel tabId='dns'>
          <DNSSettings />
        </TabPanel>
        <TabPanel tabId='routes'>
          <IPRoutingContainer />
        </TabPanel>
        <TabPanel tabId='vpnClient'>
          <VpnClient />
        </TabPanel>
        <TabPanel tabId='trust_policies'>
          <AccessControl />
        </TabPanel>
        <TabPanel tabId='user_login'>
          <UserLogin />
        </TabPanel>
      </Tabs>
      </UpperWrapper>
    </ContentDiv>
  );
};

export default NetworkSettings;
